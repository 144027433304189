

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Sidebar from "./Sidebar";
// import { useParams } from "react-router-dom";
// import iziToast from 'izitoast';
// import 'izitoast/dist/css/iziToast.min.css';


// const AdminForm = () => {
//   const { eventId } = useParams();
  
//   const [formData, setFormData] = useState({
//     eventId: eventId || "",
//     type: "",
//     typeName: "",
//     priceCurrency: "",
//     ticketPrice: "",
//     bookingfeeCurrency: "",
//     bookingfeePrice: "",
//     comment: ""
//   });

//   useEffect(() => {
//     if (eventId) {
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         eventId: eventId,
//       }));
//     }
//   }, [eventId]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post("https://backend.eurofestia.com/api/v1/tickets/addticket", formData);
//       console.log("Ticket added successfully:", response.data);
//       iziToast.success({ title: 'Success', message: 'Ticket added successfully',position:"topCenter" });
//       // Reset form or show success message as needed
//       setFormData({
//         eventId: eventId || "",
//         type: "",
//         typeName: "",
//         priceCurrency: "",
//         ticketPrice: "",
//         bookingfeeCurrency: "",
//         bookingfeePrice: "",
//         comment: ""
//       });
//     } catch (error) {
//       console.error("Error adding ticket:", error);
//       iziToast.error({ title: 'Error', message: "Error in adding ticket details",position:"topCenter" });

//     }
//   };

//   return (
//     <>
//       <Sidebar />
//       <div className="home">
//         <div className="toggle-sidebar" style={{ display: "none" }}>
//           <i className="bi bi-menu"></i>
//         </div>
//         <div className="dashboard">
//           <div className="dashboard-header">
//             <h1>Add Ticket Details</h1>
//           </div>
//           <div className="form-button">
//             <button className="find-event">
//               Add Details
//             </button>
//           </div>

//           <form onSubmit={handleSubmit}>
//             <div className="row admin-row">
//               <div className="col-lg-6">
//                 <div className="admin-item">
//                   <label htmlFor="eventId">Event Id</label>
//                   <input
//                     type="text"
//                     name="eventId"
//                     placeholder="Event Id"
//                     value={formData.eventId}
//                     onChange={handleInputChange}
//                     disabled
//                   />
//                 </div>
//               </div>
//               <div className="col-lg-6">
//                 <div className="admin-item">
//                   <label htmlFor="type">Type</label>
//                   <select name="type" id="type" value={formData.type} onChange={handleInputChange}>
//                     <option value="Type 1">Type 1</option>
//                     <option value="Type 2">Type 2</option>
//                     <option value="Type 3">Type 3</option>
//                   </select>
//                 </div>
//               </div>
//               <div className="col-lg-6">
//                 <div className="admin-item">
//                   <label htmlFor="typeName">Type Name</label>
//                   <input
//                     type="text"
//                     name="typeName"
//                     placeholder="Enter Type Name"
//                     value={formData.typeName}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//               </div>
//               <div className="col-lg-6">
//                 <div className="admin-item">
//                   <label htmlFor="priceCurrency">Price Currency</label>
//                   <input
//                     type="text"
//                     name="priceCurrency"
//                     placeholder="Enter Currency"
//                     value={formData.priceCurrency}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//               </div>
//               <div className="col-lg-6">
//                 <div className="admin-item">
//                   <label htmlFor="ticketPrice">Ticket Price</label>
//                   <input
//                     type="text"
//                     name="ticketPrice"
//                     placeholder="Enter Price"
//                     value={formData.ticketPrice}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//               </div>
//               <div className="col-lg-6">
//                 <div className="admin-item">
//                   <label htmlFor="bookingfeeCurrency">Booking Fee Currency</label>
//                   <input
//                     type="text"
//                     name="bookingfeeCurrency"
//                     placeholder="Enter Booking Fee Currency"
//                     value={formData.bookingfeeCurrency}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//               </div>
//               <div className="col-lg-6">
//                 <div className="admin-item">
//                   <label htmlFor="bookingfeePrice">Booking Fee Price</label>
//                   <input
//                     type="text"
//                     name="bookingfeePrice"
//                     placeholder="Enter Booking Fee Price"
//                     value={formData.bookingfeePrice}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//               </div>
//               <div className="col-lg-12">
//                 <div className="admin-item">
//                   <label htmlFor="comment">Add Comment</label>
//                   <textarea
//                     name="comment"
//                     id="comment"
//                     placeholder="Add Comment"
//                     value={formData.comment}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//               </div>
//             </div>
//             <button type="submit" className="find-event">
//               Submit
//             </button>
//           </form>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AdminForm;

import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import { useParams } from "react-router-dom";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

const AdminForm = () => {
  const { eventId } = useParams();

  const [formData, setFormData] = useState({
    eventId: eventId || "",
    type: "Type 1",  // Default value to ensure type is set
    typeName: "",
    priceCurrency: "",
    ticketPrice: "",
    bookingfeeCurrency: "",
    bookingfeePrice: "",
    comment: ""
  });

  useEffect(() => {
    if (eventId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        eventId: eventId,
      }));
    }
  }, [eventId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://backend.eurofestia.com/api/v1/tickets/addticket", formData);
      // const response = await axios.post("http://localhost:4000/api/v1/tickets/addticket", formData);

      console.log("Ticket added successfully:", response.data);
      iziToast.success({ title: 'Success', message: 'Ticket added successfully', position: "topCenter" });
      // Reset form or show success message as needed
      setFormData({
        eventId: eventId || "",
        type: "Type 1",  // Reset to default value
        typeName: "",
        priceCurrency: "",
        ticketPrice: "",
        bookingfeeCurrency: "",
        bookingfeePrice: "",
        comment: ""
      });
    } catch (error) {
      console.error("Error adding ticket:", error);
      iziToast.error({ title: 'Error', message: "Error in adding ticket details", position: "topCenter" });
    }
  };

  return (
    <>
      <Sidebar />
      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Add Ticket Details</h1>
          </div>
          {/* <div className="form-button">
            <button className="find-event">
              Add Details
            </button>
          </div> */}

          <form onSubmit={handleSubmit}>
            <div className="row admin-row">
              <div className="col-lg-6">
                <div className="admin-item">
                  <label htmlFor="eventId">Event Id</label>
                  <input
                    type="text"
                    name="eventId"
                    placeholder="Event Id"
                    value={formData.eventId}
                    onChange={handleInputChange}
                    disabled
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-item">
                  <label htmlFor="type">Type</label>
                  <select name="type" id="type" value={formData.type} onChange={handleInputChange}>
                    <option value="Type 1">Type 1</option>
                    <option value="Type 2">Type 2</option>
                    <option value="Type 3">Type 3</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-item">
                  <label htmlFor="typeName">Type Name</label>
                  <input
                    type="text"
                    name="typeName"
                    placeholder="Enter Type Name"
                    value={formData.typeName}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-item">
                  <label htmlFor="priceCurrency">Price Currency</label>
                  <input
                    type="text"
                    name="priceCurrency"
                    placeholder="Enter Currency"
                    value={formData.priceCurrency}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-item">
                  <label htmlFor="ticketPrice">Ticket Price</label>
                  <input
                    type="text"
                    name="ticketPrice"
                    placeholder="Enter Price"
                    value={formData.ticketPrice}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-item">
                  <label htmlFor="bookingfeeCurrency">Booking Fee Currency</label>
                  <input
                    type="text"
                    name="bookingfeeCurrency"
                    placeholder="Enter Booking Fee Currency"
                    value={formData.priceCurrency}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="admin-item">
                  <label htmlFor="bookingfeePrice">Booking Fee Price</label>
                  <input
                    type="text"
                    name="bookingfeePrice"
                    placeholder="Enter Booking Fee Price"
                    value={formData.bookingfeePrice}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="admin-item">
                  <label htmlFor="comment">Add Comment</label>
                  <textarea
                    name="comment"
                    id="comment"
                    placeholder="Add Comment"
                    value={formData.comment}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <button type="submit" className="find-event">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminForm;
