

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import $ from "jquery";
import { Modal, Form, Button } from "react-bootstrap";

const TicketManagement = () => {
  const dataTableRef = useRef();
  const [tickets, setTickets] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [currentTicket, setCurrentTicket] = useState(null);
  const [formData, setFormData] = useState({
    eventId: "",
    type: "",
    typeName: "",
    priceCurrency: "",
    ticketPrice: "",
    // bookingfeeCurrency: "",
    bookingfeePrice: "",
  });

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axios.get("https://backend.eurofestia.com/api/v1/tickets/ticketsall");
        // const response = await axios.get("http://localhost:4000/api/v1/tickets/ticketsall");

        console.log("Fetched tickets:", response.data.tickets);
        if (Array.isArray(response.data.tickets)) {
          setTickets(response.data.tickets);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    };

    fetchTickets();
  }, []);

  const handleShowDelete = (ticket) => {
    setCurrentTicket(ticket);
    setShowDelete(true);
  };

  const handleShowUpdate = (ticket) => {
    setCurrentTicket(ticket);
    setFormData({
      eventId: ticket.eventId,
      type: ticket.type,
      typeName: ticket.typeName,
      priceCurrency: ticket.priceCurrency,
      ticketPrice: ticket.ticketPrice,
      bookingfeeCurrency: ticket.priceCurrency,
      bookingfeePrice: ticket.bookingfeePrice,
    });
    setShowUpdate(true);
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
    setCurrentTicket(null);
  };

  const handleCloseUpdate = () => {
    setShowUpdate(false);
    setCurrentTicket(null);
  };

  const handleDelete = async () => {
    if (currentTicket) {
      try {
        await axios.delete(`https://backend.eurofestia.com/api/v1/tickets/ticket/${currentTicket._id}`);
        setTickets(tickets.filter(ticket => ticket._id !== currentTicket._id));
        handleCloseDelete();
      } catch (error) {
        console.error("Error deleting ticket:", error);
      }
    }
  };

  const handleSaveDetails = async () => {
    if (!currentTicket) return;

    try {
      const response = await axios.put(
        `https://backend.eurofestia.com/api/v1/tickets/ticket/${currentTicket._id}`,
        formData
      );

      setTickets(tickets.map(ticket => ticket._id === currentTicket._id ? response.data.ticket : ticket));
      setShowUpdate(false);
    } catch (error) {
      console.error("Error updating ticket details:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (tickets.length > 0) {
      $(dataTableRef.current).DataTable();
    }
  }, [tickets]);
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <Sidebar />
      <Modal show={showDelete} onHide={handleCloseDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content1">
            <p>Do you want to delete this ticket?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="find-event-delete" onClick={handleDelete}>
            Delete Ticket
          </button>
          <button className="find-event" onClick={handleCloseDelete}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showUpdate} onHide={handleCloseUpdate} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update Ticket Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content1">
            <form>
              {Object.keys(formData).map((key) => (
                <div className="modal-box" key={key}>
                  <label htmlFor={key}>{capitalizeFirstLetter(key)}</label>
                  <input
                    type={key.includes("Price") ? "number" : "text"}
                    name={key}
                    value={formData[key]}
                    onChange={handleInputChange}
                    disabled={key === "eventId"}
                  />
                </div>
              ))}
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdate}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveDetails}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Ticket Management</h1>
          </div>
          <div className="container-fluid">
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column mx-auto">
                <div className="container mt-4 overflow-auto">
                  <table id="datatable" className="table" ref={dataTableRef}>
                    <thead>
                      <tr className="tr1">
                        {/* <th>Ticket Id</th> */}
                        <th>Event Id</th>
                        <th>Type</th>
                        <th>Type Name</th>
                        <th> Currency</th>
                        <th>Ticket Price</th>
                        {/* <th>Booking Fee Currency</th> */}
                        <th>Booking Fee Price</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tickets.map((ticket) => (
                        <tr key={ticket._id}>
                          {/* <td>{ticket._id}</td> */}
                          <td>{ticket.eventId}</td>
                          {/* <td>{ticket.type}</td> */}
                          <td>{ticket.type.replace('Type ', '')}</td> {/* Extracting the number from the type */}

                          {/* <td className="ellipsis">
                            <abbr className="text-decoration-none me-4" title={ticket.type}>
                              {ticket.type}
                            </abbr>
                          </td> */}
                          {/* <td>{ticket.typeName}</td> */}
                          <td className="ellipsis">
                            <abbr className="text-decoration-none me-4" title={ticket.typeName}>
                              {ticket.typeName}
                            </abbr>
                          </td>
                          <td>{ticket.priceCurrency}</td>
                          <td>{ticket.ticketPrice}</td>
                          {/* <td>{ticket.priceCurrency}</td> */}
                          <td>{ticket.bookingfeePrice}</td>
                          <td className="td1">
                            <i className="fa-regular fa-pen-to-square me-1" onClick={() => handleShowUpdate(ticket)}></i>
                            <i className="fa-solid fa-trash" onClick={() => handleShowDelete(ticket)}></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketManagement;
