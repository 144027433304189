import React, { useState, useEffect, useCallback } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

const AdminlistedEvents = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [selectingStartDate, setSelectingStartDate] = useState(true);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [minprice, setMinprice] = useState("");
  const [maxprice, setMaxprice] = useState("");
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedGenre, setSelectedGenre] = useState("");
  const [keyword, setKeyword] = useState("");
  const [eventCount, setEventCount] = useState(0);

  const genres = [
    "Pop", "Rock", "Jazz", "Classical", "Hip-Hop", "Country", "BALLADS/ROMANTIC",
    "BLUES", "CHILDREN'S MUSIC", "Dance", "Electronic", "Folk", "Rap", "Holiday",
    "Latin", "Medieval/Renaissance", "Metal", "New Age", "Other", "Pop", "R&B",
    "Reggae", "Religious", "World"
  ];

  const toggleCalendar = () => {
    setCalendarVisible(!calendarVisible);
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleDateChange = (date) => {
    if (selectingStartDate) {
      setStartDate(date);
      setEndDate(null);
      setSelectingStartDate(false);
    } else {
      if (date >= startDate) {
        setEndDate(date);
      } else {
        setStartDate(date);
        setEndDate(null);
      }
      setCalendarVisible(false);
      setSelectingStartDate(true);
    }
  };

  const handleSearchChange = (event) => {
    const { name, value } = event.target;
    if (name === "country") {
      setCountry(value);
    } else if (name === "city") {
      setCity(value);
    } else if (name === "genre") {
      setSelectedGenre(value);
    } else if (name === "keyword") {
      setKeyword(value);
    } else if (name === "minprice") {
      setMinprice(value);
    } else if (name === "maxprice") {
      setMaxprice(value);
    }
  };

  const fetchEvents = useCallback(async (page = 0, initialFetch = false) => {
    const countryCode = country ? `&country=${country}` : "";
    const cityQuery = city ? `&city=${city}` : "";
    const genreQuery = selectedGenre ? `&classification=${selectedGenre}` : "";
    const keywordQuery = keyword ? `&keyword=${keyword}` : "";
    let dateRangeQuery = "";
    if (startDate && endDate) {
      const startDateISO = startDate.toISOString();
      const endDateISO = endDate.toISOString();
      dateRangeQuery = `&startDate=${startDateISO}&endDate=${endDateISO}`;
    }
    const minPriceQuery = minprice ? `&minPrice=${minprice}` : "";
    const maxPriceQuery = maxprice ? `&maxPrice=${maxprice}` : "";

    try {
      const response = await axios.get(
        `https://backend.eurofestia.com/api/v1/events/details?page=${page}${cityQuery}${countryCode}${genreQuery}${keywordQuery}${dateRangeQuery}${minPriceQuery}${maxPriceQuery}`
      );
      console.log("API Response:", response.data);

      const fetchedEvents = response.data.events || [];
      if (initialFetch) {
        setEvents(fetchedEvents);
        setEventCount(response.data.eventCount);
      } else {
        setEvents((prevEvents) => [...prevEvents, ...fetchedEvents]);
        setEventCount(response.data.eventCount);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  }, [country, city, selectedGenre, keyword, startDate, endDate, minprice, maxprice]);

  useEffect(() => {
    fetchEvents(0, true);
  }, [fetchEvents]);

  const handleFilterSubmit = () => {
    setEvents([]);
    setCurrentPage(0);
    fetchEvents(0, true);
  };

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    fetchEvents(nextPage);
  };

  const clearSelection = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectingStartDate(true);
  };

  return (
    <>
      <Navbar />

      <div className="container-fluid events">
        <div className="row">
          <div className="col-lg-8 event-right mx-auto">
            <h1>Explore The Events</h1>
            <h1>Of</h1>
            <h1>Europe</h1>
            <div className="d-flex justify-content-center">
              <Link to="/details">
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid event-main">
        <div className="container event-search">
          <div className="input-group event-group">
            <div className="input-group-prepend event-append">
              <span className="input-group-text event-text" id="basic-addon2">
                <i className="bi bi-search"></i>
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              aria-label="search"
              aria-describedby="basic-addon2"
              name="keyword"
              value={keyword}
              onChange={handleSearchChange}
            />
            <div className="input-group-append event-append">
              <span className="input-group-text event-text" id="basic-addon2">
                <i className="bi bi-send" onClick={handleFilterSubmit}></i>
              </span>
            </div>
          </div>
          <div className="filter-icons">
            <i className="bi bi-calendar4-week" onClick={toggleCalendar}></i>
            <i className="bi bi-funnel mx-5" onClick={toggleFilter}></i>
          </div>
          {calendarVisible && (
            <div className="calendar">
              <div className="calendar-labels">
                <label>
                  <span>Start Date : </span>{" "}
                  {startDate ? startDate.toLocaleDateString() : "Not Selected"}
                </label>
                <label>
                  <span>End Date : </span>{" "}
                  {endDate ? endDate.toLocaleDateString() : "Not Selected"}
                </label>
              </div>
              <DatePicker
                selected={selectingStartDate ? startDate : endDate}
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsStart={selectingStartDate}
                selectsEnd={!selectingStartDate}
                inline
                className="calendar-relative"
              />
              <div className="calendar-button">
                <button onClick={clearSelection}>Clear</button>
                <button onClick={handleFilterSubmit}>Filter by Date</button>
              </div>
            </div>
          )}
        </div>

        {showFilter && (
          <div className="container filter-tab">
            <div className="filters">
              <div className="row">
                <div className="col-lg-3 filter-column p-3">
                  <input
                    type="text"
                    name="country"
                    className="form-control"
                    placeholder="Country"
                    value={country}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="col-lg-3 filter-column p-3">
                  <input
                    type="text"
                    name="city"
                    className="form-control"
                    placeholder="City"
                    value={city}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="col-lg-3 p-3">
                  <select name="genre" value={selectedGenre} onChange={handleSearchChange}>
                    <option value="">Select Genre</option>
                    {genres.map((genre) => (
                      <option key={genre} value={genre}>{genre}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-3 filter-column p-3">
                  <input
                    type="text"
                    name="minprice"
                    className="form-control"
                    placeholder="Min-Price"
                    value={minprice}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="col-lg-3 filter-column p-3">
                  <input
                    type="text"
                    name="maxprice"
                    className="form-control"
                    placeholder="Max-Price"
                    value={maxprice}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="col-lg-3 filter-column p-3">
                  <button onClick={handleFilterSubmit}>Apply Filters</button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="container event-month">
          <i>
            <span className="px-5"> </span>
          </i>
        </div>

        <div className="container">
          {Array.isArray(events) && events.map((event) => (
            <div className="row event-card" key={event.eventId}>
              <div className="col-lg-1 content1">
                <h2>
                  {new Date(event.date).toLocaleString('en-US', { weekday: 'short' })} <br />
                  {new Date(event.date).getDate()}
                </h2>
              </div>
              <div className="col-lg-6 content2 pe-2">
                <h4>{new Date(event.date).toLocaleDateString()}</h4>
                <h1>{event.name}</h1>
                <h3>{event.venue}</h3>
                <h3>{event.city}, {event.country}</h3>
                <p>{event.attraction}</p>
              </div>
              <div className="col-lg-3 content3">
                <img src={event.imageUrl} alt={event.name} />
              </div>
              <div className="col-lg-2 content4">
                <Link to={`/details/${event.eventId}`} className="text-decoration-none text-white">
                  <h5>
                    Details <i className="bi bi-chevron-right"></i>
                  </h5>
                </Link>
              </div>
            </div>
          ))}

          {filteredEvents.length > 0 && (
            <div className="container mt-4">
              <h2>Filtered Events by Date Range:</h2>
              {filteredEvents.map((event) => (
                <div className="row event-card" key={event.eventId}>
                  <div className="col-lg-1 content1">
                    <h2>
                      {new Date(event.date).toLocaleString('en-US', { weekday: 'short' })} <br />
                      {new Date(event.date).getDate()}
                    </h2>
                  </div>
                  <div className="col-lg-6 content2 pe-2">
                    <h4>{new Date(event.date).toLocaleDateString()}</h4>
                    <h1>{event.name}</h1>
                    <h3>{event.venue}</h3>
                    <h3>{event.city}, {event.country}</h3>
                    <p>{event.attraction}</p>
                  </div>
                  <div className="col-lg-3 content3">
                    <img src={event.imageUrl} alt={event.name} />
                  </div>
                  <div className="col-lg-2 content4">
                    <Link to={`/details/${event.eventId}`} className="text-decoration-none text-white">
                      <h5>
                        Details <i className="bi bi-chevron-right"></i>
                      </h5>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="event-button">
            <button onClick={handleLoadMore}>Load More</button>
            <p>{events.length} of {eventCount} events displayed</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminlistedEvents;
