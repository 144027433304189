// // import React, { useEffect, useRef } from "react";
// // import Navbar from "./Navbar";
// // import $ from "jquery";

// // const Transactions = () => {

// //     const dataTableRef = useRef();

// //     useEffect(() => {
// //       $(dataTableRef.current).DataTable();
// //     }, []);

// //   return (
// //     <>
// //       <Navbar login={true} />

// //       <div className="container-fluid signin">
// //         <div className="container">
// //           <div className="signin-form">
// //             <div className="sign-heading px-3">
// //               <h1>Transaction History</h1>
// //             </div>
// //             <div className="row foot-tablerow">
// //               <div className="col-lg-12 maintable-column mx-auto">
// //                 <div className="container mt-4 overflow-auto">
// //                   <table id="datatable" className="table" ref={dataTableRef}>
// //                     <thead>
// //                       <tr className="tr1 text-center">
                        
// //                         <th>Transaction Id</th>
// //                         <th>Show Name</th>
// //                         <th>Show Venue</th>

// //                         <th>Ticket Quantity</th>
// //                         <th>Amount</th>
// //                         <th>Actions</th>
// //                       </tr>
// //                     </thead>
// //                     <tbody>
// //                       <tr className="tr1">
                        
// //                         <td>#09089</td>
// //                         <td className="ellipsis first"><abbr className="text-decoration-none me-4" title="London">Era's tour </abbr></td>
// //                         <td className="ellipsis second"><abbr className="text-decoration-none" title="London">London, England </abbr> </td>
// //                         <td>2</td>
// //                         <td>$ 200</td>
// //                         <td>
// //                           <i className="fa fa-download"></i> 
// //                         </td>
// //                       </tr>
// //                       <tr>
                        
// //                         <td>#09089</td>
// //                         <td className="ellipsis first"><abbr className="text-decoration-none me-4" title="London">Era's tour </abbr></td>
// //                         <td className="ellipsis second"><abbr className="text-decoration-none" title="London">London, England </abbr> </td>
                        
// //                         <td>2</td>
// //                         <td>$ 200</td>
// //                         <td>
// //                           <i className="fa fa-download"></i> 
// //                         </td>
// //                       </tr>
// //                       <tr>
                       
// //                         <td>#09089</td>
// //                         <td className="ellipsis first"><abbr className="text-decoration-none me-4" title="London">Era's tour </abbr></td>
// //                         <td className="ellipsis second"><abbr className="text-decoration-none" title="London">London, England </abbr> </td>
                        
// //                         <td>2</td>
// //                         <td>$ 200</td>
// //                         <td>
// //                           <i className="fa fa-download"></i> 
// //                         </td>
// //                       </tr>
// //                       <tr>
                       
// //                         <td>#09089</td>
// //                         <td className="ellipsis first"><abbr className="text-decoration-none me-4" title="London">Era's tour </abbr></td>
// //                         <td className="ellipsis second"><abbr className="text-decoration-none" title="London">London, England </abbr> </td>
                        
// //                         <td>2</td>
// //                         <td>$ 200</td>
// //                         <td>
// //                           <i className="fa fa-download"></i>
// //                         </td>
// //                       </tr>

// //                       <tr>
                        
// //                         <td>#09089</td>
// //                         <td className="ellipsis first"><abbr className="text-decoration-none me-4" title="London">Era's tour </abbr></td>
// //                         <td className="ellipsis second"><abbr className="text-decoration-none" title="London">London, England </abbr> </td>
                        
// //                         <td>2</td>
// //                         <td>$ 200</td>
// //                         <td>
// //                           <i className="fa fa-download"></i>
// //                         </td>
// //                       </tr>
// //                     </tbody>
// //                   </table>
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </>
// //   );
// // };

// // export default Transactions;











// import React, { useEffect, useState, useRef } from "react";
// import axios from "axios";
// import Navbar from "./Navbar";
// import $ from "jquery";

// const Transactions = () => {
//   const dataTableRef = useRef();
//   const [bookings, setBookings] = useState([]);
//   const userId = localStorage.getItem('userid'); // Replace with actual user ID logic
//   console.log(userId)

//   useEffect(() => {
//     const fetchBookings = async () => {
//       try {
//         const response = await axios.get(`https://backend.eurofestia.com/api/v1/payments/bookings/user/${userId}`);
//         console.log("Fetched bookings:", response.data.bookings);
//         if (Array.isArray(response.data.bookings)) {
//           setBookings(response.data.bookings);
//         } else {
//           console.error("Unexpected response format:", response.data);
//         }
//       } catch (error) {
//         console.error("Error fetching bookings:", error);
//       }
//     };

//     fetchBookings();
//   }, [userId]);

//   useEffect(() => {
//     if (bookings.length > 0) {
//       $(dataTableRef.current).DataTable();
//     }
//   }, [bookings]);

//   return (
//     <>
//       <Navbar login={true} />
//       <div className="container-fluid signin">
//         <div className="container">
//           <div className="signin-form">
//             <div className="sign-heading px-3">
//               <h1>Transaction History</h1>
//             </div>
//             <div className="row foot-tablerow">
//               <div className="col-lg-12 maintable-column mx-auto">
//                 <div className="container mt-4 overflow-auto">
//                   <table id="datatable" className="table" ref={dataTableRef}>
//                     <thead>
//                       <tr className="tr1 text-center">
//                         <th>Transaction Id</th>
//                         <th>Show Name</th>
//                         <th>Show Date</th>
//                         <th>Ticket Quantity</th>
//                         <th>Amount</th>
//                         <th>Actions</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {bookings.map((booking) => (
//                         <tr key={booking._id}>
//                           <td>{booking.paymentId}</td>
//                           <td className="ellipsis first"><abbr className="text-decoration-none me-4" title={booking.eventName}>{booking.eventName}</abbr></td>
//                           <td className="ellipsis second"><abbr className="text-decoration-none" title={booking.eventDate}>{booking.eventDate}</abbr></td>
//                           <td>{booking.ticketquantity}</td>
//                           <td>{booking.currency} {booking.amount}</td>
//                           <td>
//                             <i className="fa fa-download"></i>
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Transactions;


import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import $ from "jquery";
import { useNavigate } from "react-router-dom";

const Transactions = () => {
  const dataTableRef = useRef();
  const [bookings, setBookings] = useState([]);
  const userId = localStorage.getItem('userid'); // Replace with actual user ID logic
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.get(`https://backend.eurofestia.com/api/v1/payments/bookings/user/${userId}`);
        console.log("Fetched bookings:", response.data.bookings);
        if (Array.isArray(response.data.bookings)) {
          setBookings(response.data.bookings);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    };

    fetchBookings();
  }, [userId]);

  useEffect(() => {
    if (bookings.length > 0) {
      $(dataTableRef.current).DataTable();
    }
  }, [bookings]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  const handleDownloadClick = (booking) => {
    navigate(`/invoice/${booking._id}`, { state: { booking } });
  };
  

  return (
    <>
      <Navbar login={true} />
      <div className="container-fluid signin ">
        <div className="container">
          <div className="signin-form">
            <div className="sign-heading px-3">
              <h1>Transaction History</h1>
            </div>
            <div className="row foot-tablerow">
              <div className="col-lg-12 maintable-column mx-auto">
                <div className="container mt-4 overflow-auto">
                  <table id="datatable" className="table" ref={dataTableRef}>
                    <thead>
                      <tr className="tr1 text-center">
                        <th>Transaction Id</th>
                        <th>Transaction Date</th>
                        <th>Show Name</th>
                        <th>Show Date</th>
                        <th>Ticket Quantity</th>
                        <th>Amount</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookings.map((booking) => (
                        <tr key={booking._id}>
                          <td>{booking.paymentId}</td>
                          <td>{formatDate(booking.createdAt)}</td>
                          <td className="ellipsis first"><abbr className="text-decoration-none me-4" title={booking.eventName}>{booking.eventName}</abbr></td>
                          <td className="ellipsis second"><abbr className="text-decoration-none" title={formatDate(booking.eventDate)}>{formatDate(booking.eventDate)}</abbr></td>
                          {/* <td className="ellipsis second"><abbr className="text-decoration-none" title={formattedDate}>{formattedDate}</abbr></td> */}
                          <td>{booking.ticketquantity}</td>
                          <td>{booking.currency} {booking.amount}</td>
                          <td>
                            <i 
                              className="fa fa-download" 
                              onClick={() => handleDownloadClick(booking)}
                              style={{ cursor: 'pointer' }}
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;
