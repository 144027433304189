
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import $ from "jquery";
import { Modal } from "react-bootstrap";

const ContactUsManagement = () => {
  const dataTableRef = useRef();
  const [contacts, setContacts] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get("https://backend.eurofestia.com/api/v1/contactus/getcontacts");
        // const response = await axios.get("http://localhost:4000/api/v1/contactus/getcontacts");

        console.log("Fetched contacts:", response.data.contacts);
        if (Array.isArray(response.data.contacts)) {
          setContacts(response.data.contacts);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    fetchContacts();
  }, []);

  const handleShowDelete = (contact) => {
    setCurrentContact(contact);
    setShowDelete(true);
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
    setCurrentContact(null);
  };

  const handleDelete = async () => {
    if (currentContact) {
      try {
        await axios.delete(`https://backend.eurofestia.com/api/v1/contactus/contact/${currentContact._id}`);
                // await axios.delete(`http://localhost:4000/api/v1/contactus/contact/${currentContact._id}`);

        setContacts(contacts.filter(contact => contact._id !== currentContact._id));
        handleCloseDelete();
      } catch (error) {
        console.error("Error deleting contact:", error);
      }
    }
  };

  useEffect(() => {
    if (contacts.length > 0) {
      $(dataTableRef.current).DataTable();
    }
  }, [contacts]);

  return (
    <>
      <Sidebar />
      <Modal show={showDelete} onHide={handleCloseDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content1">
            <p>Do you want to delete this contact?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="find-event-delete" onClick={handleDelete}>
            Delete Contact
          </button>
          <button className="find-event" onClick={handleCloseDelete}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Contact Management</h1>
          </div>
          <div className="container-fluid">
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column mx-auto">
                <div className="container mt-4 overflow-auto">
                  <table id="datatable" className="table" ref={dataTableRef}>
                    <thead>
                      <tr className="tr1">
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Subject</th>
                        <th>Message</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts.map((contact) => (
                        <tr key={contact._id}>
                          <td>{contact.name}</td>
                          <td>{contact.email}</td>
                          <td>{contact.phone}</td>
                          <td>{contact.subject}</td>
                          <td>{contact.message}</td>
                          <td className="td1">
                            <i className="fa-solid fa-trash" onClick={() => handleShowDelete(contact)}></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsManagement;
