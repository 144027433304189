const europeCountryCodes = {
    // "Albania": "AL",
    "Andorra": "AD",
    // "Armenia": "AM",
    "Austria": "AT",
    // "Azerbaijan": "AZ",
    // "Belarus": "BY",
    "Belgium": "BE",
    // "Bosnia and Herzegovina": "BA",
    // "Bulgaria": "BG",
    // "Croatia": "HR",
    "Cyprus": "CY",
    "Czech Republic": "CZ",
    "Denmark": "DK",
    "Estonia": "EE",
    "Finland": "FI",
    "France": "FR",
    // "Georgia": "GE",
    "Germany": "DE",
    // "Greece": "GR",
    // "Hungary": "HU",
    // "Iceland": "IS",
    "Ireland": "IE",
    "Italy": "IT",
    // "Kazakhstan": "KZ",
    // "Kosovo": "XK",
    // "Latvia": "LV",
    "Liechtenstein": "LI",
    // "Lithuania": "LT",
    "Luxembourg": "LU",
    // "Malta": "MT",
    // "Moldova": "MD",
    "Monaco": "MC",
    // "Montenegro": "ME",
    "Netherlands": "NL",
    // "North Macedonia": "MK",
    "Norway": "NO",
    "Poland": "PL",
    "Portugal": "PT",
    // "Romania": "RO",
    // "Russia": "RU",
    // "San Marino": "SM",
    // "Serbia": "RS",
    // "Slovakia": "SK",
    // "Slovenia": "SI",
    "Spain": "ES",
    "Sweden": "SE",
    "Switzerland": "CH",
    "Turkey": "TR",
    // "Ukraine": "UA",
    "United Kingdom": "GB",
    "Great Britain":"GB",
    // "Vatican City": "VA",
    
};

module.exports = europeCountryCodes;
