import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Terms = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <>
      <Navbar />

      <div className="container-fluid privacy">
        <h1>Terms & Conditions</h1>
      </div>
      <div className="container-fluid privacy-content">
        {/* <div className="container pt-5">
          <h3>Terms & Conditions</h3>
          <p>
            We respect your privacy. When you give us your personal information,
            we use it only to fulfill the transaction or service you have
            requested.
          </p>
          <p>
            We do not subscribe you to marketing emails without your consent.
          </p>
          <p>
            We do not sell or give away your contact information to any other
            entities.
          </p>
          <p>
            We do not allow the vendors who help us process transactions to sell
            or give away your information either.
          </p>
          <p>
            If you have questions about how we use your information please
            contact privacy@nngroup.com.
          </p>
          <h3>Detailed Terms and Conditions</h3>
          <ol>
            <li>
              <b>SCOPE</b>
              <p>
                This policy applies to personal information collected on
                websites owned or controlled by Nielsen Norman Group
                (collectively referred to in this policy as “we”, "us" or
                "our"). The aim of this policy is to tell you how we will use
                any personal information we collect or you provide through our
                websites. Please read it carefully before you proceed. The data
                controller in respect of this website is Nielsen Norman Group.
              </p>
            </li>
            <li>
              <b>WHAT PERSONAL INFORMATION DO WE COLLECT?</b>
              <p>
                You do not have to give us any personal information in order to
                use most of this website. However, if you wish to subscribe to
                our newsletter, attend the UX Conference, attend or purchase an
                Online Seminar, purchase a Research Report, or request further
                information, we may collect the following personal information
                from you: name, address, phone number, email address, employment
                details, and employer details. We will also keep a record of any
                financial transaction you make with us but we do not directly
                collect, process or store your debit or credit card information.
                Online payments made through this website are processed securely
                by third party payment providers.
              </p>
              <p>
                We use different processors for different types of transactions
                and to manage support for different services.For more
                information about how your data will be handled please refer to
                the respective service provider's privacy policy:
              </p>
              <p>
                Research Report Purchases: FastSpring
                https://fastspring.com/privacy/
              </p>
              <p>
                Online Seminars: Crowdcast,
                https://www.crowdcast.io/privacy-policy
              </p>
              <p>
                UX Conference Payments: Stripe, https://stripe.com/us/privacy
              </p>
              <p>
                In addition, we may automatically collect information about the
                website that you came from or are going to. We also collect
                information about the pages of this website which you visit, IP
                addresses, the type of browser you use and the times you access
                this website. However, this information is aggregated across all
                visitors and we do not use it to identify you.
              </p>
            </li>
            <li>
              <b>HOW WILL YOUR PERSONAL INFORMATION BE USED AND SHARED?</b>
              <p>
                The personal information we collect allows us to: <br />
                deliver the products and services you have ordered; <br />
                verify your identity and details of your payment method or
                credit card amount; <br />
                administer our website and provide customer services;
                <br />
                meet legal, regulatory and compliance requirements;
                <br />
                monitor and analyse the use of any account to prevent,
                investigate and/or report fraud, terrorism, misrepresentation,
                security incidents or crime;
              </p>
            </li>
          </ol>
        </div> */}
        <div className="container pt-5">
          <h2>Terms of Service</h2>
          <h3>Effective Date: 11/1/2024</h3>
          <p>Welcome to Eurofestia! These Terms of Service ("Terms") govern your access to and use of our website, services, and products. By accessing or using Eurofestia, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our website or services.</p>
          <h3>1. General Information</h3>
          <ul>
            <li><strong>Website:</strong> Eurofestia (the "Site")</li>
            <li><strong>Owner:</strong> Noovative Group EOOD</li>
            <li><strong>Registered Address:</strong> Rayko Daskalov Str 68, Plovdiv, 4000, Bulgaria</li>
            <li><strong>Company Identification Number:</strong> 207847263</li>
            <li><strong>Contact Information:</strong> <u>info@eurofestia.com</u></li>
          </ul>
          <h3>2. Acceptance of Terms</h3>
          <p>By accessing and using Eurofestia, you acknowledge that you have read, understood, and agree to be bound by these Terms and any additional terms and conditions referenced herein or otherwise made available to you.</p>
          <h3>3. Use of the Website</h3>
          <h5>3.1 Eligibility</h5>
          <p>You must be at least 18 years old or the legal age of majority in your jurisdiction to use this Site and purchase tickets. By using the Site, you represent and warrant that you meet these eligibility requirements.</p>
          <h5>3.2 Account Registration</h5>
          <p>To purchase tickets, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your account credentials.</p>
          <h5>3.3 Prohibited Activities</h5>
          <p>You agree not to engage in any activities that may:</p>
          <ul>
            <li>Violate any applicable local, national, or international law or regulation.</li>
            <li>Interfere with or disrupt the operation of the Site or servers connected to the Site.</li>
            <li>Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity.</li>
            <li>Use the Site for any unauthorized commercial purposes.</li>
          </ul>
          <h3>4. Ticket Purchase and Payment</h3>
          <h5>4.1 Purchase Agreement</h5>
          <p>By purchasing tickets on Eurofestia, you enter into a binding agreement with Noovative Group EOOD. All sales are final, and no refunds, exchanges, or cancellations will be granted, except as provided in these Terms or as required by applicable law.</p>
          <h5>4.2 Payment</h5>
          <p>All payments must be made using the payment methods provided on the Site. You agree to provide valid payment information and authorize Noovative Group EOOD to charge your chosen payment method for the total amount of your purchase.</p>
          <h5>4.3 Pricing</h5>
          <p>All prices are listed in [Currency] and include any applicable taxes, fees, or service charges unless otherwise stated. We reserve the right to change prices at any time without notice.</p>
          <h5>4.4 Delivery of Tickets</h5>
          <p>Tickets will be delivered electronically to the email address provided during purchase. It is your responsibility to ensure the accuracy of your email address and to check your spam or junk folders for the ticket email.</p>
          <h3>5. Event Information</h3>
          <h5>5.1 Event Changes</h5>
          <p>Noovative Group EOOD is not responsible for any changes to the event schedule, including changes to the date, time, location, or performers. If an event is canceled or postponed, we will notify you as soon as possible with information about refunds or rescheduled dates.</p>
          <h5>5.2 Admission</h5>
          <p>Entry to events may be subject to additional terms and conditions imposed by the event organizer or venue. You agree to comply with all applicable event and venue rules.</p>
          <h5>5.3 Resale of Tickets</h5>
          <p>The resale of tickets purchased on Eurofestia is strictly prohibited unless explicitly permitted by applicable law. Noovative Group EOOD reserves the right to cancel any tickets that have been resold or used in violation of these Terms.</p>
          <h3>6. Intellectual Property</h3>
          <p>All content on the Site, including text, graphics, logos, images, and software, is the property of Noovative Group EOOD or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, or distribute any content from the Site without prior written permission from Noovative Group EOOD.</p>
          <h3>7. Limitation of Liability</h3>
          <p>To the fullest extent permitted by law, Noovative Group EOOD shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of or inability to use the Site, including but not limited to ticket purchases, event attendance, or any other interactions with the Site.</p>
          <h3>8. Indemnification</h3>
          <p>You agree to indemnify and hold harmless Noovative Group EOOD and its affiliates, officers, directors, employees, and agents from and against any claims, damages, losses, liabilities, costs, and expenses arising out of or related to your use of the Site, your breach of these Terms, or your violation of any rights of another.</p>
          <h3>9. Governing Law and Jurisdiction</h3>
          <p>These Terms are governed by and construed in accordance with the laws of Bulgaria. Any disputes arising out of or relating to these Terms or your use of the Site shall be resolved exclusively in the courts of Plovdiv, Bulgaria.</p>
          <h3>10. Changes to Terms</h3>
          <p>Noovative Group EOOD reserves the right to modify these Terms at any time without prior notice. Your continued use of the Site following any such changes constitutes your acceptance of the new Terms. Please review these Terms periodically for any updates.</p>
          <h3>11. Contact Information</h3>
          <p>If you have any questions or concerns about these Terms, please contact us at:</p>
          <ul>
            <li><strong>Email:</strong> info@eurofestia.com</li>
            <li><strong>Address:</strong> Noovative Group EOOD, Rayko Daskalov Str 68, Plovdiv, 4000, Bulgaria</li>
          </ul>
          <p>Thank you for choosing Eurofestia. We hope you enjoy the events and experiences we offer!</p>
          <p>&nbsp;</p>
        </div>
      </div>



      <Footer />
    </>
  );
};

export default Terms;
