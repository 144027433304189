// import React, { useState } from "react";
// import Navbar from "./Navbar";
// import { Link } from "react-router-dom";

// const Reset = () => {
//   const [showPassword, setShowPassword] = useState(false);

//   const togglePassword = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     <>
//       <Navbar />

//       <div className="container-fluid signin">
//         <div className="container">
//           <div className="signin-form">
//             <div className="row">
//               <div className="col-lg-6 my-auto">
//                 <div className="signin-left">
//                   <h1>Reset Password</h1>
//                   <p>
//                   Please enter new password.
//                   </p>
//                 </div>
//               </div>
//               <div className="col-lg-6">
//                 <div className="signin-right">
//                   <form action="/">
                    
//                     <div className="sign-item">
//                       <label htmlFor="name">New Password</label>
//                       <div class="input-group sign-group">
//                         <input
//                           type={showPassword ? "text" : "password"}
//                           class="form-control"
//                           placeholder="Enter new Password"
//                           aria-label="password"
//                           aria-describedby="basic-addon2"
//                         />
//                         <div class="input-group-append sign-append">
//                           <span
//                             class="input-group-text sign-text"
//                             id="basic-addon2"
//                             onClick={togglePassword}
//                           >
//                             <i className="bi bi-eye"></i>
//                           </span>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="sign-item">
//                       <label htmlFor="name">Confirm Password</label>
//                       <div class="input-group sign-group">
//                         <input
//                           type={showPassword ? "text" : "password"}
//                           class="form-control"
//                           placeholder="Confirm Password"
//                           aria-label="password"
//                           aria-describedby="basic-addon2"
//                         />
//                         <div class="input-group-append sign-append">
//                           <span
//                             class="input-group-text sign-text"
//                             id="basic-addon2"
//                             onClick={togglePassword}
//                           >
//                             <i className="bi bi-eye"></i>
//                           </span>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="signin-button">
//                         <Link to="/signin">
//                         <button>Continue</button>
//                         </Link>
//                     </div>
//                   </form>
//                   <h6>Remembered Password ? <Link to="/signin" className="text-decoration-none"><span>Login Here</span></Link></h6>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Reset;


// src/components/Reset.js
import React, { useState } from "react";
import Navbar from "./Navbar";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

const Reset = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!password || !confirmPassword) {
      iziToast.error({ title: 'Error', message: 'Please fill all fields',position:"topCenter" });
      return;
    }
    if (password !== confirmPassword) {
      iziToast.error({ title: 'Error', message: 'Passwords do not match',position:"topCenter" });
      return;
    }

    try {
      const email = localStorage.getItem("resetEmail");
      // await axios.post('http://localhost:4000/api/v1/mail/update-password', { email, password });
      await axios.post('https://backend.eurofestia.com/api/v1/mail/update-password', { email, password });

      iziToast.success({ title: 'Success', message: 'Password updated successfully',position:"topCenter" });
      navigate('/signin');
    } catch (error) {
      iziToast.error({ title: 'Error', message: error.response.data.message });
    }
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid signin">
        <div className="container">
          <div className="signin-form">
            <div className="row">
              <div className="col-lg-6 my-auto">
                <div className="signin-left">
                  <h1>Reset Password</h1>
                  <p>Please enter new password.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="signin-right">
                  <form onSubmit={handleSubmit}>
                    <div className="sign-item">
                      <label htmlFor="password">New Password</label>
                      <div className="input-group sign-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="Enter new Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="input-group-append sign-append">
                          <span className="input-group-text sign-text" onClick={togglePassword}>
                            <i className="bi bi-eye"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="sign-item">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <div className="input-group sign-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <div className="input-group-append sign-append">
                          <span className="input-group-text sign-text" onClick={togglePassword}>
                            <i className="bi bi-eye"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="signin-button">
                      <button type="submit">Continue</button>
                    </div>
                  </form>
                  <h6>
                    Remembered Password? <Link to="/signin" className="text-decoration-none"><span>Login Here</span></Link>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reset;
