import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from '../AuthContex';
// import AuthContex from '../AuthContex';
import iziToast from "izitoast";
// import { useNavigate} from 'react-router-dom';



const Navbar = () => {

  const location = useLocation();
  const navigate = useNavigate();
  // const { logout } = useContext(AuthContex);
    const {adminLogout,logout}=useAuth();

  const token = localStorage.getItem('token');
  // const admintoken=localStorage.getItem('admintoken');
  // const {logout}=useAuth();
  // console.log(token);
// 

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  const handleLogout = () => {
    // adminLogout();
    // localStorage.removeItem('token');
    logout();
    // iziToast.success({
    //   message: "Logout successful",
    //   position: "topCenter"
    // });
    navigate("/");
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-black sticky-top">
      <div className="container nav-fluid">
        <a className="navbar-brand" href="/">
          {/* <img src="assets/logo.png" alt="" /> */}
          <img src={`${process.env.PUBLIC_URL}/assets/logo.png`} alt="logo"/>

        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <Link className={`nav-link ${isActive("/")}`} to="/">
                Home
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className={`nav-link ${isActive("/terms")}`} to="/terms">
                Terms
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className={`nav-link ${isActive("/userevents")}`} to="/userevents">
                Events
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${isActive("/contact")}`}
                to="/contact"
              >
                Contact Us
              </Link>
            </li>
            
          </ul>
          <div className="d-flex nav-buttons">
            {token ? (
              <div className="nav-item">
              <Link className="nav-link" >
                <i className="bi bi-person-circle"></i> <i className="bi bi-chevron-down"></i>
              </Link>
              <div className="dropdown-menu">
                <ul>
                  <li>
                    <Link to={`/profile/${'userid'}`}>My Profile</Link>
                  </li>
                  <li>
                    <Link to={`/my-booking/${'userid'}`}>My Bookings</Link>
                  </li>
                  <li>
                    <Link to={`/transaction/${'userid'}`}>Transaction History</Link>
                  </li>
                  <li>
                    <Link to="/forgot">Change Password</Link>
                  </li>
                  <li>
                    <Link to="/" className="d-flex justify-content-between" onClick={handleLogout}>Logout <i className="bi bi-box-arrow-right" onClick={handleLogout}></i></Link>
                  </li>
                </ul>
              </div>
            </div>
            ) : (
              <Link to="/signin">
                <button className="first-btn me-3" type="submit">
                  Login/Register
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

Navbar.defaultProps = {
  login: true,
};

export default Navbar; 
