import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./Components/Landing";
import Privacy from "./Components/Privacy";
import Terms from "./Components/Terms";
import Contact from "./Components/Contact";
import Signin from "./Components/Signin";
import Forgot from "./Components/Forgot";
import Reset from "./Components/Reset";
import Signup from "./Components/Signup";
import Profile from "./Components/Profile";
import Booking from "./Components/Booking";
import Confirm from "./Components/Confirm";
import Details from "./Components/Details";
import Events from "./Components/Events";
import Dashboad from "./Components/Dashboad";
import UserManagement from "./Components/UserManagement";
import Refund from "./Components/Refund";
import MyProfile from "./Components/MyProfile";
import BookingManagement from "./Components/BookingMangement";
import MyBooking from "./Components/MyBooking";
import EventManagement from "./Components/EventManagement";
import { AuthProvider } from "./AuthContex";
import UserEvents from "./Components/UserEvents";
import AdminlistedEvents from "./Components/AdminlistedEvents";
import ContactUsManagement from "./Components/ContactUsManagement";
import AdminRoute from "./Components/AdminRoute"; // Import AdminRoute
import VerifyOTP from "./Components/Verifyotp";
import AdminForm from "./Components/AdminForm";
import TicketManagement from "./Components/TicketManagement";
import TicketDetails from "./Components/TicketDetails";
import PaymentForm from "./Components/PaymentForm";
import Transaction from "./Components/Transactions";
import Invoice from "./Components/Invoice";
import VerifyRegistraion from "./Components/VerifyRegistration";
import CookieConsent from "./CookieConsent"



function App() {
  return (
    <div className="App">
      <CookieConsent/>
      <AuthProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/refund" element={<Refund />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/signin" element={<Signin />} />
          <Route exact path="/forgot" element={<Forgot />} />
          <Route exact path='/verify-otp' element={<VerifyOTP />}/>
          <Route exact path="/reset" element={<Reset />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/edit-profile" element={<Profile />} />
          {/* <Route exact path="/my-booking" element={<MyBooking />} /> */}
          <Route exact path={`/my-booking/${'userid'}`} element={<MyBooking />} />

          <Route exact path="/invoice/:id" element={<Invoice />} />
          <Route exact path="/confirm" element={<Confirm />} />
          <Route exact path="/userevents" element={<UserEvents />} />
          <Route exact path={`/booking/:eventId`} element={<Booking />} />
          <Route exact path={`/payment/:eventId`} element={<PaymentForm />} />
          <Route exact path={`/transaction/${'userid'}`} element={<Transaction />} />
          <Route exact path={`/profile/${'userid'}`} element={<MyProfile />} />
          <Route exact path="/details/:eventId" element={<Details />} />
          <Route exact path="/verifyuser" element={<VerifyRegistraion />} />








          {/*Admin*/}
          <Route exact path='/dashboard' element={<Dashboad />} />
          {/* <Route path="/dashboard" element={<AdminRoute ><Dashboad /></AdminRoute>} /> */}
          <Route exact path="/user" element={<UserManagement />} />
          <Route exact path="/eventsdata" element={<EventManagement />} />
          <Route exact path="/listedeventsdata" element={<AdminlistedEvents />} />
          <Route exact path="/events" element={<Events />} />
          <Route exact path={`/admincontactus`} element={<ContactUsManagement />} />
          <Route exact path={`/adminform/:eventId`} element={<AdminForm />} />
          <Route exact path={`/ticketsall`} element={<TicketManagement />} />
          <Route exact path={`/ticketdetails/:eventId`} element={<TicketDetails />} />
          <Route exact path="/transactionall" element={<BookingManagement />} />


        </Routes>
      </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
