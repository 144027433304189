import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);
  const [totalBookings, setTotalBookings] = useState(0);
  const [totalContactus, setTotalContactus] = useState(0);




  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await axios.get("https://backend.eurofestia.com/api/v1/users/details");
        const eventResponse = await axios.get("https://backend.eurofestia.com/api/v1/events/details");
        const contactusResponse= await axios.get("https://backend.eurofestia.com/api/v1/contactus/getcontacts");
        const bookingResponse= await axios.get("https://backend.eurofestia.com/api/v1/payments/bookings");
        const ticketResponse= await axios.get("https://backend.eurofestia.com/api/v1/tickets/ticketsall");


        setTotalUsers(userResponse.data.userCount);
        setTotalEvents(eventResponse.data.eventCount);
        setTotalTickets(ticketResponse.data.ticketCount);
        setTotalBookings(bookingResponse.data.bookingCount);
        setTotalContactus(contactusResponse.data.contactusCount)
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Sidebar />
      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Dashboard</h1>
          </div>

          <div className="row dash-row">
            <div className="col-lg-4">
              <div
                className="dash-card"
                onClick={() => navigate("/user")}
                style={{ cursor: "pointer" }}
              >
                <div className="dash-1 d-flex justify-content-between align-items-center">
                  <h3>Users</h3>
                  <span>
                    {/* <i className="bi bi-arrow-up"></i> 3% */}
                  </span>
                </div>
                <div className="dash-2 d-flex justify-content-between align-items-center">
                  <h2>{totalUsers}</h2>
                  {/* <h5>1,000 last year</h5> */}
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div
                className="dash-card"
                onClick={() => navigate("/eventsdata")}
                style={{ cursor: "pointer" }}
              >
                <div className="dash-1 d-flex justify-content-between align-items-center">
                  <h3>Events</h3>
                </div>
                <div className="dash-2 d-flex justify-content-between align-items-center">
                  <h2>{totalEvents}</h2>
                  {/* <h5>500 last year</h5> */}
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div
                className="dash-card"
                onClick={() => navigate("/ticketsall")}
                style={{ cursor: "pointer" }}
              >
                <div className="dash-1 d-flex justify-content-between align-items-center">
                  <h3>Tickets</h3>
                </div>
                <div className="dash-2 d-flex justify-content-between align-items-center">
                  <h2>{totalTickets}</h2>
                  {/* <h5>500 last year</h5> */}
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div
                className="dash-card"
                onClick={() => navigate("/transactionall")}
                style={{ cursor: "pointer" }}
              >
                <div className="dash-1 d-flex justify-content-between align-items-center">
                  <h3>Bookings</h3>
                </div>
                <div className="dash-2 d-flex justify-content-between align-items-center">
                  <h2>{totalBookings}</h2>
                  {/* <h5>500 last year</h5> */}
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div
                className="dash-card"
                onClick={() => navigate("/admincontactus")}
                style={{ cursor: "pointer" }}
              >
                <div className="dash-1 d-flex justify-content-between align-items-center">
                  <h3>Contact Messages</h3>
                </div>
                <div className="dash-2 d-flex justify-content-between align-items-center">
                  <h2>{totalContactus}</h2>
                  {/* <h5>500 last year</h5> */}
                </div>
              </div>
            </div>


            
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
