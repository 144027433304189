import React, { useRef, useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
// import { fetchUpcomingEvents } from '../ticketService';

const Landing = () => {
  const sliderRef = useRef();
  const sliderRef1 = useRef();
  const [events, setEvents] = useState([]);
  const [show, setShow] = useState(false);
  const [country, setCountry] = useState("");

  const fetchEventsByCountry = async (country) => {
    try {
      const response = await fetch(`https://backend.eurofestia.com/api/v1/events/details?country=${country}`);
      const data = await response.json();
      return data.events || [];
    } catch (error) {
      console.error("Error fetching events:", error);
      return [];
    }
  };

  useEffect(() => {
    const getEvents = async () => {
      const eventsData = await fetchEventsByCountry("Poland"); // Replace with your default country or logic
      setEvents(eventsData);
    };
    getEvents();
    // setTimeout(() => {
    //   setShow(true);
    // }, 8000);
  }, []);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleFindEvents = async () => {
    const eventsData = await fetchEventsByCountry(country);
    setEvents(eventsData);
    handleClose();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    margin: 10,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1525,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slideRight = () => {
    sliderRef.current.slickNext();
  };

  const slideLeft = () => {
    sliderRef.current.slickPrev();
  };

  const slideRight1 = () => {
    sliderRef1.current.slickNext();
  };

  const slideLeft1 = () => {
    sliderRef1.current.slickPrev();
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <Navbar />

      {/* MAIN BANNER */}
      <div className="container-fluid main">
        <div className="row main-row">
          <div className="col-lg-6 main-content">
            <h2>Welcome to EuroFestia</h2>
            <h1>Let's Play the Beat and Dance</h1>
            <div className="main-button">
              <Link to="/userevents">
                <button className="me-3">Check Events</button>
              </Link>
              <button onClick={handleShow}>Location</button>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="main-img">
              <img src="assets/singer.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Your Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content1">
            <p>To show all events near you we would like your current location</p>
            <div className="d-flex justify-content-between">
              <input
                type="text"
                placeholder="Enter Your location"
                value={country}
                onChange={handleCountryChange}
              />
              {/* <button><i class="bi bi-crosshair"></i> <u>Use Current Location</u></button> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="find-event" onClick={handleFindEvents}>
            Find Events
          </button>
        </Modal.Footer>
      </Modal>

      {/* CARDS */}
      <div className="container-fluid abs-cards">
        <div className="container">
          <div className="row abs-row">
            <div className="col-lg-4 abs-card">
              <img src="assets/mdi_events-check.png" alt="" />
              <h5> Musical  Events </h5>
            </div>
            <div className="col-lg-4 abs-card">
              <img src="assets/subway_world.png" alt="" />
              <h5> 25 Countries</h5>
            </div>
            <div className="col-lg-4 abs-card abs-card1">
              <img src="assets/streamline_tickets.png" alt="" />
              <h5> Events Tickets</h5>
            </div>
          </div>
        </div>
      </div>

      {/* CONCERTS AND EVENTS */}
      <div className="container-fluid concert">
        <div className="concert-heading">
          <h1>Concerts and Events</h1>
        </div>
        <div className="concert-cards">
          <div className="arrow-right" onClick={slideRight1}>
            <span>
              <i className="bi bi-arrow-right"></i>
            </span>
          </div>
          <div className="arrow-left" onClick={slideLeft1}>
            <span>
              <i className="bi bi-arrow-left"></i>
            </span>
          </div>
          <Slider ref={sliderRef1} {...settings}>
            {events.map((event) => (
              <div className="concert-card" key={event.id}>
                <div className="concert-card1">
                  <h4>{event.classification}</h4>
                  <h1>{event.name}</h1>
                  {/* <p>{event.info || 'No additional information available.'}</p> */}
                  <h6>
                    Venue : <span>{event.venue}</span>
                  </h6>
                  <h6>
                    City : <span>{event.city}</span>
                  </h6>
                  <h6>
                    Date : <span>{formatDate(event.date)}</span>
                  </h6>
                  <h6>
                    Time : <span>{event.time}</span>
                  </h6>
                  <div className="concert-button">
                    <Link to={`/details/${event.eventId}`}>
                      <button>Buy Tickets</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* HIGHLIGHTS */}
      <div className="container-fluid highlight">
        <div className="container">
          <h1>Highlights</h1>
          <div className="row high-row">
            <div className="col-lg-6 high-col">
              <h2>
                Music <br /> Extravaganza
              </h2>
            </div>
            <div className="col-lg-6 high-col">
              <p>
                Where delectable cuisines harmonize with mesmerizing melodies,
                all set amidst a vibrant and eco-conscious atmosphere. Indulge
                your senses with a symphony of flavors and sounds at this
                ultimate music festival experience
              </p>
            </div>
            <div className="high-cards row mt-3">
              <div className="col-lg-4">
                <div className="high-card">
                  <img src="assets/f7_guitars.png" alt="" />
                  <p>
                    Innovative artists push boundaries, captivating audiences
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="high-card">
                  <img src="assets/f7_guitars.png" alt="" />
                  <p>
                    Visionaries who defy convention, creating art that resonates with the soul.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="high-card">
                  <img src="assets/f7_guitars.png" alt="" />
                  <p>
                    Masters of the art of surprise, consistently delighting and engaging their audience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* UPCOMING EVENTS */}
      <div className="container-fluid live">
        <div className="container">
          <div className="news-header">
            <h3>Live shows and</h3>
            <h1>Upcoming Events</h1>
          </div>
          <div className="row live-row">
            {events.map((event) => (
              <div className="col-lg-10 mx-auto" key={event.id}>
                <div className="row live-c">
                  <h1 className="col-lg-4">{event.name}</h1>
                  <h2 className="col-lg-2">
                    <i className="bi bi-calendar"></i> {formatDate(event.date)}
                  </h2>
                  <h2 className="col-lg-3">
                    <i className="bi bi-geo-alt-fill"></i> {event.city}, {event.country}
                  </h2>
                  <div className="col-lg-3">
                    <Link to={`/details/${event.eventId}`}>
                      <button>Buy Tickets</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>

      {/* ABOUT US */}

      <div className="container-fluid about">
        <div className="container">
          <h1>About Us</h1>
          <div className="row about-row">
            <div className="col-lg-6">
              <div className="about-left">
                <h2>
                  Elevating European Soundscapes: Where Music Meets Adventure
                </h2>
                <p>
                  We are a premier company dedicated to curating and booking
                  unforgettable music festivals across Europe, bringing together
                  diverse genres and top-notch talent to create unparalleled
                  experiences for music enthusiasts worldwide. With our
                  commitment to excellence and dedication to fostering
                  unforgettable experiences, we invite you to join us on this
                  extraordinary musical adventure across Europe
                </p>
                <div className="about-list">
                  <li>
                    <i className="bi bi-check-all"></i> Curating diverse
                    European lineups
                  </li>
                  <li>
                    <i className="bi bi-check-all"></i> Unforgettable music
                    festival experiences
                  </li>
                  <li>
                    <i className="bi bi-check-all"></i> Immersive cultural
                    exploration
                  </li>
                </div>
                <div className="about-button">
                  <Link to="/contact">
                    <button>Learn More</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-img">
                <img src="assets/Group.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* NEWS */}

      {/* <div className="container-fluid news">
        <div className="container">
          <div className="news-header">
            <h3>Latest</h3>
            <h1>News and Updates</h1>
          </div>
          <div className="row news-row">
            <div className="col-lg-4">
              <div className="news-card">
                <div className="news-img">
                  <img src="assets/concert.jpg" alt="" />
                </div>
                <h2>Europe's Newest Music Extravaganza Takes Center Stage</h2>
                <p>
                  Harmonic Haven, the latest addition to Europe's festival
                  scene, promises a captivating blend of top-tier artists,
                  immersive experiences, and scenic venues.
                </p>
                <h5>27 April 2024</h5>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="news-card">
                <div className="news-img">
                  <img src="assets/concert.jpg" alt="" />
                </div>
                <h2>Europe's Newest Music Extravaganza Takes Center Stage</h2>
                <p>
                  Harmonic Haven, the latest addition to Europe's festival
                  scene, promises a captivating blend of top-tier artists,
                  immersive experiences, and scenic venues.
                </p>
                <h5>27 April 2024</h5>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="news-card">
                <div className="news-img">
                  <img src="assets/concert.jpg" alt="" />
                </div>
                <h2>Europe's Newest Music Extravaganza Takes Center Stage</h2>
                <p>
                  Harmonic Haven, the latest addition to Europe's festival
                  scene, promises a captivating blend of top-tier artists,
                  immersive experiences, and scenic venues.
                </p>
                <h5>27 April 2024</h5>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      {/* USER STORIES */}

      <div className="container-fluid user">
        <div className="container">
          <div className="user-heading col-lg-7 mx-auto">
            <h1>User Stories</h1>
            <p>
              Discover the unfiltered perspectives of festival-goers as they
              share their candid reviews
            </p>
          </div>
          <div className="user-cards">
            <div className="arrow-right1" onClick={slideRight}>
              <span>
                <i className="bi bi-arrow-right"></i>
              </span>
            </div>
            <div className="arrow-left1" onClick={slideLeft}>
              <span>
                <i className="bi bi-arrow-left"></i>
              </span>
            </div>
            <Slider ref={sliderRef} {...settings}>
              <div className="user-card ">
                <div className="user-card1">
                  <div class="user-name d-flex">
                    <div class="user-img">
                      <img src="assets/user.png" alt="" />
                    </div>
                    <div class="mx-3">
                      <h3>Max Sainz</h3>
                      <p>
                        <i class="bi bi-star-fill px-1"></i>
                        <i class="bi bi-star-fill px-1"></i>
                        <i class="bi bi-star-fill px-1"></i>
                        <i class="bi bi-star-fill px-1"></i>
                        <i class="bi bi-star-fill px-1"></i>
                      </p>
                    </div>
                  </div>
                  <p class="py-1">
                    The lineup was diverse and impressive, the venue
                    breathtaking, and the atmosphere electric. 
                    The event company ensured seamless organization and
                    left me eagerly anticipating next year's edition.
                  </p>
                </div>
              </div>
              <div className="user-card ">
                <div className="user-card1">
                  <div class="user-name d-flex">
                    <div class="user-img">
                      <img src="assets/user.png" alt="" />
                    </div>
                    <div class="mx-3">
                      <h3>Thomas</h3>
                      <p>
                        <i class="bi bi-star-fill px-1"></i>
                        <i class="bi bi-star-fill px-1"></i>
                        <i class="bi bi-star-fill px-1"></i>
                        <i class="bi bi-star-fill px-1"></i>
                        <i class="bi bi-star-fill px-1"></i>
                      </p>
                    </div>
                  </div>
                  <p class="py-1">
                  I have been using this site for well over a long time now, 
                  and I am always impressed! Thank you for such wonderful 
                  service and ease of use!
                  </p>
                </div>
              </div>
              <div className="user-card ">
                <div className="user-card1">
                  <div class="user-name d-flex">
                    <div class="user-img">
                      <img src="assets/user.png" alt="" />
                    </div>
                    <div class="mx-3">
                      <h3>Kevin</h3>
                      <p>
                        <i class="bi bi-star-fill px-1"></i>
                        <i class="bi bi-star-fill px-1"></i>
                        <i class="bi bi-star-fill px-1"></i>
                        <i class="bi bi-star-fill px-1"></i>
                        <i class="bi bi-star-fill px-1"></i>
                      </p>
                    </div>
                  </div>
                  <p class="py-1">
                    I've used this sit a few times now and it is
                    easy to navigate and I have confidence in my order being correct
                    when I arrive at the event.
                    Always a smooth entrance which is key to start the fun off right.
                  </p>
                </div>
              </div>
              <div className="user-card ">
                <div className="user-card1">
                  <div class="user-name d-flex">
                    <div class="user-img">
                      <img src="assets/user.png" alt="" />
                    </div>
                    <div class="mx-3">
                      <h3>Amanda</h3>
                      <p>
                        <i class="bi bi-star-fill px-1"></i>
                        <i class="bi bi-star-fill px-1"></i>
                        <i class="bi bi-star-fill px-1"></i>
                        <i class="bi bi-star-fill px-1"></i>
                        <i class="bi bi-star-fill px-1"></i>
                      </p>
                    </div>
                  </div>
                  <p class="py-1">
                  All the musicians were great. 
                  The sound quality even in the back sections was good, 
                  parking was easy and affordable, tickets 
                  were reasonably priced and the crowd was interactive. 
                  The sets were cool, costumes on point and the fireworks 
                  were a nice touch. Great show.
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Landing;


// import React, { useRef, useState, useEffect } from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import Slider from "react-slick";
// import { Link } from "react-router-dom";
// import { Modal } from "react-bootstrap"; // Import Modal from react-bootstrap
// import { fetchUpcomingEvents } from '../ticketService';

// const Landing = () => {
//   const sliderRef = useRef();
//   const sliderRef1 = useRef();
//   const [events, setEvents] = useState([]);
//   const [show, setShow] = useState(false); // Define show state

//   useEffect(() => {
//     const getEvents = async () => {
//       const eventsData = await fetchUpcomingEvents();
//       setEvents(eventsData);
//     };
//     getEvents();
//   }, []);

//   const handleShow = () => setShow(true); // Define handleShow function
//   const handleClose = () => setShow(false); // Define handleClose function

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     margin: 10,
//     slidesToShow: 2,
//     slidesToScroll: 1,
//     arrows: false,
//     responsive: [
//       {
//         breakpoint: 1525,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 769,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 500,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   const slideRight = () => {
//     sliderRef.current.slickNext();
//   };

//   const slideLeft = () => {
//     sliderRef.current.slickPrev();
//   };

//   const slideRight1 = () => {
//     sliderRef1.current.slickNext();
//   };

//   const slideLeft1 = () => {
//     sliderRef1.current.slickPrev();
//   };

//   return (
//     <>
//       <Navbar />

//       {/* MAIN BANNER */}
//       <div className="container-fluid main">
//         <div className="row main-row">
//           <div className="col-lg-6 main-content">
//             <h2>Welcome to EuroFest</h2>
//             <h1>Let's Play the Beat and Dance</h1>
//             <div className="main-button">
//               <Link to="/events">
//                 <button className="me-3">Check Events</button>
//               </Link>
//               <button onClick={handleShow}>Location</button>
//             </div>
//           </div>
//           <div className="col-lg-6">
//             <div className="main-img">
//               <img src="assets/banner-img.png" alt="Banner" />
//             </div>
//           </div>
//         </div>
//       </div>
//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Your Location</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="modal-content1">
//             <p>To show all events near you we would like your current location</p>
//             <div className="d-flex justify-content-between">
//               <input type="text" placeholder="Enter Your location" />
//               <button>
//                 <i className="bi bi-crosshair"></i> <u>Use Current Location</u>
//               </button>
//             </div>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <button className="find-event" onClick={handleClose}>
//             Find Events
//           </button>
//         </Modal.Footer>
//       </Modal>

//       {/* CARDS */}
//       <div className="container-fluid abs-cards">
//         <div className="container">
//           <div className="row abs-row">
//             <div className="col-lg-4 abs-card">
//               <img src="assets/mdi_events-check.png" alt="Events" />
//               <h5>Events</h5>
//             </div>
//             <div className="col-lg-4 abs-card">
//               <img src="assets/subway_world.png" alt="Countries" />
//               <h5>Countries</h5>
//             </div>
//             <div className="col-lg-4 abs-card abs-card1">
//               <img src="assets/streamline_tickets.png" alt="Tickets" />
//               <h5>Tickets</h5>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* CONCERTS AND EVENTS */}
//       <div className="container-fluid concert">
//         <div className="concert-heading">
//           <h1>Concerts and Events</h1>
//         </div>
//         <div className="concert-cards">
//           <div className="arrow-right" onClick={slideRight1}>
//             <span>
//               <i className="bi bi-arrow-right"></i>
//             </span>
//           </div>
//           <div className="arrow-left" onClick={slideLeft1}>
//             <span>
//               <i className="bi bi-arrow-left"></i>
//             </span>
//           </div>
//           <Slider ref={sliderRef1} {...settings}>
//             {events.map((event) => (
//               <div className="concert-card" key={event.id}>
//                 <div className="concert-card1">
//                   <h4>{event.classifications[0].segment.name}</h4>
//                   <h1>{event.name}</h1>
//                   <h6>
//                     Venue : <span>{event._embedded.venues[0].name}</span>
//                   </h6>
//                   <h6>
//                     Date : <span>{new Date(event.dates.start.dateTime).toLocaleDateString()}</span>
//                   </h6>
//                   <h6>
//                     Time : <span>{new Date(event.dates.start.dateTime).toLocaleTimeString()}</span>
//                   </h6>
//                   <div className="concert-button">
//                     <Link to={`/details/${event.id}`}>
//                       <button>Buy Tickets</button>
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </Slider>
//         </div>
//       </div>

//       {/* HIGHLIGHTS */}
//       <div className="container-fluid highlight">
//         <div className="container">
//           <h1>Highlights</h1>
//           <div className="row high-row">
//             <div className="col-lg-6 high-col">
//               <h2>
//                 Music <br /> Extravaganza
//               </h2>
//             </div>
//             <div className="col-lg-6 high-col">
//               <p>
//                 Where delectable cuisines harmonize with mesmerizing melodies,
//                 all set amidst a vibrant and eco-conscious atmosphere. Indulge
//                 your senses with a symphony of flavors and sounds at this
//                 ultimate music festival experience.
//               </p>
//             </div>
//             <div className="high-cards row mt-3">
//               <div className="col-lg-4">
//                 <div className="high-card">
//                   <img src="assets/f7_guitars.png" alt="Guitars" />
//                   <p>
//                     Innovative artists push boundaries, captivating audiences.
//                   </p>
//                 </div>
//               </div>
//               <div className="col-lg-4">
//                 <div className="high-card">
//                   <img src="assets/f7_guitars.png" alt="Guitars" />
//                   <p>
//                     Innovative artists push boundaries, captivating audiences.
//                   </p>
//                 </div>
//               </div>
//               <div className="col-lg-4">
//                 <div className="high-card">
//                   <img src="assets/f7_guitars.png" alt="Guitars" />
//                   <p>
//                     Innovative artists push boundaries, captivating audiences.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* UPCOMING EVENTS */}
//       <div className="container-fluid live">
//         <div className="container">
//           <div className="news-header">
//             <h3>Live shows and</h3>
//             <h1>Upcoming Events</h1>
//           </div>
//           <div className="row live-row">
//             <div className="col-lg-10 mx-auto">
//               <div className="live-c">
//                 <h1>Primavera Sound</h1>
//                 <h2>
//                   <i className="bi bi-calendar"></i> May 26-30, 2024
//                 </h2>
//                 <h2>
//                   <i className="bi bi-geo-alt-fill"></i> Barcelona, Spain
//                 </h2>
//                 <div>
//                   <Link to="/details">
//                     <button>Buy Tickets</button>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-10 mx-auto">
//               <div className="live-c">
//                 <h1>Primavera Sound</h1>
//                 <h2>
//                   <i className="bi bi-calendar"></i> May 26-30, 2024
//                 </h2>
//                 <h2>
//                   <i className="bi bi-geo-alt-fill"></i> Barcelona, Spain
//                 </h2>
//                 <div>
//                   <Link to="/details">
//                     <button>Buy Tickets</button>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="arrow-right" onClick={slideRight}>
//             <span>
//               <i className="bi bi-arrow-right"></i>
//             </span>
//           </div>
//           <div className="arrow-left" onClick={slideLeft}>
//             <span>
//               <i className="bi bi-arrow-left"></i>
//             </span>
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Landing;
