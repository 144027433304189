import React,{useEffect,useState} from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import axios  from "axios";

const MyProfile = () => {
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    country: "",
    postalcode: "",
  });
  const userId = localStorage.getItem('userid'); // Replace with the actual user ID or fetch it from the context/state
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://backend.eurofestia.com/api/v1/users/user/${userId}`);
        setUserData(response.data.user);
      } catch (error) {
        console.error("Error fetching user details", error);
      }
    };

    fetchUserData();
  }, [userId]);

  return (
    <>
      <Navbar login={true} />

      <div className="container-fluid signin">
        <div className="container">
          <div className="signin-form">
            <div className="sign-heading px-3">
              <h1>My Profile</h1>
            </div>
            <form action="/">
              <div className="row">
                <div className="col-lg-6">
                  <div className="sign-item">
                    <label htmlFor="name">First Name</label>
                    <input type="text" placeholder="First Name" value={userData.firstname} disabled />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sign-item">
                    <label htmlFor="name">Last Name</label>
                    <input type="text" placeholder="Last Name"
                      value={userData.lastname} disabled />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sign-item">
                    <label htmlFor="name">Phone no.</label>
                    <input type="text" placeholder="Phone number"
                      value={userData.phone} disabled />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sign-item">
                    <label htmlFor="name">Email</label>
                    <input
                      type="text"
                      placeholder="Email"
                      value={userData.email}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sign-item">
                    <label htmlFor="name">Address</label>
                    <input
                      type="text"
                      placeholder="Address"
                      value={userData.address}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sign-item">
                    <label htmlFor="name">City</label>
                    <input type="text" placeholder="City"
                      value={userData.city} disabled />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sign-item">
                    <label htmlFor="name">Country</label>
                    <input type="text" placeholder="Country"
                      value={userData.country} disabled />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sign-item">
                    <label htmlFor="name">Postal Code</label>
                    <input type="text" placeholder="Postal Code"
                      value={userData.postalcode} disabled />
                  </div>
                </div>

                <div className="signup-button pt-5 px-4">
                  <Link to="/edit-profile">
                    <button>Edit Details</button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;