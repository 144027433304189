import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import { useAuth } from '../AuthContex';
import iziToast from "izitoast";


const Sidebar = () => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  const { adminLogout,logout } = useAuth();
  const admintoken = localStorage.getItem('admintoken');
  if(admintoken === null || admintoken === ""){
    navigate("/");
  }


  const toggleSidebar = () => {
    setIsActive(!isActive);
  };
  const handleLogout = () => {
    localStorage.removeItem('userid');
    localStorage.removeItem('adminid')
    localStorage.removeItem('admintoken');
    localStorage.removeItem('token')
    // adminLogout();
    // logout();
    
    iziToast.success({
      message: "Logout successful",
      position: "topCenter",
    });
    // navigate("/");
    window.location.href = "/signin";
  };


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className={`sidebar ${isActive ? "close" : ""}`}>
        <Link className="logo-box text-decoration-none" onClick={toggleSidebar}>
          {/* <img src="assets/logo.png" alt="" /> */}
          <img src={`${process.env.PUBLIC_URL}/assets/logo.png`} alt="logo"/>
        </Link>

        <ul className="sidebar-list">
          <li>
            <div className="title">
              <Link to="/dashboard" className="link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M8.557 2.75H4.682A1.932 1.932 0 0 0 2.75 4.682v3.875a1.942 1.942 0 0 0 1.932 1.942h3.875a1.942 1.942 0 0 0 1.942-1.942V4.682A1.942 1.942 0 0 0 8.557 2.75m10.761 0h-3.875a1.942 1.942 0 0 0-1.942 1.932v3.875a1.943 1.943 0 0 0 1.942 1.942h3.875a1.942 1.942 0 0 0 1.932-1.942V4.682a1.932 1.932 0 0 0-1.932-1.932M8.557 13.5H4.682a1.943 1.943 0 0 0-1.932 1.943v3.875a1.932 1.932 0 0 0 1.932 1.932h3.875a1.942 1.942 0 0 0 1.942-1.932v-3.875a1.942 1.942 0 0 0-1.942-1.942m8.818-.001a3.875 3.875 0 1 0 0 7.75a3.875 3.875 0 0 0 0-7.75"
                  />
                </svg>
                <span className="name">Dashboard</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/dashboard" className="link submenu-title">
                Dashboard
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/user" className="link">
                <svg
                  class="icon-sidebar"
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 2048 2048"
                >
                  <path
                    fill="currentColor"
                    d="m1344 2l704 352v785l-128-64V497l-512 256v258l-128 64V753L768 497v227l-128-64V354zm0 640l177-89l-463-265l-211 106zm315-157l182-91l-497-249l-149 75zm-507 654l-128 64v-1l-384 192v455l384-193v144l-448 224L0 1735v-676l576-288l576 288zm-640 710v-455l-384-192v454zm64-566l369-184l-369-185l-369 185zm576-1l448-224l448 224v527l-448 224l-448-224zm384 576v-305l-256-128v305zm384-128v-305l-256 128v305zm-320-288l241-121l-241-120l-241 120z"
                  />
                </svg>

                <span className="name">User Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">User Management</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/transactionall" className="link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="currentColor"
                    d="M232 198.65V240a8 8 0 0 1-16 0v-41.35A74.84 74.84 0 0 0 192 144v58.35a8 8 0 0 1-14.69 4.38l-10.68-16.31c-.08-.12-.16-.25-.23-.38a12 12 0 0 0-20.89 11.83l22.13 33.79a8 8 0 0 1-13.39 8.76l-22.26-34l-.24-.38A28 28 0 0 1 176 176.4V64h-16a8 8 0 0 1 0-16h16a16 16 0 0 1 16 16v59.62a90.89 90.89 0 0 1 40 75.03M88 56a8 8 0 0 0-8-8H64a16 16 0 0 0-16 16v136a8 8 0 0 0 16 0V64h16a8 8 0 0 0 8-8m69.66 42.34a8 8 0 0 0-11.32 0L128 116.69V16a8 8 0 0 0-16 0v100.69L93.66 98.34a8 8 0 0 0-11.32 11.32l32 32a8 8 0 0 0 11.32 0l32-32a8 8 0 0 0 0-11.32"
                  />
                </svg>
                <span className="name">Booking Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Booking Management</Link>
            </div>
          </li>
          {/* <li>
            <div className="title">
              <Link to="/events" className="link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M4 12.577v1.808q0 .23.192.423t.423.192h14.77q.23 0 .423-.192t.192-.424v-1.807zM4.615 3h14.77q.67 0 1.143.472q.472.472.472 1.144v9.769q0 .67-.472 1.143q-.472.472-1.143.472h-4.462v4.27L12 18.807l-2.923 1.461V16H4.616q-.672 0-1.144-.472T3 14.385v-9.77q0-.67.472-1.143Q3.944 3 4.616 3M4 10.423h16V4.616q0-.231-.192-.424T19.385 4H4.615q-.23 0-.423.192T4 4.615zm0 3.962V4h.616q-.231 0-.424.192T4 4.615zq0 .23.192.423t.423.192H4z"
                  />
                </svg>
                <span className="name"> Store Event </span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title"> Store Event </Link>
            </div>
          </li> */}
          <li>
            <div className="title">
              <Link to="/eventsdata" className="link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M4 12.577v1.808q0 .23.192.423t.423.192h14.77q.23 0 .423-.192t.192-.424v-1.807zM4.615 3h14.77q.67 0 1.143.472q.472.472.472 1.144v9.769q0 .67-.472 1.143q-.472.472-1.143.472h-4.462v4.27L12 18.807l-2.923 1.461V16H4.616q-.672 0-1.144-.472T3 14.385v-9.77q0-.67.472-1.143Q3.944 3 4.616 3M4 10.423h16V4.616q0-.231-.192-.424T19.385 4H4.615q-.23 0-.423.192T4 4.615zm0 3.962V4h.616q-.231 0-.424.192T4 4.615zq0 .23.192.423t.423.192H4z"
                  />
                </svg>
                <span className="name"> Event Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title"> Event Management</Link>
            </div>
          </li>
          
          <li>
            <div className="title">
              <Link to="/ticketsall" className="link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="currentColor"
                    d="M232 198.65V240a8 8 0 0 1-16 0v-41.35A74.84 74.84 0 0 0 192 144v58.35a8 8 0 0 1-14.69 4.38l-10.68-16.31c-.08-.12-.16-.25-.23-.38a12 12 0 0 0-20.89 11.83l22.13 33.79a8 8 0 0 1-13.39 8.76l-22.26-34l-.24-.38A28 28 0 0 1 176 176.4V64h-16a8 8 0 0 1 0-16h16a16 16 0 0 1 16 16v59.62a90.89 90.89 0 0 1 40 75.03M88 56a8 8 0 0 0-8-8H64a16 16 0 0 0-16 16v136a8 8 0 0 0 16 0V64h16a8 8 0 0 0 8-8m69.66 42.34a8 8 0 0 0-11.32 0L128 116.69V16a8 8 0 0 0-16 0v100.69L93.66 98.34a8 8 0 0 0-11.32 11.32l32 32a8 8 0 0 0 11.32 0l32-32a8 8 0 0 0 0-11.32"
                  />
                </svg>
                <span className="name">Ticket Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Ticket Management</Link>
            </div>
          </li>
          {/*
          <li>
           <div className="title">
              <Link to="/listedeventsdata" className="link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M4 12.577v1.808q0 .23.192.423t.423.192h14.77q.23 0 .423-.192t.192-.424v-1.807zM4.615 3h14.77q.67 0 1.143.472q.472.472.472 1.144v9.769q0 .67-.472 1.143q-.472.472-1.143.472h-4.462v4.27L12 18.807l-2.923 1.461V16H4.616q-.672 0-1.144-.472T3 14.385v-9.77q0-.67.472-1.143Q3.944 3 4.616 3M4 10.423h16V4.616q0-.231-.192-.424T19.385 4H4.615q-.23 0-.423.192T4 4.615zm0 3.962V4h.616q-.231 0-.424.192T4 4.615zq0 .23.192.423t.423.192H4z"
                  />
                </svg>
                <span className="name"> Listed Events Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title"> Listed Events Management</Link>
            </div>
          </li>
          <li>
            <div className="title">
              <Link to="/" className="link">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M4 12.577v1.808q0 .23.192.423t.423.192h14.77q.23 0 .423-.192t.192-.424v-1.807zM4.615 3h14.77q.67 0 1.143.472q.472.472.472 1.144v9.769q0 .67-.472 1.143q-.472.472-1.143.472h-4.462v4.27L12 18.807l-2.923 1.461V16H4.616q-.672 0-1.144-.472T3 14.385v-9.77q0-.67.472-1.143Q3.944 3 4.616 3M4 10.423h16V4.616q0-.231-.192-.424T19.385 4H4.615q-.23 0-.423.192T4 4.615zm0 3.962V4h.616q-.231 0-.424.192T4 4.615zq0 .23.192.423t.423.192H4z"
                  />
                </svg>
                <span className="name">Live Event Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Live Event Management</Link>
            </div>
          </li>*/}
          <li>
            <div className="title">
              <Link to="/admincontactus" className="link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M4 12.577v1.808q0 .23.192.423t.423.192h14.77q.23 0 .423-.192t.192-.424v-1.807zM4.615 3h14.77q.67 0 1.143.472q.472.472.472 1.144v9.769q0 .67-.472 1.143q-.472.472-1.143.472h-4.462v4.27L12 18.807l-2.923 1.461V16H4.616q-.672 0-1.144-.472T3 14.385v-9.77q0-.67.472-1.143Q3.944 3 4.616 3M4 10.423h16V4.616q0-.231-.192-.424T19.385 4H4.615q-.23 0-.423.192T4 4.615zm0 3.962V4h.616q-.231 0-.424.192T4 4.615zq0 .23.192.423t.423.192H4z"
                  />
                </svg>
                <span className="name"> ContactUs Management  </span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title"> ContactUs Management </Link>
            </div>
          </li>
          <li>
            <div className="title">
              {/* <Link onClick={handleLogout} className="link"> */}
              {/* <div className="link"> */}
              <Link  className="link">

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 12l-4-4m4 4l-4 4m4-4H9m5 9a9 9 0 1 1 0-18"
                  />
                </svg>
                <span className="name" onClick={handleLogout} >Logout</span>
              </Link>
              {/* </div> */}
            </div>
            <div className="submenu"> 
            
              <Link className="link submenu-title">Logout</Link>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
