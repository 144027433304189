import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Privacy = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <>
      <Navbar />

      <div className="container-fluid privacy">
        <h1>Privacy Policy</h1>
      </div>
      <div className="container-fluid privacy-content">
        {/* <div className="container pt-5">
          <h3>Privacy Policy</h3>
          <p>
            We respect your privacy. When you give us your personal information,
            we use it only to fulfill the transaction or service you have
            requested.
          </p>
          <p>
            We do not subscribe you to marketing emails without your consent.
          </p>
          <p>
            We do not sell or give away your contact information to any other
            entities.
          </p>
          <p>
            We do not allow the vendors who help us process transactions to sell
            or give away your information either.
          </p>
          <p>
            If you have questions about how we use your information please
            contact privacy@nngroup.com.
          </p>
          <h3>Detailed Privacy Policy</h3>
          <ol>
            <li>
              <b>SCOPE</b>
              <p>
                This policy applies to personal information collected on
                websites owned or controlled by Nielsen Norman Group
                (collectively referred to in this policy as “we”, "us" or
                "our"). The aim of this policy is to tell you how we will use
                any personal information we collect or you provide through our
                websites. Please read it carefully before you proceed. The data
                controller in respect of this website is Nielsen Norman Group.
              </p>
            </li>
            <li>
              <b>WHAT PERSONAL INFORMATION DO WE COLLECT?</b>
              <p>
                You do not have to give us any personal information in order to
                use most of this website. However, if you wish to subscribe to
                our newsletter, attend the UX Conference, attend or purchase an
                Online Seminar, purchase a Research Report, or request further
                information, we may collect the following personal information
                from you: name, address, phone number, email address, employment
                details, and employer details. We will also keep a record of any
                financial transaction you make with us but we do not directly
                collect, process or store your debit or credit card information.
                Online payments made through this website are processed securely
                by third party payment providers.
              </p>
              <p>
                We use different processors for different types of transactions
                and to manage support for different services.For more
                information about how your data will be handled please refer to
                the respective service provider's privacy policy:
              </p>
              <p>
                Research Report Purchases: FastSpring
                https://fastspring.com/privacy/
              </p>
              <p>
                Online Seminars: Crowdcast,
                https://www.crowdcast.io/privacy-policy
              </p>
              <p>
                UX Conference Payments: Stripe, https://stripe.com/us/privacy
              </p>
              <p>
                In addition, we may automatically collect information about the
                website that you came from or are going to. We also collect
                information about the pages of this website which you visit, IP
                addresses, the type of browser you use and the times you access
                this website. However, this information is aggregated across all
                visitors and we do not use it to identify you.
              </p>
            </li>
            <li>
              <b>HOW WILL YOUR PERSONAL INFORMATION BE USED AND SHARED?</b>
              <p>
                The personal information we collect allows us to: <br />
                deliver the products and services you have ordered; <br />
                verify your identity and details of your payment method or
                credit card amount; <br />
                administer our website and provide customer services;
                <br />
                meet legal, regulatory and compliance requirements;
                <br />
                monitor and analyse the use of any account to prevent,
                investigate and/or report fraud, terrorism, misrepresentation,
                security incidents or crime;
              </p>
            </li>
          </ol>
        </div> */}
        <div className="container pt-5">
          <h2>Privacy Policy</h2>
          <h3>Effective Date: 11/1/2024</h3>
          <p>Eurofestia (the "Site") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website and purchase tickets. By using the Site, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
          <h3>1. General Information</h3>
          <ul>
            <li><strong>Website:</strong> Eurofestia</li>
            <li><strong>Owner:</strong> Noovative Group EOOD</li>
            <li><strong>Registered Address:</strong> Rayko Daskalov Str 68, Plovdiv, 4000, Bulgaria</li>
            <li><strong>Company Identification Number:</strong> 207847263</li>
          </ul>
          <h3>&bull; &nbsp;&nbsp;&nbsp; Contact Information: info@eurofestia.com 2. Information We Collect</h3>
          <h5>2.1 Personal Information</h5>
          <p>When you use our Site, we may collect personal information that you voluntarily provide to us. This includes but is not limited to:</p>
          <ul>
            <li><strong>Contact Information:</strong> such as your name, email address, phone number, and mailing address.</li>
            <li><strong>Payment Information:</strong> such as your credit card details, billing address, and other paymentrelated information.</li>
            <li><strong>Account Information:</strong> such as username, password, and preferences.</li>
          </ul>
          <h5>2.2 Non-Personal Information</h5>
          <p>We may also collect non-personal information that does not directly identify you. This includes:</p>
          <ul>
            <li><strong>Usage Data:</strong> Information about how you interact with the Site, including your IP address, browser type, pages visited, and the time and date of your visit.</li>
            <li><strong>Device Information:</strong> Information about the device you use to access the Site, including device type, operating system, and unique device identifiers.</li>
          </ul>
          <h5>2.3 Cookies and Tracking Technologies</h5>
          <p>We use cookies and similar tracking technologies to enhance your experience on the Site. Cookies are small data files stored on your device that help us understand how you interact with the Site. You can manage your cookie preferences through your browser settings.</p>
          <h3>3. How We Use Your Information</h3>
          <h5>3.1 To Provide and Improve Services</h5>
          <p>We use the information we collect to:</p>
          <ul>
            <li>Process your ticket purchases and deliver your tickets.</li>
            <li>Provide customer support and respond to your inquiries.</li>
            <li>Personalize your experience on the Site.</li>
            <li>Improve the functionality and performance of the Site.</li>
          </ul>
          <h5>3.2 Marketing and Communication</h5>
          <p>With your consent, we may use your personal information to:</p>
          <ul>
            <li>Send you promotional materials and updates about events.</li>
            <li>Inform you of special offers, discounts, and news related to Eurofestia.</li>
          </ul>
          <p>You may opt out of marketing communications at any time by following the unsubscribe instructions provided in the emails.</p>
          <h5>3.3 Legal Obligations</h5>
          <p>We may use your information to comply with applicable legal obligations, enforce our Terms of Service, or protect the rights, property, or safety of Noovative Group EOOD, our users, or others.</p>
          <h3>4. How We Share Your Information</h3>
          <h5>4.1 Third-Party Service Providers</h5>
          <p>We may share your personal information with third-party service providers that assist us in operating the Site, processing payments, and delivering tickets. These providers are obligated to protect your information and use it only for the services they provide to us.</p>
          <h5>4.2 Business Transfers</h5>
          <p>In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of the transaction. We will notify you via email and/or a prominent notice on our Site of any change in ownership or use of your personal information.</p>
          <h5>4.3 Legal Requirements</h5>
          <p>We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</p>
          <h3>5. Security of Your Information</h3>
          <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to protect your information, we cannot guarantee its absolute security.</p>
          <h3>6. Your Rights and Choices</h3>
          <h5>6.1 Access and Update Your Information</h5>
          <p>You have the right to access and update your personal information at any time. You can do this by logging into your account or contacting us directly.</p>
          <h5>6.2 Data Deletion</h5>
          <p>You have the right to request the deletion of your personal information. We will comply with your request unless we are required to retain certain information for legal or operational purposes.</p>
          <h5>6.3 Opt-Out of Marketing Communications</h5>
          <p>You can opt out of receiving marketing communications from us by following the unsubscribe instructions provided in our emails or by contacting us directly.</p>
          <h5>6.4 Cookies and Tracking</h5>
          <p>You can manage your cookie preferences through your browser settings. Please note that disabling cookies may affect your ability to use certain features of the Site.</p>
          <h3>7. International Data Transfers</h3>
          <p>Your information may be transferred to, and processed in, countries other than the country in which you are resident. These countries may have data protection laws that are different from those in your country. We ensure that any transfer of your personal information complies with applicable data protection laws.</p>
          <h3>8. Children's Privacy</h3>
          <p>Our Site is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have inadvertently collected such information, we will take steps to delete it as soon as possible.</p>
          <h3>9. Changes to This Privacy Policy</h3>
          <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top. We encourage you to review this Privacy Policy periodically for any updates.</p>
          <h3>10. Contact Us</h3>
          <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
          <ul>
            <li><strong>Email:</strong> info@eurofestia.com</li>
            <li><strong>Address:</strong> Noovative Group EOOD, Rayko Daskalov Str 68, Plovdiv, 4000, Bulgaria</li>
          </ul>
          <p>Thank you for trusting Eurofestia with your personal information. We are committed to protecting your privacy and providing a safe and enjoyable experience on our Site.</p>
          <p>&nbsp;</p>
        </div>
      </div>



      <Footer />
    </>
  );
};

export default Privacy;
