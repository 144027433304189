// import React, { useEffect, useRef } from "react";
// import Navbar from "./Navbar";
// import $ from "jquery";

// const Transaction = () => {

//     const dataTableRef = useRef();

//     useEffect(() => {
//       $(dataTableRef.current).DataTable();
//     }, []);

//   return (
//     <>
//       <Navbar login={true} />

//       <div className="container-fluid signin">
//         <div className="container">
//           <div className="signin-form">
//             <div className="sign-heading px-3">
//               <h1>Transaction History</h1>
//             </div>
//             <div className="row foot-tablerow">
//               <div className="col-lg-12 maintable-column mx-auto">
//                 <div className="container mt-4 overflow-auto">
//                   <table id="datatable" className="table" ref={dataTableRef}>
//                     <thead>
//                       <tr className="tr1 text-center">
                        
//                         <th>Transaction Id</th>
//                         <th>Show Name</th>
//                         <th>Show Venue</th>

//                         <th>Ticket Quantity</th>
//                         <th>Amount</th>
//                         <th>Actions</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       <tr className="tr1">
                        
//                         <td>#09089</td>
//                         <td className="ellipsis first"><abbr className="text-decoration-none me-4" title="London">Era's tour </abbr></td>
//                         <td className="ellipsis second"><abbr className="text-decoration-none" title="London">London, England </abbr> </td>
//                         <td>2</td>
//                         <td>$ 200</td>
//                         <td>
//                           <i className="fa fa-download"></i> 
//                         </td>
//                       </tr>
//                       <tr>
                        
//                         <td>#09089</td>
//                         <td className="ellipsis first"><abbr className="text-decoration-none me-4" title="London">Era's tour </abbr></td>
//                         <td className="ellipsis second"><abbr className="text-decoration-none" title="London">London, England </abbr> </td>
                        
//                         <td>2</td>
//                         <td>$ 200</td>
//                         <td>
//                           <i className="fa fa-download"></i> 
//                         </td>
//                       </tr>
//                       <tr>
                       
//                         <td>#09089</td>
//                         <td className="ellipsis first"><abbr className="text-decoration-none me-4" title="London">Era's tour </abbr></td>
//                         <td className="ellipsis second"><abbr className="text-decoration-none" title="London">London, England </abbr> </td>
                        
//                         <td>2</td>
//                         <td>$ 200</td>
//                         <td>
//                           <i className="fa fa-download"></i> 
//                         </td>
//                       </tr>
//                       <tr>
                       
//                         <td>#09089</td>
//                         <td className="ellipsis first"><abbr className="text-decoration-none me-4" title="London">Era's tour </abbr></td>
//                         <td className="ellipsis second"><abbr className="text-decoration-none" title="London">London, England </abbr> </td>
                        
//                         <td>2</td>
//                         <td>$ 200</td>
//                         <td>
//                           <i className="fa fa-download"></i>
//                         </td>
//                       </tr>

//                       <tr>
                        
//                         <td>#09089</td>
//                         <td className="ellipsis first"><abbr className="text-decoration-none me-4" title="London">Era's tour </abbr></td>
//                         <td className="ellipsis second"><abbr className="text-decoration-none" title="London">London, England </abbr> </td>
                        
//                         <td>2</td>
//                         <td>$ 200</td>
//                         <td>
//                           <i className="fa fa-download"></i>
//                         </td>
//                       </tr>
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Transaction;


import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import $ from "jquery";
import { Modal } from "react-bootstrap";

const BookingManagement = () => {
  const dataTableRef = useRef();
  const [bookings, setBookings] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [currentBooking, setCurrentBooking] = useState(null);

  useEffect(() => {
    // const fetchBookings = async () => {
    //   try {
    //     const response = await axios.get("https://backend.eurofestia.com/api/v1/payments/bookings");
    //     console.log("Fetched bookings:", response.data.bookings);
    //     if (Array.isArray(response.data.bookings)) {
    //       setBookings(response.data.bookings);
    //     } else {
    //       console.error("Unexpected response format:", response.data);
    //     }
    //   } catch (error) {
    //     console.error("Error fetching bookings:", error);
    //   }
    // };
    const fetchBookings = async () => {
      try {
        const response = await axios.get("https://backend.eurofestia.com/api/v1/payments/bookings");
        const bookingsData = response.data.bookings;

        // Fetch customer details for each booking
        const bookingsWithCustomerDetails = await Promise.all(
          bookingsData.map(async (booking) => {
            try {
              const customerResponse = await axios.get(`https://backend.eurofestia.com/api/v1/users/user/${booking.customer}`);
              return {
                ...booking,
                customerDetails: customerResponse.data.user,
              };
            } catch (error) {
              console.error(`Error fetching customer details for booking ${booking._id}:`, error);
              return {
                ...booking,
                customerDetails: null,
              };
            }
          })
        );

        setBookings(bookingsWithCustomerDetails);
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    };

    fetchBookings();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  const handleShowDelete = (booking) => {
    setCurrentBooking(booking);
    setShowDelete(true);
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
    setCurrentBooking(null);
  };

  const handleDelete = async () => {
    if (currentBooking) {
      try {
        await axios.delete(`https://backend.eurofestia.com/api/v1/payments/bookings/${currentBooking._id}`);
        setBookings(bookings.filter(booking => booking._id !== currentBooking._id));
        handleCloseDelete();
      } catch (error) {
        console.error("Error deleting booking:", error);
      }
    }
  };

  useEffect(() => {
    if (bookings.length > 0) {
      $(dataTableRef.current).DataTable();
    }
  }, [bookings]);

  return (
    <>
      <Sidebar />
      <Modal show={showDelete} onHide={handleCloseDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content1">
            <p>Do you want to delete this booking?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="find-event-delete" onClick={handleDelete}>
            Delete Booking
          </button>
          <button className="find-event" onClick={handleCloseDelete}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Booking Management</h1>
          </div>
          <div className="container-fluid">
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column mx-auto">
                <div className="container mt-4 overflow-auto">
                  <table id="datatable" className="table" ref={dataTableRef}>
                    <thead>
                      <tr className="tr1">
                      <th>Transaction Id</th>
                        <th>Event Name</th>
                        <th>Event Date</th>
                        <th>Customer Id</th>
                        <th>Customer Name</th>
                        {/* <th>Customer Email</th> */}
                        <th>Currency</th>
                        <th>Amount</th>
                        <th>Tickets</th>
                        <th>Status</th>
                        {/* <th>Actions</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {bookings.map((booking) => (
                        <tr key={booking._id}>
                          <td>{booking._id}</td>

                          <td>{booking.eventName}</td>
                          <td>{formatDate(booking.eventDate)}</td>
                          <td>{booking.customer}</td>
                          <td>{booking.customerDetails ? `${booking.customerDetails.firstname} ${booking.customerDetails.lastname}` : 'N/A'}</td>
                          {/* <td>{booking.customerDetails ? booking.customerDetails.email : 'N/A'}</td> */}
                          <td>{booking.currency}</td>
                          <td>{booking.amount}</td>
                          <td>{booking.ticketquantity}</td>
                          <td>{booking.status}</td>
                          {/* <td className="td1">
                            <i className="fa-solid fa-trash" onClick={() => handleShowDelete(booking)}></i>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingManagement;
