import React, { useState, useContext } from "react";
import Navbar from './Navbar';
import iziToast from "izitoast";
import { Link,useNavigate } from 'react-router-dom';
import {useAuth} from '../AuthContex';
import 'izitoast/dist/css/iziToast.min.css';

const Signin = () => {
  const { apipath } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${apipath}/users/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData)
        }
      );

      const result = await response.json();
      console.log(result);

      if (result.success === true) {
        if (!result.user.isVerified) {
          iziToast.error({
            message: "Your email is not verified. Please check your inbox for the verification email.",
            position: "topCenter"
          });
          return;
        }
        localStorage.setItem("token", result.token);
        iziToast.success({
          message: "Login successful",
          position: "topCenter"
        });
        if (result.user.role === 'admin') {
          localStorage.setItem('admintoken', result.token);
          localStorage.setItem('adminid', result.user._id);
          
          navigate("/dashboard");
        } else {
          const userId=result.user._id;
          localStorage.setItem('token', result.token);
          localStorage.setItem('userid', result.user._id);
          localStorage.setItem('useremail', result.user.email);
          // navigate(`/profile/${'userid'}`);
          // navigate("/");....
          const redirectTo = localStorage.getItem('redirectAfterLogin') || '/';
          localStorage.removeItem('redirectAfterLogin');
          navigate(redirectTo);
        }
      } else {
        iziToast.error({
          message: `Login failed: ${result.error}`,
          position: "topCenter"
        });
        console.error("Login failed:", result.error);
      }
    } catch (error) {
      iziToast.error({
        message: "Error during login",
        position: "topCenter"
      });
      console.error("Error during login:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  

  return (
    <>
      <Navbar />
      <div className="container-fluid signin">
        <div className="container">
          <div className="signin-form">
            <div className="row">
              <div className="col-lg-6 my-auto">
                <div className="signin-left">
                  <h1>Login</h1>
                  <p>Welcome back! Please enter your credentials to access your account.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="signin-right">
                  <form onSubmit={handleSubmit}>
                    <div className="sign-item">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="sign-item">
                      <label htmlFor="password">Password</label>
                      <div className="input-group sign-group">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          placeholder="Enter Password"
                          aria-describedby="basic-addon2"
                          class="form-control"
                          value={formData.password}
                          onChange={handleChange}
                          required
                        />
                        <div className="input-group-append sign-append">
                          <span
                            className="input-group-text sign-text"
                            id="basic-addon2"
                            onClick={togglePassword}
                          >
                            <i className="bi bi-eye"></i>
                          </span>
                        </div>
                      </div>
                      <Link to="/forgot" className="text-decoration-none">
                        <p><span>Forgot Password ?</span></p>
                      </Link>
                    </div>
                    <div className="signin-button">
                      <button type="submit">Continue</button>
                    </div>
                  </form>
                  <h6>
                    Don't have an account ? <Link to="/signup" className="text-decoration-none"><span>Register Here</span></Link>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;