import React, { createContext, useState,useContext } from 'react';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import iziToast from "izitoast";

const AuthContext = createContext();
const apipath='https://backend.eurofestia.com/api/v1';
// const apipath='http://localhost:4000/api/v1';

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  

  const login = async (email, password) => {
    try {
      const { data } = await axios.post(`${apipath}/users/login`, { email, password });
      const decodedToken = jwtDecode(data.token);
      localStorage.setItem('token', data.token);
      setIsLoggedIn(true);
      setUser(decodedToken);
      return decodedToken.role; // Return the role to handle redirection
    } catch (error) {
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userid');
    iziToast.success({
      message: "Logout successful",
      position: "topCenter"
    });
    // localStorage.removeItem('token');
    // localStorage.removeItem('userid');
    setIsLoggedIn(false);
    setUser(null);
    window.location.href = "/";
  };

  const adminLogout = () => {
    localStorage.removeItem('admintoken');
    localStorage.removeItem('adminid')
    // iziToast.success({
    //   message: "Logout successful",
    //   position: "topCenter"
    // });
    setIsLoggedIn(false);
    setUser(null);
    window.location.href = "/";
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, user,apipath, login, logout, adminLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => useContext(AuthContext);

// export default AuthContext;