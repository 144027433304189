import React from "react";
import Navbar from "./Navbar";
import { useLocation,useEffect ,Link} from "react-router-dom";

const Confirm = () => {
  const location =useLocation();
  const {paymentId}=location.state;
  
  return (
    <>
      <Navbar />

      <div className="container-fluid thankyou-box">
        <div className="container">
          <div className="signin-form thankyou-row row">
            <div className="col-lg-5 mx-auto confirm">
              <div className="confirm-img">
                <img src="assets/congrats.png" alt="" />
              </div>
              <h1>Congratulations !</h1>
              <h3>Your Tickets are </h3>
              <h1> Confirmed </h1>
              <div className="book-item pt-3">
                {/* <div>
                  <h2>payment id:</h2>
                  <h2>Total Price : </h2>
                  <h2>Total Tickets : </h2>
                </div> */}
                {/* <div>
                  <h2>{paymentId}</h2>
                  <h2>$77.6</h2>
                  <h2>2</h2>
                </div> */}
              </div>
              <div className="confirm-button">
                <button>
                  <Link to="/" className="text-decoration-none text-white">
                    Back to Home
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirm;
