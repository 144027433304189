import React, { useState, useEffect } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

const CustomCookieConsent = () => {
  const [isConsentGiven, setIsConsentGiven] = useState(false);

  useEffect(() => {
    const consent = getCookieConsentValue("my_app_cookies");
    setIsConsentGiven(consent === "true");
  }, []);

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept All Cookies"
      declineButtonText="Reject All"
      cookieName="my_app_cookies"
      style={{ background: "#000" }}
      buttonStyle={{
        color: "#fff",
        fontSize: "16px",
        padding: "8px 25px",
        fontWeight: "500",
        background: "linear-gradient(90deg, #1548fc 0%, #032498 100%)",
        border: "1px solid transparent",
        borderRadius: "8px",
      }}
      declineButtonStyle={{
        color: "#fff",
        fontSize: "16px",
        padding: "8px 25px",
        fontWeight: "500",
        background: "transparent",
        border: "1px solid #fff",
        borderRadius: "8px",
      }}
      expires={365}
      enableDeclineButton
      onAccept={() => setIsConsentGiven(true)}
      onDecline={() => setIsConsentGiven(false)}
    >
      This website uses cookies to enhance your experience. Please review our{" "}
      <a href="#" style={{color:"#fff", fontWeight:"500"}}>cookie policy</a> to learn more. By continuing to use this
      website, you consent to the use of cookies in accordance with our policy.
    </CookieConsent>
  );
};

export default CustomCookieConsent;
