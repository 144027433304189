import React, { useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: ""
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData) {
      iziToast.error({ title: 'Error', message: 'Please enter the details',position:"topCenter" });
      return;
    }
    try {
      await axios.post('https://backend.eurofestia.com/api/v1/contactus/postcontact', formData);
      iziToast.success({ title: 'Success', message: 'Message sent successfully',position:"topCenter" });
      setFormData({ name: "", email: "", phone: "", subject: "", message: "" });
    } catch (error) {
      iziToast.error({ title: 'Error', message: error.response.data.message });
    }
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid contact">
        <div className="container contact-header">
          <h2>Want to know more ?</h2>
          <h1>Feel free to Contact Us</h1>
        </div>
      </div>
      <div className="container-fluid contact-content">
        <div className="container">
          <div className="contact-h col-lg-8 mx-auto">
            <h1>Contact Us</h1>
            <p>
              Dive into the music! Join our festival for an unforgettable 
              experience. Contact us today to secure your spot.
            </p>
          </div>
          <div className="contact-form col-lg-10 mx-auto">
            <div className="row">
              <form onSubmit={handleSubmit}>
                <div className="col-lg-6">
                  <div className="cform-item">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Your name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="cform-item">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Your email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="cform-item">
                    <label htmlFor="phone">Phone No.</label>
                    <input
                      type="text"
                      name="phone"
                      placeholder="00000-00000"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="cform-item">
                    <label htmlFor="subject">Subject</label>
                    <input
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="cform-item">
                    <label htmlFor="message">Message</label>
                    <textarea
                      name="message"
                      placeholder="Write your message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="contact-button">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
