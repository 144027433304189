// import React, { useState, useEffect } from "react";
// import Navbar from "./Navbar";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import iziToast from 'izitoast';
// import 'izitoast/dist/css/iziToast.min.css';

// const VerifyRegistraion = () => {
//   const [otp, setOtp] = useState("");
//   const navigate = useNavigate();
//   const [email, setEmail] = useState("");

//   useEffect(() => {
//     const savedEmail = localStorage.getItem("signupEmail");
//     if (savedEmail) {
//       setEmail(savedEmail);
//     } else {
//       navigate("/signup");
//     }
//   }, [navigate]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!otp || !email) {
//       iziToast.error({ title: 'Error', message: 'Please enter the OTP', position: "topCenter" });
//       return;
//     }

//     try {
//       // await axios.post('https://backend.eurofestia.com/api/v1/users/verify-otp', { email, otp });
//       await axios.post('https://backend.eurofestia.com/api/v1/users/verify-otp', { email, otp });

//       iziToast.success({ title: 'Success', message: 'OTP verified successfully', position: "topCenter" });
//       localStorage.removeItem("signupEmail");
//       localStorage.removeItem("otpSent");
//       navigate('/signin');  // Redirect to the login page
//     } catch (error) {
//       iziToast.error({ title: 'Error', message: "Enter Valid OTP", position: "topCenter" });
//     }
//   };

//   return (
//     <>
//       <Navbar />
//       <div className="container-fluid signin">
//         <div className="container">
//           <div className="signin-form">
//             <div className="row">
//               <div className="col-lg-6 my-auto">
//                 <div className="signin-left">
//                   <h1>Verify OTP</h1>
//                   <p>Please enter the OTP sent to your email.</p>
//                 </div>
//               </div>
//               <div className="col-lg-6">
//                 <div className="signin-right">
//                   <form onSubmit={handleSubmit}>
//                     <div className="sign-item">
//                       <label htmlFor="otp">OTP</label>
//                       <input
//                         type="text"
//                         placeholder="Enter OTP"
//                         value={otp}
//                         onChange={(e) => setOtp(e.target.value)}
//                       />
//                     </div>
//                     <div className="signin-button">
//                       <button type="submit">Verify</button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default VerifyRegistraion;

import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

const VerifyRegistration = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isResending, setIsResending] = useState(false);

  useEffect(() => {
    const savedEmail = localStorage.getItem("signupEmail");
    if (savedEmail) {
      setEmail(savedEmail);
    } else {
      // navigate("/signup");
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!otp || !email) {
      iziToast.error({ title: 'Error', message: 'Please enter the OTP', position: "topCenter" });
      return;
    }

    try {
      await axios.post('https://backend.eurofestia.com/api/v1/users/verify-otp', { email, otp });
// await axios.post('http://localhost:4000/api/v1/users/verify-otp', { email, otp });

      iziToast.success({ title: 'Success', message: 'OTP verified successfully', position: "topCenter" });
      localStorage.removeItem("signupEmail");
      localStorage.removeItem("otpSent");
      navigate('/signin');  // Redirect to the login page
    } catch (error) {
      iziToast.error({ title: 'Error', message: "Enter Valid OTP", position: "topCenter" });
    }
  };

  const handleResendOtp = async () => {
    setIsResending(true);
    try {
      await axios.post('https://backend.eurofestia.com/api/v1/users/resend-otp', { email });
      // await axios.post('http://localhost:4000/api/v1/users/resend-otp', { email });

      iziToast.success({ title: 'Success', message: 'OTP resent successfully', position: "topCenter" });
    } catch (error) {
      iziToast.error({ title: 'Error', message: 'Error resending OTP. Please try again.', position: "topCenter" });
    } finally {
      setIsResending(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid signin">
        <div className="container">
          <div className="signin-form">
            <div className="row">
              <div className="col-lg-6 my-auto">
                <div className="signin-left">
                  <h1>Verify OTP</h1>
                  <p>Please enter the OTP sent to your email.</p>
                  {/* <p>If the OTP is expired try resend OTP .</p> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="signin-right">
                  <form onSubmit={handleSubmit}>
                    <div className="sign-item">
                      <label htmlFor="otp">OTP</label>
                      <input
                        type="text"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </div>
                    <div className="signin-button">
                      <button type="submit">Verify</button>
                    </div>
                  </form>
                  <div className="signin-button">
                    <button
                      onClick={handleResendOtp}
                      disabled={isResending}
                    >
                      {isResending ? "Resending..." : "Resend OTP"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyRegistration;
