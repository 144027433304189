
// import React, { useEffect, useState, useRef } from "react";
// import axios from "axios";
// import Sidebar from "./Sidebar";
// import $ from "jquery";
// import { Modal } from "react-bootstrap";

// const EventManagement = () => {
//   const dataTableRef = useRef();
//   const [events, setEvents] = useState([]);
//   const [show, setShow] = useState(false);
//   const [selectedEvent, setSelectedEvent] = useState(null);
//   const [ticketPrice, setTicketPrice] = useState("");
//   const [showDelete, setShowDelete] = useState(false);
//   const [currentEvent, setCurrentEvent] = useState(null);
  



//   useEffect(() => {
//     const fetchEvents = async () => {
//       try {
//         const response = await axios.get("https://backend.eurofestia.com/api/v1/events/eventdetails");
//         console.log("Fetched events:", response.data.event);
//         if (Array.isArray(response.data.event)) {
//           setEvents(response.data.event);
//         } else {
//           console.error("Unexpected response format:", response.data);
//         }
//       } catch (error) {
//         console.error("Error fetching events:", error);
//       }
//     };

//     fetchEvents();
//   }, []);
  
//   const handleShow = (event) => {
//     setSelectedEvent(event);
//     setTicketPrice(event.ticketprice || "");
//     setShow(true);
//   };

//   const handleClose = () => {
//     setShow(false);
//     setSelectedEvent(null);
//     setTicketPrice("");
//   };

//   const handleInputChange = (e) => {
//     setTicketPrice(e.target.value);
//   };
//   const handleShowDelete = (event) => {
//     setCurrentEvent(event);
//     setShowDelete(true);
//   };

//   const handleCloseDelete = () => {
//     setShowDelete(false);
//     setCurrentEvent(null);
//   };

//   const handleDelete = async () => {
//     if (currentEvent) {
//       try {
//         await axios.delete(`https://backend.eurofestia.com/api/v1/events/event/${currentEvent._id}`);
//         setEvents(events.filter(event => event._id !== currentEvent._id));
//         handleCloseDelete();
//       } catch (error) {
//         console.error("Error deleting event:", error);
//       }
//     }
//   };

//   useEffect(() => {
//     if (events.length > 0) {
//       $(dataTableRef.current).DataTable();
//     }
//   }, [events]);






//   const handleSavePrice = async () => {
//     if (!selectedEvent) return;
  
//     try {
//       const response = await axios.put(
//         `https://backend.eurofestia.com/api/v1/events/event/${selectedEvent._id}`,
//         { ticketprice: ticketPrice } // Ensure ticketPrice is the correct type (string or number)
//       );
  
//       // Update the events state with the updated event
//       setEvents(events.map(event => event._id === selectedEvent._id ? response.data.event : event));
//       setShow(false);
//     } catch (error) {
//       console.error("Error updating ticket price:", error);
//     }
//   };

//   return (
//     <>
//       <Sidebar />
//       <Modal show={show} onHide={handleClose} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Enter Ticket Price</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="modal-content1">
//             <form>
//               <div className="modal-box">
//                 <label htmlFor="">Event ID</label>
//                 <input type="text" value={selectedEvent ? selectedEvent.eventId : ""} disabled />
//               </div>
//               <div className="modal-box">
//                 <label htmlFor="">Event Name</label>
//                 <input type="text" value={selectedEvent ? selectedEvent.name : ""} disabled />
//               </div>
//               <div className="modal-box">
//                 <label htmlFor="">Ticket Price</label>
//                 <input type="text" value={ticketPrice} onChange={handleInputChange} />
//               </div>
//             </form>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <button className="find-event" onClick={handleSavePrice}>
//             Add Price
//           </button>
//         </Modal.Footer>
//       </Modal>
//       <Modal show={showDelete} onHide={handleCloseDelete} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Delete Event</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="modal-content1">
//             <p>Do you want to delete this event?</p>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <button className="find-event-delete" onClick={handleDelete}>
//             Delete Event
//           </button>
//           <button className="find-event" onClick={handleCloseDelete}>
//             Cancel
//           </button>
//         </Modal.Footer>
//       </Modal>

//       <div className="home">
//         <div className="toggle-sidebar" style={{ display: "none" }}>
//           <i className="bi bi-menu"></i>
//         </div>
//         <div className="dashboard">
//           <div className="dashboard-header">
//             <h1>Events Management</h1>
//           </div>
//           <div className="container-fluid">
//             <div className="row foot-tablerow">
//               <div className="col-lg-11 maintable-column mx-auto">
//                 <div className="container mt-4 overflow-auto">
//                   <table id="datatable" className="table" ref={dataTableRef}>
//                     <thead>
//                       <tr className="tr1">
//                         <th>Event Id</th>
//                         <th>Event Name</th>
//                         <th>Date</th>
//                         <th>Classification</th>
//                         <th>Venue</th>
//                         <th>City</th>
//                         <th>Country</th>
//                         <th>Ticket Price</th>
//                         <th>Actions</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {events.map((event) => (
//                         <tr key={event._id}>
//                           <td>{event.eventId}</td>
//                           <td className="ellipsis first">
//                             <abbr className="text-decoration-none me-4" title={event.name}>
//                               {event.name}
//                             </abbr>
//                           </td>
//                           <td className="ellipsis">
//                             <abbr className="text-decoration-none me-4" title={event.date}>
//                               {event.date}
//                             </abbr>
//                           </td>
//                           <td className="ellipsis">
//                             <abbr className="text-decoration-none me-4" title={event.classification}>
//                               {event.classification}
//                             </abbr>
//                           </td>
//                           <td className="ellipsis">
//                             <abbr className="text-decoration-none me-4" title={event.venue}>
//                               {event.venue}
//                             </abbr>
//                           </td>
//                           <td>{event.city}</td>
//                           <td className="ellipsis">
//                             <abbr className="text-decoration-none me-4" title={event.country}>
//                               {event.country}
//                             </abbr>
//                           </td>
//                           <td>{event.ticketprice}</td>
//                           <td className="td1">
//                             <i className="fa-regular fa-pen-to-square me-1" onClick={() => handleShow(event)}></i>
//                             <i className="fa-solid fa-trash"onClick={() => handleShowDelete(event)}></i>
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default EventManagement;








// import React, { useEffect, useState, useRef, useCallback } from "react";
// import axios from "axios";
// import Sidebar from "./Sidebar";
// import $ from "jquery";
// import {Link} from "react-router-dom";
// import { Modal } from "react-bootstrap";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css"; // Import styles
// import debounce from "lodash/debounce"; // Import debounce from lodash

// const EventManagement = () => {
//   const dataTableRef = useRef();
//   const [events, setEvents] = useState([]);
//   const [show, setShow] = useState(false);
//   const [selectedEvent, setSelectedEvent] = useState(null);
//   const [showDelete, setShowDelete] = useState(false);
//   const [currentEvent, setCurrentEvent] = useState(null);
//   const [formData, setFormData] = useState({
//     ticketprice: "",
//     name: "",
//     eventId: "",
//     date: "",
//     venue: "",
//     city: "",
//     country: "",
//     classification: "",
//     imageUrl: "",
//     attraction: "",
//     currency: "",
//     minprice: "",
//     maxprice: "",
//     facilities: "",
//     info: "",
//     ticketdetails: ""
//   });

//   useEffect(() => {
//     const fetchEvents = async () => {
//       try {
//         const response = await axios.get("https://backend.eurofestia.com/api/v1/events/eventdetails");
//         console.log("Fetched events:", response.data.event);
//         if (Array.isArray(response.data.event)) {
//           setEvents(response.data.event);
//         } else {
//           console.error("Unexpected response format:", response.data);
//         }
//       } catch (error) {
//         console.error("Error fetching events:", error);
//       }
//     };

//     fetchEvents();
//   }, []);

//   const handleShow = (event) => {
//     setSelectedEvent(event);
//     setFormData({
//       name: event.name || "",
//       eventId: event.eventId || "",
//       info: event.info || "",
//       date: event.date || "",
//       venue: event.venue || "",
//       city: event.city || "",
//       country: event.country || "",
//       classification: event.classification || "",
//       imageUrl: event.imageUrl || "",
//       attraction: event.attraction || "",
//       ticketdetails: event.ticketdetails || "",
//       currency: event.currency || "",
//       ticketprice: event.ticketprice || "",
//       minprice: event.minprice || "",
//       maxprice: event.maxprice || "",
//       facilities: event.facilities || "",
//     });
//     setShow(true);
//   };

//   const handleClose = () => {
//     setShow(false);
//     setSelectedEvent(null);
//     setFormData({
//       name: "",
//       eventId: "",
//       info: "",
//       date: "",
//       venue: "",
//       city: "",
//       country: "",
//       classification: "",
//       imageUrl: "",
//       attraction: "",
//       ticketdetails: "",
//       currency: "",
//       ticketprice: "",
//       minprice: "",
//       maxprice: "",
//       facilities: "",
//     });
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   // Debounce the handleQuillChange function
//   const debouncedQuillChange = useCallback(
//     debounce((name, value) => {
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         [name]: value,
//       }));
//     }, 300), // Adjust the delay as needed
//     []
//   );

//   const handleQuillChange = (name, value) => {
//     debouncedQuillChange(name, value);
//   };

//   const handleShowDelete = (event) => {
//     setCurrentEvent(event);
//     setShowDelete(true);
//   };

//   const handleCloseDelete = () => {
//     setShowDelete(false);
//     setCurrentEvent(null);
//   };

//   const handleDelete = async () => {
//     if (currentEvent) {
//       try {
//         await axios.delete(`https://backend.eurofestia.com/api/v1/events/event/${currentEvent._id}`);
//         setEvents(events.filter(event => event._id !== currentEvent._id));
//         handleCloseDelete();
//       } catch (error) {
//         console.error("Error deleting event:", error);
//       }
//     }
//   };

//   useEffect(() => {
//     if (events.length > 0) {
//       $(dataTableRef.current).DataTable();
//     }
//   }, [events]);

//   const handleSaveDetails = async () => {
//     if (!selectedEvent) return;

//     try {
//       const response = await axios.put(
//         `https://backend.eurofestia.com/api/v1/events/event/${selectedEvent._id}`,
//         formData
//       );

//       setEvents(events.map(event => event._id === selectedEvent._id ? response.data.event : event));
//       setShow(false);
//     } catch (error) {
//       console.error("Error updating event details:", error);
//     }
//   };

//   return (
//     <>
//       <Sidebar />
//       <Modal show={show} onHide={handleClose} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Edit Event Details</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="modal-content1">
//             <form>
//               {Object.keys(formData).map((key) => (
//                 key === "info" || key === "facilities" ? (
//                   <div className="modal-box" key={key}>
//                     <label htmlFor={key}>{key}</label>
//                     <ReactQuill
//                       value={formData[key]}
//                       onChange={(value) => handleQuillChange(key, value)}
//                     />
//                   </div>
//                 ) : (
//                   <div className="modal-box" key={key}>
//                     <label htmlFor={key}>{key}</label>
//                     <input
//                       type="text"
//                       name={key}
//                       value={formData[key]}
//                       onChange={handleInputChange}
//                       disabled={key === "eventId"}
//                     />
//                   </div>
//                 )
//               ))}
//             </form>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//         <Link to={{ pathname: "/adminform", state: { eventId: formData.eventId } }}>        
//         <button className="find-event" >
//             Add ticketdetails
//           </button>
//           </Link>
//           <button className="find-event" onClick={handleSaveDetails}>
//             Save Changes
//           </button>

//         </Modal.Footer>
//       </Modal>
import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import $ from "jquery";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles
import debounce from "lodash/debounce"; // Import debounce from lodash
import iziToast from "izitoast";

const EventManagement = () => {
  const dataTableRef = useRef();
  const [events, setEvents] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [formData, setFormData] = useState({
    promoter: "",
    name: "",
    eventId: "",
    // date: "",
    // time:"",
    // venue: "",
    // city: "",
    // country: "",
    // classification: "",
    imageUrl: "",
    attraction: "",
    currency: "",
    minprice: "",
    maxprice: "",
    facilities: "",
    info: "",
    ticketdetails: "",
    status:""
  });

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get("https://backend.eurofestia.com/api/v1/events/eventdetails");
        // const response = await axios.get("http://localhost:4000/api/v1/events/eventdetails");

        console.log("Fetched events:", response.data.event);
        if (Array.isArray(response.data.event)) {
          setEvents(response.data.event);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  const handleShow = (event) => {
    setSelectedEvent(event);
    setFormData({
      name: event.name || "",
      eventId: event.eventId || "",
      info: event.info || "",
      // date: event.date || "",
      // time:event.time ||"",
      // venue: event.venue || "",
      // city: event.city || "",
      // country: event.country || "",
      // classification: event.classification || "",
      imageUrl: event.imageUrl || "",
      attraction: event.attraction || "",
      promoter: event.promoter || "",
      ticketdetails: event.ticketdetails || "",
      currency: event.currency || "",
      minprice: event.minprice || "",
      maxprice: event.maxprice || "",
      facilities: event.facilities || "",
      status:event.status||"",
    });
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedEvent(null);
    setFormData({
      name: "",
      eventId: "",
      info: "",
      // date: "",
      // time:"",
      // venue: "",
      // city: "",
      // country: "",
      // classification: "",
      imageUrl: "",
      attraction: "",
      promoter: "",
      ticketdetails: "",
      currency: "",
      minprice: "",
      maxprice: "",
      facilities: "",
      status:"",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Debounce the handleQuillChange function
  const debouncedQuillChange = useCallback(
    debounce((name, value) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }, 300), // Adjust the delay as needed
    []
  );

  const handleQuillChange = (name, value) => {
    debouncedQuillChange(name, value);
  };

  const handleShowDelete = (event) => {
    setCurrentEvent(event);
    setShowDelete(true);
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
    setCurrentEvent(null);
  };

  const handleDelete = async () => {
    if (currentEvent) {
      try {
        await axios.delete(`https://backend.eurofestia.com/api/v1/events/event/${currentEvent._id}`);
                // await axios.delete(`http://localhost:4000/api/v1/events/event/${currentEvent._id}`);

        setEvents(events.filter(event => event._id !== currentEvent._id));
        handleCloseDelete();
      } catch (error) {
        console.error("Error deleting event:", error);
      }
    }
  };

  useEffect(() => {
    if (events.length > 0) {
      $(dataTableRef.current).DataTable();
    }
  }, [events]);

  const handleSaveDetails = async () => {
    if (!selectedEvent) return;
    const ticketDetailsUrl = `https://backend.eurofestia.com/api/v1/tickets/ticket/${selectedEvent.eventId}`;
    // const ticketDetailsUrl = `http://localhost:4000/api/v1/tickets/ticket/${selectedEvent.eventId}`;



    try {
      // Validate ticket details availability before publishing
      const ticketDetailsResponse = await axios.get(ticketDetailsUrl);
      if (ticketDetailsResponse.data.tickets.length=== 0) {
        iziToast.warning({
          title: "Warning!",
          message: "Please add ticket details before publishing the event.",
          position: "topCenter",
          timeout: 5000,
        });
        return;
      }
            // Update event details if ticket details are available
      const response = await axios.put(
        `https://backend.eurofestia.com/api/v1/events/event/${selectedEvent._id}`,
        // `http://localhost:4000/api/v1/events/event/${selectedEvent._id}`,

        formData
      );

      setEvents(events.map(event => event._id === selectedEvent._id ? response.data.event : event));
      setShow(false);
    } catch (error) {
      console.error("Error updating event details:", error);
      iziToast.error({
        title: "Error!",
        message: "An error occurred while updating the event. Please try again.",
        position: "topCenter",
        timeout: 5000,
      });
    }
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <Sidebar />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Event Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content1">
            <form>
              {Object.keys(formData).map((key) => (
                key === "info" || key === "facilities" ? (
                  <div className="modal-box" key={key}>
                    <label htmlFor={key}>{capitalizeFirstLetter(key)}</label>
                    <ReactQuill
                      value={formData[key]}
                      onChange={(value) => handleQuillChange(key, value)}
                    />
                  </div>
                ): key === "status" ? (
                  <div className="modal-box" key={key}>
                    <label htmlFor={key}>Status</label>
                    <select
                      name={key}
                      value={formData[key]}
                      onChange={handleInputChange}
                    >
                      <option value="Publish">Publish</option>
                      <option value="Unpublish">Unpublish</option>
                    </select>
                  </div>
                )  : (
                  <div className="modal-box" key={key}>
                    <label htmlFor={key}>{capitalizeFirstLetter(key)}</label>
                    <input
                      type="text"
                      name={key}
                      value={formData[key]}
                      onChange={handleInputChange}
                      disabled={key === "eventId"}
                    />
                  </div>
                )
              ))}
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link to={`/adminform/${formData.eventId}`}target="_blank" rel="noopener noreferrer">
            <button className="find-event">
              Add ticketdetails
            </button>
          </Link>
          <button className="find-event" onClick={handleSaveDetails}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>

        <Modal show={showDelete} onHide={handleCloseDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content1">
            <p>Do you want to delete this event?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="find-event-delete" onClick={handleDelete}>
            Delete Event
          </button>
          <button className="find-event" onClick={handleCloseDelete}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Events Management</h1>
          </div>
          <div className="container-fluid">
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column mx-auto">
                <div className="container mt-4 overflow-auto">
                  <table id="datatable" className="table" ref={dataTableRef}>
                    <thead>
                      <tr className="tr1">
                        <th>Event Id</th>
                        <th>Event Name</th>
                        <th>Date</th>
                        <th>Genre</th>
                        <th>Venue</th>
                        <th>City</th>
                        <th>Country</th>
                        <th>Ticket</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {events.map((event) => (
                        <tr key={event._id}>
                          <td>{event.eventId}</td>
                          <td className="ellipsis first">
                            <abbr className="text-decoration-none me-4" title={event.name}>
                              {event.name}
                            </abbr>
                          </td>
                          <td>{formatDate(event.date)}</td>
                          {/* <td className="ellipsis">
                            <abbr className="text-decoration-none me-4" title={formatDate(event.date)}>
                              {formatDate(event.date)}
                            </abbr>
                          </td> */}
                          <td className="ellipsis">
                            <abbr className="text-decoration-none me-4" title={event.classification}>
                              {event.classification}
                            </abbr>
                          </td>
                          <td className="ellipsis">
                            <abbr className="text-decoration-none me-4" title={event.venue}>
                              {event.venue}
                            </abbr>
                          </td>
                          <td>{event.city}</td>
                          <td className="ellipsis">
                            <abbr className="text-decoration-none me-4" title={event.country}>
                              {event.country}
                            </abbr>
                          </td>
                          <td>
                          <Link to={`/ticketdetails/${event.eventId}`}target="_blank" rel="noopener noreferrer" >
                            <i class="bi bi-ticket-perforated-fill"></i>
                            </Link>
                            </td>
                          <td className="td1">
                            <i className="fa-regular fa-pen-to-square me-1" onClick={() => handleShow(event)}></i>
                            <i className="fa-solid fa-trash" onClick={() => handleShowDelete(event)}></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventManagement;
