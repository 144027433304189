// import React, { useState } from "react";
// import Navbar from "./Navbar";
// import { Link } from "react-router-dom";

// const MyBooking = () => {
//   const [activeTab, setActiveTab] = useState("pending");

//   const openTab = (tabName) => {
//     setActiveTab(tabName);
//   };

//   return (
//     <>
//       <Navbar login={true} />

//       <div className="container-fluid signin">
//         <div className="container">
//           <div className="signin-form">
//             <div className="sign-heading px-3">
//               <h1>My Booked Shows</h1>
//             </div>
//             <div className="row">
//               <div className="col-lg-9 tab mx-auto">
//                 <button
//                   className={`tablinks  ${activeTab === "pending" && "active"}`}
//                   onClick={() => openTab("pending")}
//                   id="pending-button"
//                 >
//                   Bookings Pending
//                 </button>
//                 <button
//                   className={`tablinks ${activeTab === "upcoming" && "active"}`}
//                   onClick={() => openTab("upcoming")}
//                   id="upcoming-button"
//                 >
//                   Upcoming Bookings
//                 </button>
//                 <button
//                   className={`tablinks ${activeTab === "past" && "active"}`}
//                   onClick={() => openTab("past")}
//                   id="past"
//                 >
//                   Past Bookings
//                 </button>
//               </div>
//             </div>

//             <div
//               id="pending"
//               className={`tabcontent ${
//                 activeTab === "pending" ? "active" : ""
//               }`}
//             >
//               <div className="row tab-row">
//                 <div className="col-lg-6 tab-col">
//                   <div className="tab-card row">
//                     <div className="tab-left col-lg-10">
//                       <h2>Event Name</h2>
//                       <h6>Booking Id : #9898</h6>
//                       <h6>Booking Date : May 24, 2024</h6>
//                     </div>
//                     <div className="tab-right col-lg-2">
//                       <h4>$ 20</h4>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-6 tab-col">
//                   <div className="tab-card row">
//                     <div className="tab-left col-lg-10">
//                       <h2>Event Name</h2>
//                       <h6>Booking Id : #9898</h6>
//                       <h6>Booking Date : May 24, 2024</h6>
//                     </div>
//                     <div className="tab-right col-lg-2">
//                       <h4>$ 20</h4>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-6 tab-col">
//                   <div className="tab-card row">
//                     <div className="tab-left col-lg-10">
//                       <h2>Event Name</h2>
//                       <h6>Booking Id : #9898</h6>
//                       <h6>Booking Date : May 24, 2024</h6>
//                     </div>
//                     <div className="tab-right col-lg-2">
//                       <h4>$ 20</h4>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-6 tab-col">
//                   <div className="tab-card row">
//                     <div className="tab-left col-lg-10">
//                       <h2>Event Name</h2>
//                       <h6>Booking Id : #9898</h6>
//                       <h6>Booking Date : May 24, 2024</h6>
//                     </div>
//                     <div className="tab-right col-lg-2">
//                       <h4>$ 20</h4>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div
//               id="upcoming"
//               className={`tabcontent ${
//                 activeTab === "upcoming" ? "active" : ""
//               }`}
//             >
//               <div className="row tab-row">
//                 <div className="col-lg-6 tab-col">
//                   <div className="tab-card row">
//                     <div className="tab-left col-lg-10">
//                       <h2>Event Name</h2>
//                       <h6>Booking Id : #9898</h6>
//                       <h6>Booking Date : May 24, 2024</h6>
//                     </div>
//                     <div className="tab-right col-lg-2">
//                       <h4>$ 20</h4>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-6 tab-col">
//                   <div className="tab-card row">
//                     <div className="tab-left col-lg-10">
//                       <h2>Event Name</h2>
//                       <h6>Booking Id : #9898</h6>
//                       <h6>Booking Date : May 24, 2024</h6>
//                     </div>
//                     <div className="tab-right col-lg-2">
//                       <h4>$ 20</h4>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-6 tab-col">
//                   <div className="tab-card row">
//                     <div className="tab-left col-lg-10">
//                       <h2>Event Name</h2>
//                       <h6>Booking Id : #9898</h6>
//                       <h6>Booking Date : May 24, 2024</h6>
//                     </div>
//                     <div className="tab-right col-lg-2">
//                       <h4>$ 20</h4>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-6 tab-col">
//                   <div className="tab-card row">
//                     <div className="tab-left col-lg-10">
//                       <h2>Event Name</h2>
//                       <h6>Booking Id : #9898</h6>
//                       <h6>Booking Date : May 24, 2024</h6>
//                     </div>
//                     <div className="tab-right col-lg-2">
//                       <h4>$ 20</h4>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div
//               id="past"
//               className={`tabcontent ${activeTab === "past" ? "active" : ""}`}
//             >
//               <div className="row tab-row">
//                 <div className="col-lg-6 tab-col">
//                   <div className="tab-card row">
//                     <div className="tab-left col-lg-10">
//                       <h2>Event Name</h2>
//                       <h6>Booking Id : #9898</h6>
//                       <h6>Booking Date : May 24, 2024</h6>
//                     </div>
//                     <div className="tab-right col-lg-2">
//                       <h4>$ 20</h4>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-6 tab-col">
//                   <div className="tab-card row">
//                     <div className="tab-left col-lg-10">
//                       <h2>Event Name</h2>
//                       <h6>Booking Id : #9898</h6>
//                       <h6>Booking Date : May 24, 2024</h6>
//                     </div>
//                     <div className="tab-right col-lg-2">
//                       <h4>$ 20</h4>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-6 tab-col">
//                   <div className="tab-card row">
//                     <div className="tab-left col-lg-10">
//                       <h2>Event Name</h2>
//                       <h6>Booking Id : #9898</h6>
//                       <h6>Booking Date : May 24, 2024</h6>
//                     </div>
//                     <div className="tab-right col-lg-2">
//                       <h4>$ 20</h4>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-6 tab-col">
//                   <div className="tab-card row">
//                     <div className="tab-left col-lg-10">
//                       <h2>Event Name</h2>
//                       <h6>Booking Id : #9898</h6>
//                       <h6>Booking Date : May 24, 2024</h6>
//                     </div>
//                     <div className="tab-right col-lg-2">
//                       <h4>$ 20</h4>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* <div className="signup-button pt-5 px-4">
//                   <Link to="/edit-profile">
//                     <button>Edit Details</button>
//                   </Link>
//                 </div> */}
//             <div className="signup-button pt-5 px-4">
//               <Link to={`/transaction/${'userid'}`}>
//                 <button>View Transaction</button>
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default MyBooking;


// // import React, { useEffect, useState } from "react";
// // import axios from "axios";
// // import Navbar from "./Navbar";
// // import { Link } from "react-router-dom";

// // const MyBooking = () => {
// //   const [activeTab, setActiveTab] = useState("pending");
// //   const [bookings, setBookings] = useState([]);
// //   const [loading, setLoading] = useState(true);
// //   const [error, setError] = useState(null);
// //   const userId = localStorage.getItem('userid'); // Replace with actual user ID logic
// //   console.log(userId)


// //   useEffect(() => {
// //     const fetchBookings = async () => {
// //       try {
// //         const response = await axios.get(`https://backend.eurofestia.com/api/v1/payments/bookings/user/${userId}`);
// //         setBookings(response.data.bookings);
// //         console.log(response.data.bookings)

// //       } catch (error) {
// //         setError("Error fetching bookings");
// //       } finally {
// //         setLoading(false);
// //       }
// //     };

// //     fetchBookings();
// //   }, [userId]);

// //   const openTab = (tabName) => {
// //     setActiveTab(tabName);
// //   };

// //   if (loading) {
// //     return <div>Loading...</div>;
// //   }

// //   if (error) {
// //     return <div>{error}</div>;
// //   }

// //   const renderBookings = (status) => {
// //     return bookings
// //       .filter((booking) => booking.status.toLowerCase() === status)
// //       .map((booking) => (
// //         <div className="col-lg-6 tab-col" key={booking._id}>
// //           <div className="tab-card row">
// //             <div className="tab-left col-lg-10">
// //               <h2>{booking.eventName}</h2>
// //               <h6>Booking Id: {booking.paymentId}</h6>
// //               <h6>Booking Date: {new Date(booking.eventDate).toLocaleDateString()}</h6>
// //             </div>
// //             <div className="tab-right col-lg-2">
// //               <h4>{booking.currency} {booking.amount}</h4>
// //             </div>
// //           </div>
// //         </div>
// //       ));
// //   };

// //   return (
// //     <>
// //       <Navbar login={true} />
// //       <div className="container-fluid signin">
// //         <div className="container">
// //           <div className="signin-form">
// //             <div className="sign-heading px-3">
// //               <h1>My Booked Shows</h1>
// //             </div>
// //             <div className="row">
// //               <div className="col-lg-9 tab mx-auto">
// //                 <button
// //                   className={`tablinks  ${activeTab === "pending" && "active"}`}
// //                   onClick={() => openTab("pending")}
// //                   id="pending-button"
// //                 >
// //                   Bookings Pending
// //                 </button>
// //                 <button
// //                   className={`tablinks ${activeTab === "upcoming" && "active"}`}
// //                   onClick={() => openTab("upcoming")}
// //                   id="upcoming-button"
// //                 >
// //                   Upcoming Bookings
// //                 </button>
// //                 <button
// //                   className={`tablinks ${activeTab === "past" && "active"}`}
// //                   onClick={() => openTab("past")}
// //                   id="past"
// //                 >
// //                   Past Bookings
// //                 </button>
// //               </div>
// //             </div>

// //             <div
// //               id="pending"
// //               className={`tabcontent ${
// //                 activeTab === "pending" ? "active" : ""
// //               }`}
// //             >
// //               <div className="row tab-row">
// //                 {renderBookings("pending")}
// //               </div>
// //             </div>

// //             <div
// //               id="upcoming"
// //               className={`tabcontent ${
// //                 activeTab === "upcoming" ? "active" : ""
// //               }`}
// //             >
// //               <div className="row tab-row">
// //                 {renderBookings("paid")}
// //               </div>
// //             </div>

// //             <div
// //               id="past"
// //               className={`tabcontent ${activeTab === "past" ? "active" : ""}`}
// //             >
// //               <div className="row tab-row">
// //                 {renderBookings("completed")}
// //               </div>
// //             </div>

// //             <div className="signup-button pt-5 px-4">
// //               <Link to={`/transaction/${"userid"}`}>
// //                 <button>View Transaction</button>
// //               </Link>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </>
// //   );
// // };

// // export default MyBooking;

import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";

const MyBooking = () => {
  const [activeTab, setActiveTab] = useState("upcoming");
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userId = localStorage.getItem('userid')

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.get(`https://backend.eurofestia.com/api/v1/payments/bookings/user/${userId}`);
        setBookings(response.data.bookings);
        console.log(response.data.bookings);
      } catch (error) {
        setError("Error fetching bookings");
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, [userId]);

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  if (loading) {
    // return <div>Loading...</div>;
    return (
      <div className="loading-bg">
        <div className="loader"></div>
        <h3>You are almost there...</h3>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  const renderBookings = (status) => {
    const filteredBookings = bookings.filter((booking) => {
      if (status === "pending") return booking.status.toLowerCase() === "pending";
      if (status === "upcoming") return booking.status.toLowerCase() === "paid" && new Date(booking.eventDate) > new Date();
      if (status === "past") return new Date(booking.eventDate) < new Date();
      return false;
    });

    if (filteredBookings.length === 0) {
      return <div>No bookings found for this category.</div>;
    }

    return filteredBookings.map((booking) => (
      <div className="col-lg-6 tab-col" key={booking._id}>
        <div className="tab-card row">
          <div className="tab-left col-lg-9">
            <h2>{booking.eventName}</h2>
            <h6>Booking Id: {booking.paymentId}</h6>
            <h6>Booking Date: {new Date(booking.createdAt).toLocaleDateString()}</h6>
          </div>
          <div className="tab-right col-lg-3">
            <h6 className="me-1">{booking.currency} </h6>
            <h6>{booking.amount}</h6>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <Navbar login={true} />
      <div className="container-fluid signin">
        <div className="container">
          <div className="signin-form">
            <div className="sign-heading px-3">
              <h1>My Booked Shows</h1>
            </div>
            <div className="row">
              <div className="col-lg-9 tab mx-auto">
                {/* <button
                  className={`tablinks  ${activeTab === "pending" && "active"}`}
                  onClick={() => openTab("pending")}
                  id="pending-button"
                >
                  Bookings Pending
                </button> */}
                <button
                  className={`tablinks ${activeTab === "upcoming" && "active"}`}
                  onClick={() => openTab("upcoming")}
                  id="upcoming-button"
                >
                  Upcoming Bookings
                </button>
                <button
                  className={`tablinks ${activeTab === "past" && "active"}`}
                  onClick={() => openTab("past")}
                  id="past"
                >
                  Past Bookings
                </button>
              </div>
            </div>

            {/* <div
              id="pending"
              className={`tabcontent ${
                activeTab === "pending" ? "active" : ""
              }`}
            >
              <div className="row tab-row">
                {renderBookings("pending")}
              </div>
            </div> */}

            <div
              id="upcoming"
              className={`tabcontent ${
                activeTab === "upcoming" ? "active" : ""
              }`}
            >
              <div className="row tab-row">
                {renderBookings("upcoming")}
              </div>
            </div>

            <div
              id="past"
              className={`tabcontent ${activeTab === "past" ? "active" : ""}`}
            >
              <div className="row tab-row">
                {renderBookings("past")}
              </div>
            </div>

            <div className="signup-button pt-5 px-4">
              <Link to={`/transaction/${'userid'}`}>
                <button>View Transaction</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyBooking;
