import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Refund = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <>
      <Navbar />

      <div className="container-fluid privacy">
        <h1>Refund Policy</h1>
      </div>
      <div className="container-fluid privacy-content">
        {/* <div className="container pt-5">
          <h3>Refund Policy</h3>
          <p>
            We respect your privacy. When you give us your personal information,
            we use it only to fulfill the transaction or service you have
            requested.
          </p>
          <p>
            We do not subscribe you to marketing emails without your consent.
          </p>
          <p>
            We do not sell or give away your contact information to any other
            entities.
          </p>
          <p>
            We do not allow the vendors who help us process transactions to sell
            or give away your information either.
          </p>
          <p>
            If you have questions about how we use your information please
            contact privacy@nngroup.com.
          </p>
          <h3>Detailed Refund Policy</h3>
          <ol>
            <li>
              <b>SCOPE</b>
              <p>
                This policy applies to personal information collected on
                websites owned or controlled by Nielsen Norman Group
                (collectively referred to in this policy as “we”, "us" or
                "our"). The aim of this policy is to tell you how we will use
                any personal information we collect or you provide through our
                websites. Please read it carefully before you proceed. The data
                controller in respect of this website is Nielsen Norman Group.
              </p>
            </li>
            <li>
              <b>WHAT PERSONAL INFORMATION DO WE COLLECT?</b>
              <p>
                You do not have to give us any personal information in order to
                use most of this website. However, if you wish to subscribe to
                our newsletter, attend the UX Conference, attend or purchase an
                Online Seminar, purchase a Research Report, or request further
                information, we may collect the following personal information
                from you: name, address, phone number, email address, employment
                details, and employer details. We will also keep a record of any
                financial transaction you make with us but we do not directly
                collect, process or store your debit or credit card information.
                Online payments made through this website are processed securely
                by third party payment providers.
              </p>
              <p>
                We use different processors for different types of transactions
                and to manage support for different services.For more
                information about how your data will be handled please refer to
                the respective service provider's privacy policy:
              </p>
              <p>
                Research Report Purchases: FastSpring
                https://fastspring.com/privacy/
              </p>
              <p>
                Online Seminars: Crowdcast,
                https://www.crowdcast.io/privacy-policy
              </p>
              <p>
                UX Conference Payments: Stripe, https://stripe.com/us/privacy
              </p>
              <p>
                In addition, we may automatically collect information about the
                website that you came from or are going to. We also collect
                information about the pages of this website which you visit, IP
                addresses, the type of browser you use and the times you access
                this website. However, this information is aggregated across all
                visitors and we do not use it to identify you.
              </p>
            </li>
            <li>
              <b>HOW WILL YOUR PERSONAL INFORMATION BE USED AND SHARED?</b>
              <p>
                The personal information we collect allows us to: <br />
                deliver the products and services you have ordered; <br />
                verify your identity and details of your payment method or
                credit card amount; <br />
                administer our website and provide customer services;
                <br />
                meet legal, regulatory and compliance requirements;
                <br />
                monitor and analyse the use of any account to prevent,
                investigate and/or report fraud, terrorism, misrepresentation,
                security incidents or crime;
              </p>
            </li>
          </ol>
        </div> */}
        <div className="container pt-5">
          <h2>Refund Policy</h2>
          <p><strong>Effective Date:</strong> 11/1/2024</p>
          <p>Thank you for choosing Eurofestia for your event and music ticket purchases. We strive to provide a seamless and enjoyable experience for all our customers. Please read this Refund Policy carefully to understand your rights and obligations regarding ticket purchases.</p>
          <ol>
            <li><strong>General Information </strong>
              <ul>
                <li><strong>Website:</strong> Eurofestia</li>
                <li><strong>Owner:</strong> Noovative Group EOOD</li>
                <li><strong>Registered Address:</strong> Rayko Daskalov Str 68, Plovdiv, 4000, Bulgaria</li>
                <li><strong>Company Identification Number:</strong> 207847263</li>
                <li><strong>Contact Information:</strong> info@eurofestia.com</li>
              </ul>
            </li>
            <li><strong>No Refunds Policy </strong></li>
          </ol>
          <p>All ticket sales on Eurofestia are final. Once a ticket is purchased, it cannot be refunded, exchanged, or canceled, except as outlined in this Refund Policy or as required by applicable law.</p>
          <ol start="3">
            <li><strong>Event Cancellations </strong>
              <ul>
                <li><strong>Full Event Cancellation </strong></li>
              </ul>
            </li>
          </ol>
          <p>If an event is canceled and not rescheduled, you will be entitled to a refund of the ticket price, excluding any service fees, processing fees, or delivery charges. We will notify you via the email address provided during purchase with details on how to obtain your refund.</p>
          <ul>
            <li><strong>Rescheduled Events </strong></li>
          </ul>
          <p>If an event is rescheduled, your ticket will typically be valid for the new date. In the event that you are unable to attend the rescheduled date, you may request a refund. Refund requests must be made within the time frame specified in the notification email. Service fees, processing fees, or delivery charges may not be refundable.</p>
          <ol start="4">
            <li><strong>Changes to Event Lineups or Venues </strong></li>
          </ol>
          <p>Changes to the event lineup, performers, or venue do not typically qualify for a refund. These changes are at the discretion of the event organizers and are beyond the control of Eurofestia. Please review the event details carefully before purchasing your ticket.</p>
          <ol start="5">
            <li><strong>Third-Party Events </strong></li>
          </ol>
          <p>For events organized by third parties, the refund policy of the event organizer may apply. Eurofestia will assist in facilitating any refunds as per the organizer&rsquo;s policy but cannot guarantee a refund if the organizer&rsquo;s policy does not allow for one.</p>
          <ol start="6">
            <li><strong>How to Request a Refund </strong>
              <ul>
                <li><strong>Eligibility </strong></li>
              </ul>
            </li>
          </ol>
          <p>To request a refund, you must meet the criteria outlined in this Refund Policy (e.g., event cancellation or rescheduling). If eligible, follow the steps below to request your refund.</p>
          <ul>
            <li><strong>Refund Request Process </strong>
              <ul>
                <li>Contact our customer service team at <u>info@eurofestia.com</u> within the specified time frame.</li>
                <li>Provide your order number, event details, and the reason for your refund request.</li>
                <li>Once your request is reviewed and approved, your refund will be processed to the original payment method.</li>
              </ul>
            </li>
            <li><strong>Refund Processing Time </strong></li>
          </ul>
          <p>Refunds will be processed within 7 business days of the approval of your refund request. Depending on your payment provider, it may take additional time for the refund to appear on your statement. <strong>7. Exceptions </strong></p>
          <ul>
            <li><strong>Non-Refundable Fees </strong></li>
          </ul>
          <p>Service fees, processing fees, delivery charges, and any other non-ticket-related fees are non-refundable, even in the case of event cancellation or rescheduling.</p>
          <ul>
            <li><strong>Unauthorized Resales </strong></li>
          </ul>
          <p>Tickets purchased through unauthorized third-party sellers or resellers are not eligible for refunds under this policy. Only tickets purchased directly through Eurofestia are covered by this Refund Policy.</p>
          <ol start="8">
            <li><strong> Contact Information </strong></li>
          </ol>
          <p>If you have any questions or need further assistance regarding your purchase or this Refund Policy, please contact us at:</p>
          <ul>
            <li><strong>Email:</strong> info@eurofestia.com</li>
            <li><strong>Address:</strong> Noovative Group EOOD, Rayko Daskalov Str 68, Plovdiv, 4000, Bulgaria</li>
          </ul>
          <p>We appreciate your understanding and cooperation. Thank you for choosing Eurofestia for your event and music ticket needs!</p>
          <p>&nbsp;</p>
        </div>
      </div>



      <Footer />
    </>
  );
};

export default Refund;
