import React, { useEffect } from "react";
import { Link } from "react-router-dom";


const Footer = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <>
      <div className="container-fluid footer">
        <div className="container">
          <div className="row footer-row">
            <div className="col-lg-4">
              <div className="footer-content">
                <div className="footer-logo">
                  {/* <img src="assets/logo.png" alt="" /> */}
                  <img src={`${process.env.PUBLIC_URL}/assets/logo.png`} alt="logo"/>

                </div>
                <p>
                  {/* Lorem ipsum dolor sit amet, consectetur theithis adipiscing
                  elit.{" "} */}
                </p>
                <h6>Our trusted payment partners :</h6>
                <div className="footer-imgs">
                  <img src={`${process.env.PUBLIC_URL}/assets/master-card.png`} alt="" />
                  <img src={`${process.env.PUBLIC_URL}/assets/visa.png`} alt="" />
                  <img src={`${process.env.PUBLIC_URL}/assets/paypal.png`} alt="" />
                  <img src={`${process.env.PUBLIC_URL}/assets/maestro.png`} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="footer-list">
                <h3>Quick Links</h3>
                {/* <li>About Us</li> */}
                <Link to = "/userevents" className="text-decoration-none"><li >Events</li></Link>
                <Link to = "/signup" className="text-decoration-none"><li>Register</li></Link>
                <Link to = "/contact" className="text-decoration-none"><li>Contact</li></Link>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-list">
                <h3>Contact Us</h3>
                <li>
                  <i className="bi bi-geo-alt-fill"></i> Noovative Group EOOD, Rayko Daskalov str68,
                  Plodiv, 4000, Bulgaria
                </li>
                {/* <li>
                  <i className="bi bi-telephone"></i> +44-0000 0000
                </li> */}
                <div className="footer-social">
                  <i className="bi bi-facebook"></i>
                  <i className="bi bi-twitter"></i>
                  <i className="bi bi-instagram"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-list">
                <h3>Policies</h3>
                <Link to="/privacy" className="text-decoration-none"><li>Privacy Policy</li></Link>
                <Link to="/refund" className="text-decoration-none"><li>Refund Policy</li></Link>
                <Link to="/terms" className="text-decoration-none"><li>Terms & Conditions</li></Link>

                <Link to="/contact" className="text-decoration-none"> <h6>Have questions? We're here to assist you!</h6>
                <div class="input-group footer-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Write a message"
                    aria-label="password"
                    aria-describedby="basic-addon2"
                  />

                  <div class="input-group-append footer-append">
                    <span class="input-group-text" id="basic-addon2">
                      {" "}
                      Send{" "}
                    </span>
                  </div>
                </div></Link>
              </div>
            </div>
          </div>
          <div className="row footer-row2">
            <div className="col-lg-12">
              <p>Copyrights © 2024 <b>EUROFESTIA, All Right Reserved</b></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
