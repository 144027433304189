// import React, { useEffect, useState } from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";

// const Booking = () => {
//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       left: 0,
//       behavior: "auto",
//     });
//   }, []);

//   const [count, setCount] = useState([0, 0, 0, 0]);

//   const increaseCount = (index) => {
//     setCount((prev) =>
//       prev.map((value, i) => (i === index ? value + 1 : value))
//     );
//   };

//   const decreaseCount = (index) => {
//     setCount((prev) =>
//       prev.map((value, i) => (i === index && value > 0 ? value - 1 : value))
//     );
//   };

//   return (
//     <>
//       <Navbar />

//       <div className="container-fluid contact">
//         <div className="container contact-header">
//           <h2>Hurry up !!</h2>
//           <h1>Book Your Tickets Now</h1>
//         </div>
//       </div>

//       <div className="container-fluid contact-content">
//         <div className="container">
//           <div className="booking-heading">
//             <h1>Ticket Details</h1>
//           </div>
//           <div className="row book-row d-flex justify-content-between">
//             <div className="col-lg-6">
//               <div className="book-left">
//                 <h2>Date : 5-7 May</h2>
//                 <h3>Venue : London</h3>
//                 <h1>Type 1 (title of ticket tier)</h1>
//                 <div>
//                   <li>
//                   <h4>Details</h4>
//                   </li>
//                   <li>
//                     <span>Price : </span>  € 40
//                   </li>
//                   <li>
//                     <span>Booking Fee : </span>  € 20
//                   </li>
//                 </div>
                
//                 <h1>Type 2 (title of ticket tier)</h1>
//                 <div>
//                   <li>
//                   <h4>Details</h4>
//                   </li>
//                   <li>
//                     <span>Price : </span>  € 40
//                   </li>
//                   <li>
//                     <span>Booking Fee : </span>  € 20
//                   </li>
//                 </div>
//                 <h1>Type 3 (title of ticket tier)</h1>
//                 <div>
//                   <li>
//                   <h4>Details</h4>
//                   </li>
//                   <li>
//                     <span>Price : </span>  € 40
//                   </li>
//                   <li>
//                     <span>Booking Fee : </span>  € 20
//                   </li>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-5">
//               <div className="book-right">
//                 {/* <div className="book-item">
//                   <div>
//                     <h1>General Admission</h1>
//                     <h3>Price : $ 16.90</h3>
//                     <h3>Booking Fee : $ 6.90</h3>
//                   </div>

//                   <div className="book-counter">
//                     <span>
//                       <i
//                         className="bi bi-plus"
//                         onClick={() => increaseCount(0)}
//                       ></i>
//                     </span>
//                     <h3 className="px-3">{count[0]}</h3>
//                     <span>
//                       <i
//                         className="bi bi-dash"
//                         onClick={() => decreaseCount(0)}
//                       ></i>
//                     </span>
//                   </div>
//                 </div> */}
//                 <div className="book-item">
//                   <div>
//                     <h1>Type 1 </h1>
//                     <h3>Price : $ 26.90</h3>
//                     <h3>Booking Fee : $ 6.90</h3>
//                   </div>
//                   <div className="book-counter">
//                     <span>
//                       <i
//                         className="bi bi-plus"
//                         onClick={() => increaseCount(1)}
//                       ></i>
//                     </span>
//                     <h3 className="px-3">{count[1]}</h3>
//                     <span>
//                       <i
//                         className="bi bi-dash"
//                         onClick={() => decreaseCount(1)}
//                       ></i>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="book-item">
//                   <div>
//                     <h1>Type 2 </h1>
//                     <h3>Price : $ 36.90</h3>
//                     <h3>Booking Fee : $ 6.90</h3>
//                   </div>
//                   <div className="book-counter">
//                     <span>
//                       <i
//                         className="bi bi-plus"
//                         onClick={() => increaseCount(2)}
//                       ></i>
//                     </span>
//                     <h3 className="px-3">{count[2]}</h3>
//                     <span>
//                       <i
//                         className="bi bi-dash"
//                         onClick={() => decreaseCount(2)}
//                       ></i>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="book-item">
//                   <div>
//                     <h1>Type 3 </h1>
//                     <h3>Price : $ 46.90</h3>
//                     <h3>Booking Fee : $ 6.90</h3>
//                   </div>
//                   <div className="book-counter">
//                     <span>
//                       <i
//                         className="bi bi-plus"
//                         onClick={() => increaseCount(3)}
//                       ></i>
//                     </span>
//                     <h3 className="px-3">{count[3]}</h3>
//                     <span>
//                       <i
//                         className="bi bi-dash"
//                         onClick={() => decreaseCount(3)}
//                       ></i>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="book-item pt-3">
//                   <div>
//                     <h2>Total Price : </h2>
//                     <h2>Total Tickets : </h2>
//                   </div>
//                   <div>
//                     <h2>$77.6</h2>
//                     <h2>{count[0]}</h2>
//                   </div>
//                 </div>
//                 <div className="book-payment pt-3">
//                   <h3>Our trusted payment partners :</h3>
//                   <div className="payment-imgs">
//                     <img src="assets/master-card.png" alt="" />
//                     <img src="assets/visa.png" alt="" />
//                     <img src="assets/paypal.png" alt="" />
//                     <img src="assets/maestro.png" alt="" />
//                   </div>
//                 </div>
//                 <div className="book-button">
//                   <button>
//                     <Link
//                       to="/confirm"
//                       className="text-decoration-none text-white"
//                     >
//                       Confirm Payment
//                     </Link>
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Booking;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Link, useParams } from "react-router-dom";

// const Booking = () => {
//   const { eventId } = useParams();
//   const [event,setEvent]=useState([]);
//   const [tickets, setTickets] = useState([]);
//   const [count, setCount] = useState([]);

//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       left: 0,
//       behavior: "auto",
      
//     });

//     const fetchTickets = async () => {
//       try {
//         const response = await axios.get(`https://backend.eurofestia.com/api/v1/tickets/ticket/${eventId}`);
//         setTickets(response.data.tickets);
//         setCount(new Array(response.data.tickets.length).fill(0));
//       } catch (error) {
//         console.error("Error fetching tickets:", error);
//       }
//     };
//     const fetchEvent = async () => {
//       try {
//         const localresponse = await axios.get(`https://backend.eurofestia.com/api/v1/events/event/${eventId}`);
//         setEvent(localresponse.data.event[0]);
//         console.log("localevent", localresponse.data);
//       } catch (error) {
//         console.error("Error fetching event:", error);
//       }
//     };

//     fetchTickets();
//     fetchEvent();
//   }, [eventId]);


//   const increaseCount = (index) => {
//     setCount((prev) =>
//       prev.map((value, i) => (i === index ? value + 1 : value))
//     );
//   };

//   const decreaseCount = (index) => {
//     setCount((prev) =>
//       prev.map((value, i) => (i === index && value > 0 ? value - 1 : value))
//     );
//   };

//   const totalTickets = count.reduce((total, value) => total + value, 0);
//   const totalPrice = tickets.reduce((total, ticket, index) => {
//     const ticketCount = count[index];
//     return total + (ticketCount * (ticket.ticketPrice + ticket.bookingfeePrice));
//   }, 0);
//   // console.log(totalPrice,totalTickets)

//   return (
//     <>
//       <Navbar />

//       <div className="container-fluid contact">
//         <div className="container contact-header">
//           <h2>Hurry up !!</h2>
//           <h1>Book Your Tickets Now</h1>
//         </div>
//       </div>

//       <div className="container-fluid contact-content">
//         <div className="container">
//           <div className="booking-heading">
//             <h1>Ticket Details</h1>
//           </div>
//           <div className="row book-row d-flex justify-content-between">
//             <div className="col-lg-6">
//               <div className="book-left">
//                 <h2>Date : {event.date}</h2>
//                 <h3>Venue : {event.venue}</h3>
//                 {tickets.map((ticket, index) => (
//                   <div key={index}>
//                     <h1>{ticket.typeName}</h1>
//                     <div>
//                       <li>
//                         <h4>Details</h4> {ticket.comment}
                        
//                       </li>
//                       <li>
//                         <span>Price : </span> {ticket.priceCurrency}  {ticket.ticketPrice}
//                       </li>
//                       <li>
//                         <span>Booking Fee : </span>{ticket.bookingfeeCurrency}  {ticket.bookingfeePrice}
//                       </li>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//             <div className="col-lg-5">
//               <div className="book-right">
//                 {tickets.map((ticket, index) => (
//                   <div className="book-item" key={index}>
//                     <div>
//                       <h1>{ticket.typeName}</h1>
//                       <h3>Price : {ticket.priceCurrency}  {ticket.ticketPrice}</h3>
//                       <h3>Booking Fee : {ticket.bookingfeeCurrency}  {ticket.bookingfeePrice}</h3>
//                     </div>
//                     <div className="book-counter">
//                       <span>
//                         <i
//                           className="bi bi-plus"
//                           onClick={() => increaseCount(index)}
//                         ></i>
//                       </span>
//                       <h3 className="px-3">{count[index]}</h3>
//                       <span>
//                         <i
//                           className="bi bi-dash"
//                           onClick={() => decreaseCount(index)}
//                         ></i>
//                       </span>
//                     </div>
//                   </div>
//                 ))}
//                 <div className="book-item pt-3">
//                   <div>
//                     <h2>Total Price : </h2>
//                     <h2>Total Tickets : </h2>
//                   </div>
//                   <div>
//                     <h2>{totalPrice.toFixed(2)}</h2>
//                     <h2>{totalTickets}</h2>
//                   </div>
//                 </div>
//                 <div className="book-payment pt-3">
//                   <h3>Our trusted payment partners :</h3>
//                   <div className="payment-imgs">
//                     <img src="assets/master-card.png" alt="" />
//                     <img src="assets/visa.png" alt="" />
//                     <img src="assets/paypal.png" alt="" />
//                     <img src="assets/maestro.png" alt="" />
//                   </div>
//                 </div>
//                 <div className="book-button">
//                   <button>
//                     <Link to={{
//                         pathname: `/payment/${event.eventId}`,
//                         state:  {totalPrice, totalTickets} ,
//                       }}
//                       className="text-decoration-none text-white"
//                     >
//                       Confirm Payment
//                     </Link>
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Booking;





import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useParams,useNavigate } from "react-router-dom";
import iziToast from "izitoast";

// import { useBooking } from '../BookingContext';





const Booking = () => {
  const { eventId } = useParams();
  // const { setBookingData } = useBooking();
  const [event, setEvent] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [count, setCount] = useState([]);
  const [priceCurrency, setPriceCurrency] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: '' });

  const navigate=useNavigate();
  const userid = localStorage.getItem('token');

  useEffect(() => {
    window.scrollTo({
      top: 450,
      left: 0,
      behavior: "auto",
    });

    // if (!userid) {
    //   iziToast.error({
    //     title: 'Error',
    //     message: 'Please Login as user to continue',
    //     position: 'topCenter'
    //   });
    //   navigate('/signin');
    //   return; // Prevent further execution if user is not logged in
    // }
    // if (!userid) {
    //   alert('Please Login as user to continue');
    //   navigate('/signin');
    //   return; // Prevent further execution if user is not logged in
    // }
  

    

    const fetchTickets = async () => {
      try {
        const response = await axios.get(`https://backend.eurofestia.com/api/v1/tickets/ticket/${eventId}`);
        // const response = await axios.get(`http://localhost:4000/api/v1/tickets/ticket/${eventId}`);

        setTickets(response.data.tickets);
        setCount(new Array(response.data.tickets.length).fill(0));
        if (response.data.tickets.length > 0) {
          setPriceCurrency(response.data.tickets[0].priceCurrency); // Set priceCurrency from the first ticket
        }
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    };

    const fetchEvent = async () => {
      try {
        const localresponse = await axios.get(`https://backend.eurofestia.com/api/v1/events/event/${eventId}`);
        // const localresponse = await axios.get(`http://localhost:4000/api/v1/events/event/${eventId}`);

        setEvent(localresponse.data.event[0]);
        console.log("localevent", localresponse.data);
      } catch (error) {
        console.error("Error fetching event:", error);
      }
    };

    fetchTickets();
    fetchEvent();
  }, [eventId,navigate,userid]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  const increaseCount = (index) => {
    setCount((prev) =>
      prev.map((value, i) => (i === index ? value + 1 : value))
    );
  };

  const decreaseCount = (index) => {
    setCount((prev) =>
      prev.map((value, i) => (i === index && value > 0 ? value - 1 : value))
    );
  };

  const totalTickets = count.reduce((total, value) => total + value, 0);
  const totalPrice = tickets.reduce((total, ticket, index) => {
    const ticketCount = count[index];
    return ( total + (ticketCount * (ticket.ticketPrice + ticket.bookingfeePrice)));
  }, 0);
  // const date=new Date(event.date).toISOString().slice(0, -5) + 'Z';
  // const handleConfirmPayment = () => {
  //   const bookingDetails = {
  //     totalPrice,
  //     totalTickets,
  //     priceCurrency,
  //     eventName: event.name,
  //     eventDate: event.date,
  //     eventId: event.eventId,
  //     tickets,
  //     count
  //   };
  //   setBookingData(bookingDetails);
  // };
  const handleConfirmPayment = () => {
    
    if (totalTickets === 0) {
      iziToast.warning({
        title: "Warning!",
        message: "Please select at least one ticket to proceed.",
        position: "topCenter",
        timeout: 5000,
      });
      return;
    }
   
        // const redirectTo = localStorage.getItem('redirectAfterLogin') || '/';
          // localStorage.removeItem('redirectAfterLogin');
          // navigate(redirectTo);
    
    // else{
    
    //             navigate(`/payment/${eventId}`);
    //     //     } else {
    //     //         localStorage.setItem('redirectAfterLogin', `/payment/${eventId}`);
    //     //         navigate('/signin');
    //     //     }
    // }

    if (!isTermsAccepted) {
      iziToast.warning({
        title: "Warning!",
        message: "Please accept the Terms & Conditions, Privacy Policy, and Refund Policy to proceed.",
        position: "topCenter",
        timeout: 5000,
      });
      return;
    }

    // Implement your payment confirmation logic here (e.g., redirect to payment gateway)
    console.log("Payment confirmation logic would be implemented here.");
    navigate(`/payment/${event.eventId}`,{state:{ totalPrice, totalTickets,priceCurrency,eventName:event.name,eventDate:event.date,eventId:event.eventId,tickets,count }});
  };
  const handleTermsChange = (event) => {
    setIsTermsAccepted(event.target.checked);
  };
  


  return (
    <>
      <Navbar  />

      <div className="container-fluid contact">
        <div className="container contact-header">
          <h2>Hurry up !!</h2>
          <h1>Book Your Tickets Now</h1>
        </div>
      </div>

      <div className="container-fluid contact-content">
        <div className="container">
          <div className="booking-heading">
            <h1>Ticket Details</h1>
          </div>
          <div className="row book-row d-flex justify-content-between">
            <div className="col-lg-6">
              <div className="book-left">
                <h2>Date : {formatDate(event.date)}</h2>
                {/* <h2>Date : {new Date(event.date).toISOString().slice(0, -5) + 'Z'}</h2> */}
                {/* <h2>Date : {date}</h2> */}

                <h3>Venue : {event.venue}</h3>
                {tickets.map((ticket, index) => (
                  <div key={index}>
                    <h1>{ticket.typeName}</h1>
                    <div>
                      <li>
                        <h4>Details</h4> {ticket.comment}
                      </li>
                      <li>
                        <span>Price : </span> {ticket.priceCurrency} {ticket.ticketPrice}
                      </li>
                      <li>
                        <span>Booking Fee : </span>{/*ticket.bookingfeeCurrency*/} {ticket.priceCurrency} {ticket.bookingfeePrice}
                      </li>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-5">
              <div className="book-right">
                {tickets.map((ticket, index) => (
                  <div className="book-item" key={index}>
                    <div>
                      <h1>{ticket.typeName}</h1>
                      <h3>Price : {ticket.priceCurrency} {ticket.ticketPrice}</h3>
                      <h3>Booking Fee : {/*ticket.bookingfeeCurrency*/}{ticket.priceCurrency} {ticket.bookingfeePrice}</h3>
                    </div>
                    <div className="book-counter">
                      <span>
                        <i
                          className="bi bi-plus"
                          onClick={() => increaseCount(index)}
                        ></i>
                      </span>
                      <h3 className="px-3">{count[index]}</h3>
                      <span>
                        <i
                          className="bi bi-dash"
                          onClick={() => decreaseCount(index)}
                        ></i>
                      </span>
                    </div>
                  </div>
                ))}
                <div className="book-item pt-3">
                  <div>
                    <h2>Total Price : </h2>
                    <h2>Total Tickets : </h2>
                  </div>
                  <div>
                    <h2>{totalPrice.toFixed(2)}</h2>
                    <h2>{totalTickets}</h2>
                  </div>
                </div>
                <div className="form-check terms-check" >
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={handleTermsChange}/>
                  <label class="form-check-label" for="flexCheckDefault">
                  I've read and agree with the <Link to= "/terms" target="_blank" rel="noopener noreferrer">Terms & Condition</Link>,<Link to= "/privacy" target="_blank" rel="noopener noreferrer"> Privacy Policy</Link>, and <Link to= "/refund" target="_blank" rel="noopener noreferrer">Refund Policy</Link>.
                  </label>
                </div>
                
                {/* <div className="book-payment pt-3">
                  <h3>Our trusted payment partners :</h3>
                  <div className="payment-imgs">
                  <img src={`${process.env.PUBLIC_URL}/assets/master-card.png`} alt="" />
                  <img src={`${process.env.PUBLIC_URL}/assets/visa.png`} alt="" />
                  <img src={`${process.env.PUBLIC_URL}/assets/paypal.png`} alt="" />
                  <img src={`${process.env.PUBLIC_URL}/assets/maestro.png`} alt="" />
                  </div>
                </div> */}
                <div className="book-button">
                  <button onClick={handleConfirmPayment}  >
                    <Link
                      // to={`/payment/${event.eventId}`}
                      // state={{ totalPrice, totalTickets,priceCurrency,eventName:event.name,eventDate:event.date,eventId:event.eventId,tickets,count }}
                      className="text-decoration-none text-white"
                    >
                      Confirm Payment
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Booking;


