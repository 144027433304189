// // import React from "react";
// // import Navbar from "./Navbar";
// // import { Link } from "react-router-dom";

// // const Forgot = () => {

// //   return (
// //     <>
// //       <Navbar />

// //       <div className="container-fluid signin">
// //         <div className="container">
// //           <div className="signin-form">
// //             <div className="row">
// //               <div className="col-lg-6 my-auto">
// //                 <div className="signin-left">
// //                   <h1>Forgot Password ?</h1>
// //                   <p>
// //                   Don’t Worry we can fix that in a jiffy
// //                   </p>
// //                 </div>
// //               </div>
// //               <div className="col-lg-6">
// //                 <div className="signin-right">
// //                   <form action="/">
// //                     <div className="sign-item">
// //                       <label htmlFor="name">Email</label>
// //                       <input type="text" placeholder="Email" />
// //                     </div>
                    
// //                     <div className="signin-button">
// //                         <Link to="/reset">
// //                         <button>Continue</button>
// //                         </Link>
// //                     </div>
// //                   </form>
// //                   <h6>Remembered Your Password ? <Link to="/signin" className="text-decoration-none"><span>Login Here</span></Link></h6>
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </>
// //   );
// // };

// // export default Forgot;

import React, { useState } from "react";
import Navbar from "./Navbar";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

const Forgot = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      iziToast.error({ title: 'Error', message: 'Please enter your email',position:"topCenter" });
      return;
    }

    try {
      // await axios.post('http://localhost:4000/api/v1/mail/forgot-password', { email });
      await axios.post('https://backend.eurofestia.com/api/v1/mail/forgot-password', { email });

      localStorage.setItem("resetEmail", email); // Store email for reset step
      iziToast.success({ title: 'Success', message: 'OTP sent to your email',position:"topCenter" });
      navigate('/verify-otp');  // Redirect to the OTP verification page
    } catch (error) {
      iziToast.error({ title: 'Error', message: "User not found",position:"topCenter" });
    }
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid signin">
        <div className="container">
          <div className="signin-form">
            <div className="row">
              <div className="col-lg-6 my-auto">
                <div className="signin-left">
                  <h1>Forgot Password?</h1>
                  <p>Don’t Worry we can fix that in a jiffy</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="signin-right">
                  <form onSubmit={handleSubmit}>
                    <div className="sign-item">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="signin-button">
                      <button type="submit">Continue</button>
                    </div>
                  </form>
                  <h6>
                    Remembered Your Password? <Link to="/signin" className="text-decoration-none"><span>Login Here</span></Link>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgot;
