// // // import React, { useEffect, useRef } from "react";
// // // import Navbar from "./Navbar";
// // // import Footer from "./Footer";
// // // import { Link } from "react-router-dom";
// // // import Slider from "react-slick";

// // // const Details = () => {
// // //   useEffect(() => {
// // //     window.scrollTo({
// // //       top: 0,
// // //       left: 0,
// // //       behavior: "auto",
// // //     });
// // //   }, []);

// // //   const sliderRef = useRef();

// // //   const settings = {
// // //     dots: true,
// // //     infinite: true,
// // //     speed: 500,
// // //     margin: 10,
// // //     slidesToShow: 4,
// // //     slidesToScroll: 1,
// // //     arrows: false,
// // //     responsive: [
// // //       {
// // //         breakpoint: 1525,
// // //         settings: {
// // //           slidesToShow: 4,
// // //           slidesToScroll: 1,
// // //         },
// // //       },
// // //       {
// // //         breakpoint: 1000,
// // //         settings: {
// // //           slidesToShow: 3,
// // //           slidesToScroll: 1,
// // //         },
// // //       },
// // //       {
// // //         breakpoint: 769,
// // //         settings: {
// // //           slidesToShow: 2,
// // //           slidesToScroll: 1,
// // //         },
// // //       },
// // //       {
// // //         breakpoint: 500,
// // //         settings: {
// // //           slidesToShow: 1,
// // //           slidesToScroll: 1,
// // //         },
// // //       },
// // //     ],
// // //   };

// // //   const slideRight = () => {
// // //     sliderRef.current.slickNext();
// // //   };

// // //   const slideLeft = () => {
// // //     sliderRef.current.slickPrev();
// // //   };

// // //   return (
// // //     <>
// // //       <Navbar />

// // //       <div className="container-fluid detail">
// // //         <div className="detail-header">
// // //           <h3>5-15 May</h3>
// // //           <h1>Coachella 2024</h1>
// // //           <h2>Enjoy this Melody in the Desert</h2>
// // //         </div>
// // //       </div>

// // //       <div className="container-fluid details">
// // //         <div className="container detail-p">
// // //           <p>
// // //             A desert oasis of music and art, Where dreams are woven in beats and
// // //             stars. Under the blazing sun, crowds unite,In a kaleidoscope of
// // //             sound and light. With its iconic ferris wheel, kaleidoscopic
// // //             sunsets, and star-studded performances, Coachella has become a
// // //             symbol of youth culture and artistic expression, drawing crowds from
// // //             around the globe to celebrate life, music, and community in the
// // //             heart of the desert.
// // //           </p>
// // //         </div>

// // //         <div className="attract">
// // //           <div className="attract-left col-lg-4">Main Attraction</div>
// // //           <div className="attract-right">
// // //             <li>
// // //               <img src="assets/solar_star-shine-bold.png" alt="" /> Diverse
// // //               lineup of musical acts spanning various genres
// // //             </li>
// // //             <li>
// // //               <img src="assets/solar_star-shine-bold.png" alt="" /> Diverse
// // //               lineup of musical acts spanning various genres
// // //             </li>
// // //             <li>
// // //               <img src="assets/solar_star-shine-bold.png" alt="" /> Diverse
// // //               lineup of musical acts spanning various genres
// // //             </li>
// // //             <li>
// // //               <img src="assets/solar_star-shine-bold.png" alt="" /> Diverse
// // //               lineup of musical acts spanning various genres
// // //             </li>
// // //           </div>
// // //         </div>

// // //         <div className="container-fluid artists">
// // //           <div className="event-h">
// // //             <h1>Artists Performing</h1>
// // //           </div>
// // //           <div className="artist-cards">
// // //             <div className="arrow-right" onClick={slideRight}>
// // //               <span>
// // //                 <i className="bi bi-arrow-right"></i>
// // //               </span>
// // //             </div>
// // //             <div className="arrow-left" onClick={slideLeft}>
// // //               <span>
// // //                 <i className="bi bi-arrow-left"></i>
// // //               </span>
// // //             </div>
// // //             <Slider ref={sliderRef} {...settings}>
// // //               <div className="artist-card1 ">
// // //                 <div className="artist-card">
// // //                   <img src="assets/BLACKPINK.jpg" alt="" />
// // //                   <span className="overlay">
// // //                     <h1>Blackpink</h1>
// // //                     <p>
// // //                       {" "}
// // //                       The South Korean girl group sensation, captivates with
// // //                       their fierce charisma, dynamic performances, and
// // //                       chart-topping hits, redefining K-pop with their global
// // //                       influence.
// // //                     </p>
// // //                   </span>
// // //                 </div>
// // //               </div>
// // //               <div className="artist-card1 ">
// // //                 <div className="artist-card">
// // //                   <img src="assets/Diljit Dosanjh.jpg" alt="" />
// // //                   <span className="overlay">
// // //                     <h1>Diljit Dosanjh</h1>
// // //                     <p>
// // //                       {" "}
// // //                       The South Korean girl group sensation, captivates with
// // //                       their fierce charisma, dynamic performances, and
// // //                       chart-topping hits, redefining K-pop with their global
// // //                       influence.
// // //                     </p>
// // //                   </span>
// // //                 </div>
// // //               </div>
// // //               <div className="artist-card1 ">
// // //                 <div className="artist-card">
// // //                   <img src="assets/BLACKPINK.jpg" alt="" />
// // //                   <span className="overlay">
// // //                     <h1>Blackpink</h1>
// // //                     <p>
// // //                       {" "}
// // //                       The South Korean girl group sensation, captivates with
// // //                       their fierce charisma, dynamic performances, and
// // //                       chart-topping hits, redefining K-pop with their global
// // //                       influence.
// // //                     </p>
// // //                   </span>
// // //                 </div>
// // //               </div>
// // //               <div className="artist-card1 ">
// // //                 <div className="artist-card">
// // //                   <img src="assets/Diljit Dosanjh.jpg" alt="" />
// // //                   <span className="overlay">
// // //                     <h1>Diljit Dosanjh</h1>
// // //                     <p>
// // //                       {" "}
// // //                       The South Korean girl group sensation, captivates with
// // //                       their fierce charisma, dynamic performances, and
// // //                       chart-topping hits, redefining K-pop with their global
// // //                       influence.
// // //                     </p>
// // //                   </span>
// // //                 </div>
// // //               </div>
// // //               <div className="artist-card1 ">
// // //                 <div className="artist-card">
// // //                   <img src="assets/Diljit Dosanjh.jpg" alt="" />
// // //                   <span className="overlay">
// // //                     <h1>Diljit Dosanjh</h1>
// // //                     <p>
// // //                       {" "}
// // //                       The South Korean girl group sensation, captivates with
// // //                       their fierce charisma, dynamic performances, and
// // //                       chart-topping hits, redefining K-pop with their global
// // //                       influence.
// // //                     </p>
// // //                   </span>
// // //                 </div>
// // //               </div>
// // //             </Slider>
// // //           </div>
// // //         </div>

// // //         <div className="container event-detail">
// // //           <div className="event-h">
// // //             <h1>Details Of Event</h1>
// // //           </div>
// // //           <div className="event-c row">
// // //             <div className="col-lg-4">
// // //               <h3>Event</h3>
// // //               <li>Duration : 5-15 May</li>
// // //               <li>Category : Festival</li>
// // //               <li>Capacity : 125,000</li>
// // //               <li>
// // //                 Website :{" "}
// // //                 <Link className="text-decoration-none text-white">
// // //                   www.coachella.com
// // //                 </Link>
// // //               </li>
// // //             </div>
// // //             <div className="col-lg-4">
// // //               <h3>Organizer</h3>
// // //               <li>Golden Voice</li>
// // //             </div>
// // //             <div className="col-lg-4">
// // //               <h3>Venue</h3>
// // //               <li>Empire Polo Club</li>
// // //             </div>
// // //           </div>
// // //         </div>

// // //         <div className="container tickets">
// // //           <div className="event-h">
// // //             <h1>Tickets</h1>
// // //           </div>

// // //           <div className="col-lg-10 row mx-auto">
// // //             <div className="col-lg-4 p-4">
// // //               <div className="ticket-card">
// // //                 <h6>Regular</h6>
// // //                 <h1>VIEWING PLATFORM</h1>
// // //                 <h2>VIEWING PLATFORM AT THE MAIN STAGE</h2>
// // //                 <p>
// // //                   This raised platform is designed for wheelchair-users and
// // //                   those with access needs for whom the Ground Level Viewing area
// // //                   is not suitable.
// // //                 </p>
// // //                 <div className="d-flex justify-content-center">
// // //                   <Link to="/booking">
// // //                     <button>Buy Now</button>
// // //                   </Link>
// // //                 </div>
// // //               </div>
// // //             </div>
// // //             <div className="col-lg-4 p-4">
// // //               <div className="ticket-card">
// // //                 <h6>Regular</h6>
// // //                 <h1>VIEWING PLATFORM</h1>
// // //                 <h2>VIEWING PLATFORM AT THE MAIN STAGE</h2>
// // //                 <p>
// // //                   This raised platform is designed for wheelchair-users and
// // //                   those with access needs for whom the Ground Level Viewing area
// // //                   is not suitable.
// // //                 </p>
// // //                 <div className="d-flex justify-content-center">
// // //                   <Link to="/booking">
// // //                     <button>Buy Now</button>
// // //                   </Link>
// // //                 </div>
// // //               </div>
// // //             </div>
// // //             <div className="col-lg-4 p-4">
// // //               <div className="ticket-card">
// // //                 <h6>Regular</h6>
// // //                 <h1>VIEWING PLATFORM</h1>
// // //                 <h2>VIEWING PLATFORM AT THE MAIN STAGE</h2>
// // //                 <p>
// // //                   This raised platform is designed for wheelchair-users and
// // //                   those with access needs for whom the Ground Level Viewing area
// // //                   is not suitable.
// // //                 </p>
// // //                 <div className="d-flex justify-content-center">
// // //                   <Link to="/booking">
// // //                     <button>Buy Now</button>
// // //                   </Link>
// // //                 </div>
// // //               </div>
// // //             </div>
// // //           </div>
// // //           <div className="ticket-button">
// // //             <Link to="/booking">
// // //               <button>View All</button>
// // //             </Link>
// // //           </div>
// // //         </div>

// // //         <div className="attract facilities">
// // //           <div className="attract-right">
// // //             <li>
// // //               <img src="assets/solar_star-shine-bold.png" alt="" /> Food vendors
// // //               offering diverse cuisines.
// // //             </li>
// // //             <li>
// // //               <img src="assets/solar_star-shine-bold.png" alt="" />{" "}
// // //               Accommodation options include camping, nearby hotels, and
// // //               glamping.
// // //             </li>
// // //             <li>
// // //               <img src="assets/solar_star-shine-bold.png" alt="" /> Festival
// // //               guides and maps help navigate the venue and schedules.
// // //             </li>
// // //             <li>
// // //               <img src="assets/solar_star-shine-bold.png" alt="" /> On-site
// // //               medical services and security ensure attendee safety.
// // //             </li>
// // //           </div>
// // //           <div className="facilities-right col-lg-4">Facilities</div>
// // //         </div>
// // //       </div>

// // //       <Footer />
// // //     </>
// // //   );
// // // };

// // // export default Details;

// // import React, { useEffect, useState, useRef } from "react";
// // import { useParams, Link } from "react-router-dom";
// // import Navbar from "./Navbar";
// // import Footer from "./Footer";
// // import Slider from "react-slick";
// // import axios from "axios";

// // const Details = () => {
// //   const { eventId } = useParams();
// //   const [event, setEvent] = useState(null);
// //   const sliderRef = useRef();  // Define sliderRef here
// //   const [loading, setLoading] = useState(true);

// //   useEffect(() => {
// //     const fetchEventDetails = async () => {
// //       try {
// //         const response = await axios.get(
// //           `https://app.ticketmaster.com/discovery/v2/events/${eventId}.json?apikey=VgRLAbfHW62DJTQwk8TTumOFGOWVLRsw`
// //         );
// //         setEvent(response.data);
// //         // console(response.data);
// //         setLoading(false);

// //       } catch (error) {
// //         console.error("Error fetching event details:", error);
// //         setLoading(false);
// //       }
// //     };

// //     fetchEventDetails();
// //   }, [eventId]);

// //   if (loading) {
// //     return <div>Loading...</div>;
// //   }

// //   if (!event) {
// //     return <div>Loading...</div>;
// //   }

// //   const settings = {
// //     dots: true,
// //     infinite: true,
// //     speed: 500,
// //     margin: 10,
// //     slidesToShow: 4,
// //     slidesToScroll: 1,
// //     arrows: false,
// //     responsive: [
// //       {
// //         breakpoint: 1525,
// //         settings: {
// //           slidesToShow: 4,
// //           slidesToScroll: 1,
// //         },
// //       },
// //       {
// //         breakpoint: 1000,
// //         settings: {
// //           slidesToShow: 3,
// //           slidesToScroll: 1,
// //         },
// //       },
// //       {
// //         breakpoint: 769,
// //         settings: {
// //           slidesToShow: 2,
// //           slidesToScroll: 1,
// //         },
// //       },
// //       {
// //         breakpoint: 500,
// //         settings: {
// //           slidesToShow: 1,
// //           slidesToScroll: 1,
// //         },
// //       },
// //     ],
// //   };

// //   return (
// //     <>
// //       <Navbar />
// //       <div className="container-fluid detail">
// //         <div className="detail-header">
// //           <h3>{new Date(event.dates.start.localDate).toLocaleDateString()}</h3>
// //           <h1>{event.name}</h1>
// //           <h2>{event._embedded.venues[0].name}</h2>
// //         </div>
// //       </div>

// //       <div className="container-fluid details">
// //         <div className="container detail-p">
// //           <p>{event.info}</p>
// //         </div>

// //         <div className="attract">
// //           <div className="attract-left col-lg-4">Main Attraction</div>
// //           <div className="attract-right">
// //             <li>
// //               <img src="assets/solar_star-shine-bold.png" alt="" /> {event.pleaseNote}
// //             </li>
// //           </div>
// //         </div>

// //         <div className="container-fluid artists">
// //           <div className="event-h">
// //             <h1>Artists Performing</h1>
// //           </div>
// //           <div className="artist-cards">
// //             <div className="arrow-right" onClick={() => sliderRef.current.slickNext()}>
// //               <span>
// //                 <i className="bi bi-arrow-right"></i>
// //               </span>
// //             </div>
// //             <div className="arrow-left" onClick={() => sliderRef.current.slickPrev()}>
// //               <span>
// //                 <i className="bi bi-arrow-left"></i>
// //               </span>
// //             </div>
// //             <Slider ref={sliderRef} {...settings}>
// //               {event._embedded.attractions.map((artist) => (
// //                 <div className="artist-card1 " key={artist.id}>
// //                   <div className="artist-card">
// //                     <img src={artist.images[0].url} alt={artist.name} />
// //                     <span className="overlay">
// //                       <h1>{artist.name}</h1>
// //                       <p>{artist.description || 'No description available.'}</p>
// //                     </span>
// //                   </div>
// //                 </div>
// //               ))}
// //             </Slider>
// //           </div>
// //         </div>

// //         <div className="container event-detail">
// //           <div className="event-h">
// //             <h1>Details Of Event</h1>
// //           </div>
// //           <div className="event-c row">
// //             <div className="col-lg-4">
// //               <h3>Event</h3>
// //               <li>Duration: {event.dates.start.localDate}</li>
// //               <li>Category: {event.classifications[0].segment.name}</li>
// //               <li>Capacity: {event.seatmap.staticUrl ? 'Unknown' : 'Capacity not available'}</li>
// //               <li>
// //                 Website:{" "}
// //                 <Link to={event.url} className="text-decoration-none text-white">
// //                   {event.url}
// //                 </Link>
// //               </li>
// //             </div>
// //             <div className="col-lg-4">
// //               <h3>Organizer</h3>
// //               <li>{event.promoter ? event.promoter.name : 'Unknown'}</li>
// //             </div>
// //             <div className="col-lg-4">
// //               <h3>Venue</h3>
// //               <li>{event._embedded.venues[0].name}</li>
// //             </div>
// //           </div>
// //         </div>

// //         <div className="container tickets">
// //           <div className="event-h">
// //             <h1>Tickets</h1>
// //           </div>

// //           <div className="col-lg-10 row mx-auto">
// //             <div className="col-lg-4 p-4">
// //               <div className="ticket-card">
// //                 <h6>Regular</h6>
// //                 <h1>VIEWING PLATFORM</h1>
// //                 <h2>VIEWING PLATFORM AT THE MAIN STAGE</h2>
// //                 <p>
// //                   This raised platform is designed for wheelchair-users and
// //                   those with access needs for whom the Ground Level Viewing area
// //                   is not suitable.
// //                 </p>
// //                 <div className="d-flex justify-content-center">
// //                   <Link to="/booking">
// //                     <button>Buy Now</button>
// //                   </Link>
// //                 </div>
// //               </div>
// //             </div>
// //             <div className="col-lg-4 p-4">
// //               <div className="ticket-card">
// //                 <h6>Regular</h6>
// //                 <h1>VIEWING PLATFORM</h1>
// //                 <h2>VIEWING PLATFORM AT THE MAIN STAGE</h2>
// //                 <p>
// //                   This raised platform is designed for wheelchair-users and
// //                   those with access needs for whom the Ground Level Viewing area
// //                   is not suitable.
// //                 </p>
// //                 <div className="d-flex justify-content-center">
// //                   <Link to="/booking">
// //                     <button>Buy Now</button>
// //                   </Link>
// //                 </div>
// //               </div>
// //             </div>
// //             <div className="col-lg-4 p-4">
// //               <div className="ticket-card">
// //                 <h6>Regular</h6>
// //                 <h1>VIEWING PLATFORM</h1>
// //                 <h2>VIEWING PLATFORM AT THE MAIN STAGE</h2>
// //                 <p>
// //                   This raised platform is designed for wheelchair-users and
// //                   those with access needs for whom the Ground Level Viewing area
// //                   is not suitable.
// //                 </p>
// //                 <div className="d-flex justify-content-center">
// //                   <Link to="/booking">
// //                     <button>Buy Now</button>
// //                   </Link>
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //           <div className="ticket-button">
// //             <Link to="/booking">
// //               <button>View All</button>
// //             </Link>
// //           </div>
// //         </div>

// //         <div className="attract facilities">
// //           <div className="attract-right">
// //             <li>
// //               <img src="assets/solar_star-shine-bold.png" alt="" /> Food vendors
// //               offering diverse cuisines.
// //             </li>
// //             <li>
// //               <img src="assets/solar_star-shine-bold.png" alt="" />{" "}
// //               Accommodation options include camping, nearby hotels, and
// //               glamping.
// //             </li>
// //             <li>
// //               <img src="assets/solar_star-shine-bold.png" alt="" /> Festival
// //               guides and maps help navigate the venue and schedules.
// //             </li>
// //             <li>
// //               <img src="assets/solar_star-shine-bold.png" alt="" /> On-site
// //               medical services and security ensure attendee safety.
// //             </li>
// //           </div>
// //           <div className="facilities-right col-lg-4">Facilities</div>
// //         </div>
// //       </div>

// //       <Footer />
// //     </>
// //   );
// // };

// // export default Details;

import React, { useEffect, useState, useRef } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useParams,useNavigate } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import iziToast from "izitoast";


const Details = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [localevent, setlocalevent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const sliderRef = useRef();
  const navigate=useNavigate();


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(
          `https://app.ticketmaster.com/discovery/v2/events/${eventId}.json?apikey=VgRLAbfHW62DJTQwk8TTumOFGOWVLRsw`
        );
        setEvent(response.data);
        const localresponse = await axios.get(
          `https://backend.eurofestia.com/api/v1/events/event/${eventId}`
          // `http://localhost:4000/api/v1/events/event/${eventId}`

        );
        setlocalevent(localresponse.data);
        console.log("localevent", localresponse.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [eventId]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    margin: 10,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1525,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slideRight = () => {
    sliderRef.current.slickNext();
  };

  const slideLeft = () => {
    sliderRef.current.slickPrev();
  };

  if (loading) {
    return (
      <div className="loading-bg">
        <div className="loader"></div>
        <h3>You are almost there...</h3>
      </div>
    );
  }

  if (error) {
    return (<div className="loading-bg">
      <div className="error"></div>
      <h3>Error Fetching the Details</h3>
    </div>);
  }

  if (!event) {
    return <div>No event details available</div>;
  }
  const attractions = event._embedded?.attractions || [];

  const defaultAttraction = {
    name: localevent?.event[0]?.attraction || "Unknown Attraction",
    images: [{ url: localevent?.event[0]?.imageUrl }],
    info: localevent?.event[0]?.info || "No additional info available",
  };
  if (attractions.length===0){
    // attractions.images[0].url=defaultAttraction.images[0].url,
    // attractions.name=defaultAttraction.name
    attractions.push(defaultAttraction)

  }
  console.log(defaultAttraction)
  // const token=localStorage.getItem('token');
  const handleBookingClick =()=> {
    const userid = localStorage.getItem('userid');

    if (userid) {
        navigate(`/booking/${localevent.event[0]?.eventId}`);
    } else {
      iziToast.error({
            title: 'Error',
            message: 'Please login to continue',
            position: 'topCenter'
          });
          navigate('/signin');
          return; // Prevent further execution if user is not logged in
        // navigate('/signin');
      }
};

  return (
    <>
      <Navbar />
{/* 
      <div className="container-fluid events">
        <div className="detail-header">
          <h3>{event.dates.start.localDate}</h3>
          <h1>{event.name}</h1>
          <h2>{event.pleaseNote}</h2>
        </div>
      </div> */}
        <div className="container-fluid events">
        <div className="row">
          <div className="detail-header">
          <h3>{event.dates.start.localDate}</h3>
          <h1>{event.name}</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid details">
        <div className="container detail-p">
          <p>
            {/* {localevent.event[0].info || "Event details not available."} */}
            <div
              dangerouslySetInnerHTML={{
                __html:
                  localevent.event[0]?.info || "Event details not available.",
              }}
            />
          </p>
        </div>

        {/* <div className="attract">
          <div className="attract-left col-lg-4">Main Attraction</div>
          <div className="attract-right">
            {event._embedded?.attractions?.map((attraction, index) => (
              <li key={index}>
                <img src="assets/solar_star-shine-bold.png" alt="" />
                {attraction.name ||
                  localevent?.event[0]?.attraction ||
                  "Unknown Attraction"}
              </li>
            ))}
          </div>
        </div> */}
        
        <div className="attract">
          <div className="attract-left col-lg-4">Main Attraction</div>
          <div className="attract-right">
            {attractions.length > 0 ? (
              attractions.map((attraction, index) => (
                <li key={index}>
                  <img src="assets/solar_star-shine-bold.png" alt="" />
                  {attraction.name || defaultAttraction.name}
                </li>
              ))
            ) : (
              <li>
                <img src="assets/solar_star-shine-bold.png" alt="" />
                {defaultAttraction.name}
              </li>
            )}
          </div>
        </div>

        {/*<div className="container-fluid artists">
          <div className="event-h">
            <h1>Artists Performing</h1>
          </div>
          <div className="artist-cards">
            <div className="arrow-right" onClick={slideRight}>
              <span>
                <i className="bi bi-arrow-right"></i>
              </span>
            </div>
            <div className="arrow-left" onClick={slideLeft}>
              <span>
                <i className="bi bi-arrow-left"></i>
              </span>
            </div>
            <Slider ref={sliderRef} {...settings}>
              {event._embedded && event._embedded?.attractions?.map((attraction, index) => (
                <div className="artist-card1" key={index}>
                  <div className="artist-card">
                    <img src={attraction.images[0]?.url} alt={attraction.name || (localevent?.event[0]?.attraction || "Unknown Attraction")} />
                    <span className="overlay">
                      <h1>{attraction.name}</h1>
                      <p>{attraction.info}</p>
                    </span>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>*/}
        <div className="container-fluid artists">
          <div className="event-h">
            <h1>Artists Performing</h1>
          </div>
          <div className="artist-cards">
            
            {attractions.length > 1 ? (
              <>
                <div className="arrow-right" onClick={slideRight}>
                  <span>
                    <i className="bi bi-arrow-right"></i>
                  </span>
                </div>
                <div className="arrow-left" onClick={slideLeft}>
                  <span>
                    <i className="bi bi-arrow-left"></i>
                  </span>
                </div>
                <Slider ref={sliderRef} {...settings}>
                  {attractions.map((attraction, index) => (
                    <div className="artist-card1" key={index}>
                      <div className="artist-card">
                        <img
                          src={attraction.images[0]?.url}
                          alt={attraction.name}
                        />
                        <span className="overlay">
                          <h1>{attraction.name}</h1>
                          <p>{attraction.info}</p>
                        </span>
                      </div>
                    </div>
                  ))}
                </Slider>
              </>
            ) : (
              attractions.map((attraction, index) => (
                <div className="artist-card1" key={index}>
                  <div className="artist-card single-card">
                    <img
                      src={attraction.images[0]?.url}
                      alt={attraction.name}
                    />
                    <span className="overlay">
                      <h1>{attraction.name}</h1>
                      {/* <p>{attraction.info}</p> */}
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div className="container event-detail">
          <div className="event-h">
            <h1>Details Of Event</h1>
          </div>
          <div className="event-c row">
            <div className="col-lg-4">
              <h3>Event</h3>
              <li>Date : {event.dates.start.localDate}</li>
              <li>Time:{event.dates.start.localTime}</li>
              <li>
                Category : {event.classifications[0].segment?.name},
                {event.classifications[0].genre?.name}
              </li>
              {/* <li>Capacity : {event.info || "N/A"}</li> */}
              {/* <li>
                Website :{" "}
                <a href={event.url} className="text-decoration-none text-white" target="_blank" rel="noopener noreferrer">
                  {event.url}
                </a>
              </li> */}
            </div>
            <div className="col-lg-4">
              <h3>Organizer</h3>
              <li>
                {event.promoter
                  ? event.promoter.name
                  : localevent.event[0].promoter}
              </li>
            </div>
            <div className="col-lg-4">
              <h3>Venue</h3>
              <li>{event._embedded.venues[0].name}</li>
              <li>
                {event._embedded.venues[0].city.name},
                {event._embedded.venues[0].country.name}
              </li>
            </div>
          </div>
        </div>

        <div className="container tickets">
          <div className="event-h">
            <h1>Tickets</h1>
          </div>

          <div className="row justify-content-center">
            {/* {event.priceRanges && event.priceRanges.map((price, index) => ( */}
            <div className="col-lg-4 p-4">
              <div className="ticket-card">
                <h6>Tickets starting from</h6>
                {/* <h6>{price.type}</h6> */}
                <h1>
                  {localevent.event[0]?.currency}{" "}
                  {localevent.event[0]?.minprice} -{" "}
                  {localevent.event[0]?.maxprice}
                </h1>
                <p>{localevent.event[0]?.ticketdetails}</p>
                <div className="d-flex justify-content-center">
                  {/* <Link to={`/booking/${localevent.event[0]?.eventId}`}> */}
                    <button onClick={handleBookingClick}>Buy Now</button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
            {/*))}*/}
          </div>
          {/*<div className="ticket-button">
            {/* <Link to={`/booking/${localevent.event[0]?.eventId}`}> }
              <button onClick={handleBookingClick}>View All</button>
            {/* </Link> }
          </div>*/}
        </div>

        <div className="attract facilities">
          <div className="attract-right">
            {/* {localevent.event[0].facilities} */}
            <div
              dangerouslySetInnerHTML={{
                __html: localevent.event[0]?.facilities,
              }}
            />
          </div>
          <div className="facilities-right col-lg-4">Facilities</div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Details;
