import React, { useState } from "react";
import Navbar from "./Navbar";
import { useNavigate,Link } from "react-router-dom";
import axios from "axios";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

const VerifyOTP = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = localStorage.getItem("resetEmail");
    if (!otp || !email) {
      iziToast.error({ title: 'Error', message: 'Please enter the OTP',position:"topCenter"});
      return;
    }

    try {
      // await axios.post('http://localhost:4000/api/v1/mail/verify-otp', { email, otp });
            await axios.post('https://backend.eurofestia.com/api/v1/mail/verify-otp', { email, otp });

      iziToast.success({ title: 'Success', message: 'OTP verified successfully',position:"topCenter"});
      navigate('/reset');  // Redirect to the reset password page
    } catch (error) {
      iziToast.error({ title: 'Error', message:"Enter Valid OTP",position:"topCenter" });
    }
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid signin">
        <div className="container">
          <div className="signin-form">
            <div className="row">
              <div className="col-lg-6 my-auto">
                <div className="signin-left">
                  <h1>Verify OTP</h1>
                  <p>Please enter the OTP sent to your email.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="signin-right">
                  <form onSubmit={handleSubmit}>
                    <div className="sign-item">
                      <label htmlFor="otp">OTP</label>
                      <input
                        type="text"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </div>
                    <div className="signin-button">
                      <button type="submit">Verify</button>
                    </div>
                  </form>
                  <h6>
                    Remembered Your Password? <Link to="/signin" className="text-decoration-none"><span>Login Here</span></Link>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOTP;
