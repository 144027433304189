// import React, { useEffect, useState, useRef } from "react";
// import axios from "axios";
// import Sidebar from "./Sidebar";
// import $ from "jquery";
// import { Modal } from "react-bootstrap";

// const UserManagement = () => {
//   const dataTableRef = useRef();
//   const [users, setUsers] = useState([]);
//   const [showEdit, setShowEdit] = useState(false);
//   const [showDelete, setShowDelete] = useState(false);
//   const [currentUser, setCurrentUser] = useState(null);
//   const [userData, setUserData] = useState({
//     firstname: "",
//     lastname: "",
//     phone: "",
//     email: "",
//     address: "",
//     city: "",
//     country: "",
//     postalcode: "",
//   });
//   useEffect(() => {
//     const fetchUsers = async () => {
//       try {
//         const response = await axios.get("https://backend.eurofestia.com/api/v1/users/details");
//         console.log("Fetched users:", response.data);
//         if (Array.isArray(response.data.user)) {
//           setUsers(response.data.user);
//           console.log(response.data)
//         } else {
//           console.error("Unexpected response format:", response.data);
//         }
//       } catch (error) {
//         console.error("Error fetching users:", error);
//       }
//     };

//     fetchUsers();
//   }, []);

//   const handleShowEdit = (user) => {
//     setCurrentUser(user);
//     setUserData(user);
//     setShowEdit(true);
//   };

//   const handleCloseEdit = () => {
//     setShowEdit(false);
//     setCurrentUser(null);
//   };

//   const handleShowDelete = (user) => {
//     setCurrentUser(user);
//     setShowDelete(true);
//     handleDelete();
//   };

//   const handleCloseDelete = () => {
//     setShowDelete(false);
//     setCurrentUser(null);
//   };
//   const handleDelete = async () => {
//     if (currentUser) {
//       try {
//         // console.log(currentUser)
//         await axios.delete(`https://backend.eurofestia.com/api/v1/users/user/:${currentUser._id}`);
//         setUsers(users.filter(user => user._id !== currentUser._id));
//         handleCloseDelete();
//       } catch (error) {
//         console.error("Error deleting user:", error);
//       }
//     }
//   };

//   useEffect(() => {
//     if (users.length > 0) {
//       $(dataTableRef.current).DataTable();
//     }
//   }, [users]);

//   return (
//     <>
//       <Sidebar />
//       <Modal show={showEdit} onHide={handleCloseEdit} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>User Details</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="modal-content1">
//             <form>
//               <div className="modal-box">
//                 <label htmlFor="">First name</label>
//                 <input type="text" placeholder="First Name" value={userData.firstname} disabled />
//               </div>
//               <div className="modal-box">
//                 <label htmlFor="">Last name</label>
//                 <input type="text" placeholder="Last Name" value={userData.lastname} disabled />
//               </div>
//               <div className="modal-box">
//                 <label htmlFor="">Email</label>
//                 <input type="text" placeholder="Email" value={userData.email} disabled />
//               </div>
//               <div className="modal-box">
//                 <label htmlFor="">Phone No.</label>
//                 <input type="text" placeholder="Phone number" value={userData.phone} disabled />
//               </div>
//               <div className="modal-box">
//                 <label htmlFor="">Address</label>
//                 <input type="text" placeholder="Address" value={userData.address} disabled />
//               </div>
//               <div className="modal-box">
//                 <label htmlFor="">City</label>
//                 <input type="text" placeholder="City" value={userData.city} disabled />
//               </div>
//               <div className="modal-box">
//                 <label htmlFor="">Country</label>
//                 <input type="text" placeholder="Country" value={userData.country} disabled />
//               </div>
//             </form>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <button className="find-event" onClick={handleCloseEdit}>
//             Close
//           </button>
//         </Modal.Footer>
//       </Modal>
//       <Modal show={showDelete} onHide={handleCloseDelete} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Delete User</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="modal-content1">
//             <p>Do you want to delete this user?</p>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <button className="find-event-delete" onClick={handleCloseDelete}>
//             Delete User
//           </button>
//           <button className="find-event" onClick={handleCloseDelete}>
//             Cancel
//           </button>
//         </Modal.Footer>
//       </Modal>
//       <div className="home">
//         <div className="toggle-sidebar" style={{ display: "none" }}>
//           <i className="bi bi-menu"></i>
//         </div>
//         <div className="dashboard">
//           <div className="dashboard-header">
//             <h1>User Management</h1>
//           </div>
//           <div className="container-fluid">
//             <div className="row foot-tablerow">
//               <div className="col-lg-11 maintable-column mx-auto">
//                 <div className="container mt-4 overflow-auto">
//                   <table id="datatable" className="table" ref={dataTableRef}>
//                     <thead>
//                       <tr className="tr1">
//                         <th>User Name</th>
//                         <th>Email Id</th>
//                         <th>Phone</th>
//                         <th>Address</th>
//                         <th>City</th>
//                         <th>Country</th>
//                         <th>Actions</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {users.map((user) => (
//                         <tr key={user._id}>
//                           <td>{user.firstname}{""}{user.lastname}</td>
//                           <td className="ellipsis">
//                             <abbr className="text-decoration-none me-4" title={user.email}>
//                               {user.email}
//                             </abbr>
//                           </td>
//                           <td>{user.phone}</td>
//                           <td className="ellipsis">
//                             <abbr className="text-decoration-none me-4" title={user.address}>
//                               {user.address}
//                             </abbr>
//                           </td>
//                           <td>{user.city}</td>
//                           <td>{user.country}</td>
//                           <td className="td1">
//                             <i className="fa-regular fa-pen-to-square me-1" onClick={() => handleShowEdit(user)}></i> 
//                             <i className="fa-solid fa-trash" onClick={() => handleShowDelete(user)}></i>
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default UserManagement;


import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import $ from "jquery";
import { Modal } from "react-bootstrap";

const UserManagement = () => {
  const dataTableRef = useRef();
  const [users, setUsers] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    country: "",
    postalcode: "",
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("https://backend.eurofestia.com/api/v1/users/details");
        console.log("Fetched users:", response.data);
        if (Array.isArray(response.data.user)) {
          setUsers(response.data.user);
          console.log(response.data)
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleShowEdit = (user) => {
    setCurrentUser(user);
    setUserData(user);
    setShowEdit(true);
  };

  const handleCloseEdit = () => {
    setShowEdit(false);
    setCurrentUser(null);
  };

  const handleShowDelete = (user) => {
    setCurrentUser(user);
    setShowDelete(true);
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
    setCurrentUser(null);
  };

  const handleDelete = async () => {
    if (currentUser) {
      try {
        await axios.delete(`https://backend.eurofestia.com/api/v1/users/user/${currentUser._id}`);
        setUsers(users.filter(user => user._id !== currentUser._id));
        handleCloseDelete();
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  useEffect(() => {
    if (users.length > 0) {
      $(dataTableRef.current).DataTable();
    }
  }, [users]);

  return (
    <>
      <Sidebar />
      <Modal show={showEdit} onHide={handleCloseEdit} centered>
        <Modal.Header closeButton>
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content1">
            <form>
              <div className="modal-box">
                <label htmlFor="">First name</label>
                <input type="text" placeholder="First Name" value={userData.firstname} disabled />
              </div>
              <div className="modal-box">
                <label htmlFor="">Last name</label>
                <input type="text" placeholder="Last Name" value={userData.lastname} disabled />
              </div>
              <div className="modal-box">
                <label htmlFor="">Email</label>
                <input type="text" placeholder="Email" value={userData.email} disabled />
              </div>
              <div className="modal-box">
                <label htmlFor="">Phone No.</label>
                <input type="text" placeholder="Phone number" value={userData.phone} disabled />
              </div>
              <div className="modal-box">
                <label htmlFor="">Address</label>
                <input type="text" placeholder="Address" value={userData.address} disabled />
              </div>
              <div className="modal-box">
                <label htmlFor="">City</label>
                <input type="text" placeholder="City" value={userData.city} disabled />
              </div>
              <div className="modal-box">
                <label htmlFor="">Country</label>
                <input type="text" placeholder="Country" value={userData.country} disabled />
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="find-event" onClick={handleCloseEdit}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDelete} onHide={handleCloseDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content1">
            <p>Do you want to delete this user?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="find-event-delete" onClick={handleDelete}>
            Delete User
          </button>
          <button className="find-event" onClick={handleCloseDelete}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>User Management</h1>
          </div>
          <div className="container-fluid">
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column mx-auto">
                <div className="container mt-4 overflow-auto">
                  <table id="datatable" className="table" ref={dataTableRef}>
                    <thead>
                      <tr className="tr1">
                        <th>id</th>
                        <th>Full Name</th>
                        <th>Email Id</th>
                        <th>Phone</th>
                        <th>Address</th>
                        {/* <th>City</th> */}
                        <th>Country</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user) => (
                        <tr key={user._id}>
                          <td>{user._id}</td>
                          <td>{user.firstname}{""}{user.lastname}</td>
                          <td className="ellipsis">
                            <abbr className="text-decoration-none me-4" title={user.email}>
                              {user.email}
                            </abbr>
                          </td>
                          <td>{user.phone}</td>
                          <td className="ellipsis">
                            <abbr className="text-decoration-none me-4" title={user.address}>
                              {user.address}
                            </abbr>
                          </td>
                          {/* <td>{user.city}</td> */}
                          <td>{user.country}</td>
                          <td className="td1">
                            <i className="fa-regular fa-pen-to-square me-1" onClick={() => handleShowEdit(user)}></i> 
                            <i className="fa-solid fa-trash" onClick={() => handleShowDelete(user)}></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagement;
