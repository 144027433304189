// // // // // import React from "react";

// // // // // const Invoice = () => {
// // // // //   return (
// // // // //     <>
// // // // //       <div style={{ margin: "3%" }}>
// // // // //         <div
// // // // //           style={{
// // // // //             width: "66.6667%",
// // // // //             maxWidth: "100%",
// // // // //             margin: "0 auto",
// // // // //             border: "1px solid black",
// // // // //             padding: "2%",
// // // // //             borderRadius: "6px",
// // // // //           }}
// // // // //         >
// // // // //           <h2
// // // // //             style={{
// // // // //               fontFamily: "Poppins",
// // // // //               fontSize: "24px",
// // // // //               textAlign: "center",
// // // // //             }}
// // // // //           >
// // // // //             Eurofest
// // // // //           </h2>
// // // // //           <h4
// // // // //             style={{
// // // // //               fontFamily: "Poppins",
// // // // //               fontSize: "20px",
// // // // //               textAlign: "center",
// // // // //               color: "#656a78",
// // // // //             }}
// // // // //           >
// // // // //             Invoice Receipt
// // // // //           </h4>

// // // // //           <div style={{ display: "flex", justifyContent: "space-between" }}>
// // // // //             <div>
// // // // //               <h5 style={{ fontSize: "18px", fontFamily: "Inter" }}>
// // // // //                 Carlos Sainz
// // // // //               </h5>
// // // // //               <p style={{ fontSize: "15px", fontFamily: "Inter" }}>
// // // // //                 <b>Mobile no. : </b>+44-00000-00000
// // // // //               </p>
// // // // //               <p style={{ fontSize: "15px", fontFamily: "Inter" }}>
// // // // //                 <b>Email : </b> abc@xyz.com
// // // // //               </p>
// // // // //             </div>
// // // // //             <div>
// // // // //               <h5 style={{ fontSize: "18px", fontFamily: "Inter" }}>
// // // // //                 ID: 98989
// // // // //               </h5>
// // // // //               <p style={{ fontSize: "15px", fontFamily: "Inter" }}>
// // // // //                 <b>Invoice Date : </b>15 June 2024
// // // // //               </p>
// // // // //               <p style={{ fontSize: "15px", fontFamily: "Inter" }}>
// // // // //                 <b>Status : </b> Paid
// // // // //               </p>
// // // // //             </div>
// // // // //           </div>
// // // // //           <hr />
// // // // //           <table style={{ width: "100%", marginTop: "4%" }}>
// // // // //             <tr style={{ background: "#D9D9D9" }}>
// // // // //               <th style={{ padding : "12px"}}>Transaction ID</th>
// // // // //               <th style={{ padding : "12px"}}>Show Name</th>
// // // // //               <th style={{ padding : "12px"}}>Show Date</th>
// // // // //               <th style={{ padding : "12px"}}>Total Quantity</th>
// // // // //               <th style={{ padding : "12px"}}>Amount</th>
// // // // //             </tr>
// // // // //             <tr style={{borderBottom: "1px solid #D9D9D9"}}>
// // // // //               <td style={{ padding : "12px"}}>9898</td>
// // // // //               <td style={{ padding : "12px"}}>Megan Thee Stallion</td>
// // // // //               <td style={{ padding : "12px"}}>2024-07-07</td>
// // // // //               <td style={{ padding : "12px"}}>2</td>
// // // // //               <td style={{ padding : "12px"}}>EUR 20.400</td>
// // // // //             </tr>
// // // // //             <tr style={{borderBottom: "1px solid #D9D9D9"}}>
// // // // //               <td style={{ padding : "12px"}}>9898</td>
// // // // //               <td style={{ padding : "12px"}}>Megan Thee Stallion</td>
// // // // //               <td style={{ padding : "12px"}}>2024-07-07</td>
// // // // //               <td style={{ padding : "12px"}}>2</td>
// // // // //               <td style={{ padding : "12px"}}>EUR 20.400</td>
// // // // //             </tr>
// // // // //           </table>

// // // // //           <div style={{display : "flex", justifyContent: "space-around", paddingTop: "3%"}}>
// // // // //             <h6
// // // // //               style={{
// // // // //                 fontFamily: "Poppins",
// // // // //                 fontSize: "18px",
// // // // //               }}
// // // // //             >
// // // // //               Additional Charges and Total
// // // // //             </h6>
// // // // //             <div>
// // // // //               <p style={{ fontSize: "15px", fontFamily: "Inter", fontWeight: "500" }}>
// // // // //                 <b>Subtotal : </b> EUR 40.800
// // // // //               </p>
// // // // //               <p style={{ fontSize: "15px", fontFamily: "Inter", fontWeight: "500" }}>
// // // // //                 <b>Tax : </b> 15%
// // // // //               </p>
// // // // //               <p style={{ fontSize: "15px", fontFamily: "Inter", fontWeight: "500" }}>
// // // // //                 <b>Total : </b> EUR 50
// // // // //               </p>
// // // // //             </div>
// // // // //           </div>

// // // // //           <hr />

// // // // //           <p style={{ fontSize: "16px", fontFamily: "Poppins", fontWeight: "600", textAlign: "center" }}>Thank you for your Booking</p>
// // // // //         </div>
// // // // //       </div>
// // // // //     </>
// // // // //   );
// // // // // };

// // // // // export default Invoice;
// // // // import React, { useEffect, useState } from "react";
// // // // import axios from "axios";

// // // // const Invoice = () => {
// // // //   const [userData, setUserData] = useState(null);
// // // //   const [eventData, setEventData] = useState(null);
// // // //   const [ticketsData, setTicketsData] = useState([]);

// // // //   useEffect(() => {
// // // //     const userId = localStorage.getItem('userid'); // Replace with dynamic ID if needed
// // // //     const eventId = 1; // Replace with dynamic ID if needed

// // // //     const fetchUserData = async () => {
// // // //       try {
// // // //         const response = await axios.get(`https://backend.eurofestia.com/api/v1/users/user/${userId}`);
// // // //         setUserData(response.data);
// // // //       } catch (error) {
// // // //         console.error("Error fetching user data:", error);
// // // //       }
// // // //     };

// // // //     const fetchEventData = async () => {
// // // //       try {
// // // //         const response = await axios.get(`https://backend.eurofestia.com/api/v1/events/event/${eventId}`);
// // // //         setEventData(response.data);
// // // //       } catch (error) {
// // // //         console.error("Error fetching event data:", error);
// // // //       }
// // // //     };

// // // //     const fetchTicketsData = async () => {
// // // //       try {
// // // //         const response = await axios.get(`https://backend.eurofestia.com/api/v1/tickets/ticket/${eventId}`);
// // // //         setTicketsData(response.data);
// // // //       } catch (error) {
// // // //         console.error("Error fetching tickets data:", error);
// // // //       }
// // // //     };

// // // //     fetchUserData();
// // // //     fetchEventData();
// // // //     fetchTicketsData();
// // // //   }, []);

// // // //   if (!userData || !eventData || ticketsData.length === 0) {
// // // //     return <div>Loading...</div>;
// // // //   }

// // // //   const subtotal = ticketsData.reduce((acc, ticket) => acc + ticket.price * ticket.quantity, 0);
// // // //   const tax = 0.15 * subtotal;
// // // //   const total = subtotal + tax;

// // // //   return (
// // // //     <>
// // // //       <div style={{ margin: "3%" }}>
// // // //         <div
// // // //           style={{
// // // //             width: "66.6667%",
// // // //             maxWidth: "100%",
// // // //             margin: "0 auto",
// // // //             border: "1px solid black",
// // // //             padding: "2%",
// // // //             borderRadius: "6px",
// // // //           }}
// // // //         >
// // // //           <h2
// // // //             style={{
// // // //               fontFamily: "Poppins",
// // // //               fontSize: "24px",
// // // //               textAlign: "center",
// // // //             }}
// // // //           >
// // // //             Eurofest
// // // //           </h2>
// // // //           <h4
// // // //             style={{
// // // //               fontFamily: "Poppins",
// // // //               fontSize: "20px",
// // // //               textAlign: "center",
// // // //               color: "#656a78",
// // // //             }}
// // // //           >
// // // //             Invoice Receipt
// // // //           </h4>

// // // //           <div style={{ display: "flex", justifyContent: "space-between" }}>
// // // //             <div>
// // // //               <h5 style={{ fontSize: "18px", fontFamily: "Inter" }}>
// // // //                 {userData.name}
// // // //               </h5>
// // // //               <p style={{ fontSize: "15px", fontFamily: "Inter" }}>
// // // //                 <b>Mobile no. : </b>{userData.phone}
// // // //               </p>
// // // //               <p style={{ fontSize: "15px", fontFamily: "Inter" }}>
// // // //                 <b>Email : </b> {userData.email}
// // // //               </p>
// // // //             </div>
// // // //             <div>
// // // //               <h5 style={{ fontSize: "18px", fontFamily: "Inter" }}>
// // // //                 ID: {userData.id}
// // // //               </h5>
// // // //               <p style={{ fontSize: "15px", fontFamily: "Inter" }}>
// // // //                 <b>Invoice Date : </b>{new Date().toLocaleDateString()}
// // // //               </p>
// // // //               <p style={{ fontSize: "15px", fontFamily: "Inter" }}>
// // // //                 <b>Status : </b> Paid
// // // //               </p>
// // // //             </div>
// // // //           </div>
// // // //           <hr />
// // // //           <table style={{ width: "100%", marginTop: "4%" }}>
// // // //             <thead>
// // // //               <tr style={{ background: "#D9D9D9" }}>
// // // //                 <th style={{ padding: "12px" }}>Transaction ID</th>
// // // //                 <th style={{ padding: "12px" }}>Show Name</th>
// // // //                 <th style={{ padding: "12px" }}>Show Date</th>
// // // //                 <th style={{ padding: "12px" }}>Total Quantity</th>
// // // //                 <th style={{ padding: "12px" }}>Amount</th>
// // // //               </tr>
// // // //             </thead>
// // // //             <tbody>
// // // //               {ticketsData.map((ticket) => (
// // // //                 <tr key={ticket.id} style={{ borderBottom: "1px solid #D9D9D9" }}>
// // // //                   <td style={{ padding: "12px" }}>{ticket.transactionId}</td>
// // // //                   <td style={{ padding: "12px" }}>{eventData.name}</td>
// // // //                   <td style={{ padding: "12px" }}>{new Date(eventData.date).toLocaleDateString()}</td>
// // // //                   <td style={{ padding: "12px" }}>{ticket.quantity}</td>
// // // //                   <td style={{ padding: "12px" }}>EUR {ticket.price.toFixed(2)}</td>
// // // //                 </tr>
// // // //               ))}
// // // //             </tbody>
// // // //           </table>

// // // //           <div style={{ display: "flex", justifyContent: "space-around", paddingTop: "3%" }}>
// // // //             <h6
// // // //               style={{
// // // //                 fontFamily: "Poppins",
// // // //                 fontSize: "18px",
// // // //               }}
// // // //             >
// // // //               Additional Charges and Total
// // // //             </h6>
// // // //             <div>
// // // //               <p style={{ fontSize: "15px", fontFamily: "Inter", fontWeight: "500" }}>
// // // //                 <b>Subtotal : </b> EUR {subtotal.toFixed(2)}
// // // //               </p>
// // // //               <p style={{ fontSize: "15px", fontFamily: "Inter", fontWeight: "500" }}>
// // // //                 <b>Tax : </b> 15%
// // // //               </p>
// // // //               <p style={{ fontSize: "15px", fontFamily: "Inter", fontWeight: "500" }}>
// // // //                 <b>Total : </b> EUR {total.toFixed(2)}
// // // //               </p>
// // // //             </div>
// // // //           </div>

// // // //           <hr />

// // // //           <p style={{ fontSize: "16px", fontFamily: "Poppins", fontWeight: "600", textAlign: "center" }}>
// // // //             Thank you for your Booking
// // // //           </p>
// // // //         </div>
// // // //       </div>
// // // //     </>
// // // //   );
// // // // };

// // // // export default Invoice;


// // import React, { useEffect, useState } from 'react';
// // import axios from 'axios';
// // import { useLocation, useParams } from 'react-router-dom';

// // const Invoice = () => {
// //   const { state } = useLocation();
// //   const { booking } = state || {};
// //   const [user, setUser] = useState(null);
// //   const [event, setEvent] = useState(null);

// //   useEffect(() => {
// //     const fetchUserAndEvent = async () => {
// //       try {
// //         const userResponse = await axios.get(`https://backend.eurofestia.com/api/v1/users/user/${booking.customer}`);
// //         const userData=userResponse.data.user;
// //         // console.log(userResponse.data)
// //         setUser(userData);
        

// //         const eventResponse = await axios.get(`https://backend.eurofestia.com/api/v1/events/event/${booking.eventId}`);
// //         setEvent(eventResponse.data);
// //       } catch (error) {
// //         console.error("Error fetching user or event data:", error);
// //       }
// //     };

// //     if (booking) {
// //       fetchUserAndEvent();
// //     }
// //   }, [booking]);

// //   if (!booking || !user || !event) {
// //     return <div>Loading...</div>;
// //   }
// // console.log(user)
// // console.log(event)
// // console.log(booking)
// // // console.log(user.firstname)
// //   const subtotal = booking.amount ; // assuming amount is in cents
// //   const tax = 0.15 * subtotal;
// //   const total = subtotal + tax;

// //   return (
// //     <div style={{ margin: "3%" }}>
// //       <div
// //         style={{
// //           width: "66.6667%",
// //           maxWidth: "100%",
// //           margin: "0 auto",
// //           border: "1px solid black",
// //           padding: "2%",
// //           borderRadius: "6px",
// //         }}
// //       >
// //         <h2
// //           style={{
// //             fontFamily: "Poppins",
// //             fontSize: "24px",
// //             textAlign: "center",
// //           }}
// //         >
// //           Eurofest
// //         </h2>
// //         <h4
// //           style={{
// //             fontFamily: "Poppins",
// //             fontSize: "20px",
// //             textAlign: "center",
// //             color: "#656a78",
// //           }}
// //         >
// //           Invoice Receipt
// //         </h4>

// //         <div style={{ display: "flex", justifyContent: "space-between" }}>
// //           <div>
// //             <h5 style={{ fontSize: "18px", fontFamily: "Inter" }}>
// //               {user.firstname}{user.lastname}
// //             </h5>
// //             <p style={{ fontSize: "15px", fontFamily: "Inter" }}>
// //               <b>Mobile no. : </b>{user.phone}
// //             </p>
// //             <p style={{ fontSize: "15px", fontFamily: "Inter" }}>
// //               <b>Email : </b>{user.email}
// //             </p>
// //           </div>
// //           <div>
// //             <h5 style={{ fontSize: "18px", fontFamily: "Inter" }}>
// //               ID: {booking.paymentId}
// //             </h5>
// //             <p style={{ fontSize: "15px", fontFamily: "Inter" }}>
// //               <b>Invoice Date : </b>{new Date(booking.createdAt).toLocaleDateString()}
// //             </p>
// //             <p style={{ fontSize: "15px", fontFamily: "Inter" }}>
// //               <b>Status : </b> {booking.status}
// //             </p>
// //           </div>
// //         </div>
// //         <hr />
// //         <table style={{ width: "100%", marginTop: "4%" }}>
// //           <thead>
// //             <tr style={{ background: "#D9D9D9" }}>
// //               <th style={{ padding: "12px" }}>Transaction ID</th>
// //               <th style={{ padding: "12px" }}>Show Name</th>
// //               <th style={{ padding: "12px" }}>Show Date</th>
// //               {/* <th style={{ padding: "12px" }}>Show Time</th> */}
// //               <th style={{ padding: "12px" }}>Total Quantity</th>
// //               <th style={{ padding: "12px" }}>Amount</th>
// //             </tr>
// //           </thead>
// //           <tbody>
// //             <tr style={{ borderBottom: "1px solid #D9D9D9" }}>
// //               <td style={{ padding: "12px" }}>{booking._id}</td>
// //               <td style={{ padding: "12px" }}>{booking.eventName}</td>
// //               <td style={{ padding: "12px" }}>{booking.eventDate}</td>
// //               {/* <td style={{ padding: "12px" }}>{event.time}</td> */}

// //               <td style={{ padding: "12px" }}>{booking.ticketquantity}</td>
// //               <td style={{ padding: "12px" }}>{booking.currency} {/*subtotal.toFixed(2)*/}{booking.amount}</td>
// //             </tr>
// //           </tbody>
// //         </table>

// //         <div style={{ display: "flex", justifyContent: "space-around", paddingTop: "3%" }}>
// //           <h6
// //             style={{
// //               fontFamily: "Poppins",
// //               fontSize: "18px",
// //             }}
// //           >
// //             {/* Additional Charges and Total */}
// //           </h6>
// //           {/* <div>
// //             <p style={{ fontSize: "15px", fontFamily: "Inter", fontWeight: "500" }}>
// //               <b>Subtotal : </b> EUR {subtotal.toFixed(2)}
// //             </p>
// //             <p style={{ fontSize: "15px", fontFamily: "Inter", fontWeight: "500" }}>
// //               <b>Tax : </b> 15%
// //             </p>
// //             <p style={{ fontSize: "15px", fontFamily: "Inter", fontWeight: "500" }}>
// //               <b>Total : </b> EUR {total.toFixed(2)}
// //             </p>
// //           </div> */}
// //         </div>

// //         <hr />

// //         <p style={{ fontSize: "16px", fontFamily: "Poppins", fontWeight: "600", textAlign: "center" }}>
// //           Thank you for your Booking
// //         </p>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Invoice;

// // // import React, { useEffect, useState } from 'react';
// // // import axios from 'axios';
// // // import { useLocation } from 'react-router-dom';

// // // const Invoice = () => {
// // //   const { state } = useLocation();
// // //   const { booking } = state || {};
// // //   const [user, setUser] = useState(null);
// // //   const [event, setEvent] = useState(null);

// // //   useEffect(() => {
// // //     const fetchUserAndEvent = async () => {
// // //       try {
// // //         if (booking) {
// // //           const userResponse = await axios.get(`https://backend.eurofestia.com/api/v1/users/user/${booking.customer}`);
// // //           const userData = userResponse.data;
// // //           console.log("User data fetched:", userData);
// // //           setUser(userData);

// // //           const eventResponse = await axios.get(`https://backend.eurofestia.com/api/v1/events/event/${booking.eventId}`);
// // //           const eventData = eventResponse.data;
// // //           console.log("Event data fetched:", eventData);
// // //           setEvent(eventData);
// // //         } else {
// // //           console.error("No booking data available");
// // //         }
// // //       } catch (error) {
// // //         console.error("Error fetching user or event data:", error);
// // //       }
// // //     };

// // //     fetchUserAndEvent();
// // //   }, [booking]);

// // //   if (!booking || !user || !event) {
// // //     return <div>Loading...</div>;
// // //   }

// // //   console.log("User data:", user); // Ensure this logs the expected user data
// // //   console.log("Booking data:", booking); // Ensure this logs the expected booking data
// // //   console.log("Event data:", event); // Ensure this logs the expected event data

// // //   const subtotal = booking.amount; // assuming amount is in cents
// // //   const tax = 0.15 * subtotal;
// // //   const total = subtotal + tax;

// // //   return (
// // //     <div style={{ margin: "3%" }}>
// // //       <div
// // //         style={{
// // //           width: "66.6667%",
// // //           maxWidth: "100%",
// // //           margin: "0 auto",
// // //           border: "1px solid black",
// // //           padding: "2%",
// // //           borderRadius: "6px",
// // //         }}
// // //       >
// // //         <h2
// // //           style={{
// // //             fontFamily: "Poppins",
// // //             fontSize: "24px",
// // //             textAlign: "center",
// // //           }}
// // //         >
// // //           Eurofest
// // //         </h2>
// // //         <h4
// // //           style={{
// // //             fontFamily: "Poppins",
// // //             fontSize: "20px",
// // //             textAlign: "center",
// // //             color: "#656a78",
// // //           }}
// // //         >
// // //           Invoice Receipt
// // //         </h4>

// // //         <div style={{ display: "flex", justifyContent: "space-between" }}>
// // //           <div>
// // //             <h5 style={{ fontSize: "18px", fontFamily: "Inter" }}>
// // //               {user.firstname} {user.lastname}
// // //             </h5>
// // //             <p style={{ fontSize: "15px", fontFamily: "Inter" }}>
// // //               <b>Mobile no. : </b>{user.phone}
// // //             </p>
// // //             <p style={{ fontSize: "15px", fontFamily: "Inter" }}>
// // //               <b>Email : </b>{user.email}
// // //             </p>
// // //           </div>
// // //           <div>
// // //             <h5 style={{ fontSize: "18px", fontFamily: "Inter" }}>
// // //               ID: {booking.paymentId}
// // //             </h5>
// // //             <p style={{ fontSize: "15px", fontFamily: "Inter" }}>
// // //               <b>Invoice Date : </b>{new Date(booking.createdAt).toLocaleDateString()}
// // //             </p>
// // //             <p style={{ fontSize: "15px", fontFamily: "Inter" }}>
// // //               <b>Status : </b> {booking.status}
// // //             </p>
// // //           </div>
// // //         </div>
// // //         <hr />
// // //         <table style={{ width: "100%", marginTop: "4%" }}>
// // //           <thead>
// // //             <tr style={{ background: "#D9D9D9" }}>
// // //               <th style={{ padding: "12px" }}>Transaction ID</th>
// // //               <th style={{ padding: "12px" }}>Show Name</th>
// // //               <th style={{ padding: "12px" }}>Show Date</th>
// // //               <th style={{ padding: "12px" }}>Total Quantity</th>
// // //               <th style={{ padding: "12px" }}>Amount</th>
// // //             </tr>
// // //           </thead>
// // //           <tbody>
// // //             <tr style={{ borderBottom: "1px solid #D9D9D9" }}>
// // //               <td style={{ padding: "12px" }}>{booking._id}</td>
// // //               <td style={{ padding: "12px" }}>{booking.eventName}</td>
// // //               <td style={{ padding: "12px" }}>{new Date(booking.eventDate).toLocaleDateString()}</td>
// // //               <td style={{ padding: "12px" }}>{booking.ticketquantity}</td>
// // //               <td style={{ padding: "12px" }}>{booking.currency} {booking.amount}</td>
// // //             </tr>
// // //           </tbody>
// // //         </table>

// // //         <div style={{ display: "flex", justifyContent: "space-around", paddingTop: "3%" }}>
// // //           <h6
// // //             style={{
// // //               fontFamily: "Poppins",
// // //               fontSize: "18px",
// // //             }}
// // //           >
// // //             Additional Charges and Total
// // //           </h6>
// // //           <div>
// // //             <p style={{ fontSize: "15px", fontFamily: "Inter", fontWeight: "500" }}>
// // //               <b>Subtotal : </b> EUR {subtotal.toFixed(2)}
// // //             </p>
// // //             <p style={{ fontSize: "15px", fontFamily: "Inter", fontWeight: "500" }}>
// // //               <b>Tax : </b> 15%
// // //             </p>
// // //             <p style={{ fontSize: "15px", fontFamily: "Inter", fontWeight: "500" }}>
// // //               <b>Total : </b> EUR {total.toFixed(2)}
// // //             </p>
// // //           </div>
// // //         </div>

// // //         <hr />

// // //         <p style={{ fontSize: "16px", fontFamily: "Poppins", fontWeight: "600", textAlign: "center" }}>
// // //           Thank you for your Booking
// // //         </p>
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // export default Invoice;

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useLocation } from 'react-router-dom';
// import jsPDF from 'jspdf';
// import { useBooking } from '../BookingContext';
// import html2canvas from 'html2canvas';

// const Invoice = () => {
//   const { state } = useLocation();
//   const { bookingData } = useBooking();
//   const { booking } = state || {};
//   const [user, setUser] = useState(null);
//   const [event, setEvent] = useState(null);

//   useEffect(() => {
//     const fetchUserAndEvent = async () => {
//       try {
//         const userResponse = await axios.get(`https://backend.eurofestia.com/api/v1/users/user/${booking.customer}`);
//         const userData = userResponse.data.user;
//         setUser(userData);

//         const eventResponse = await axios.get(`https://backend.eurofestia.com/api/v1/events/event/${booking.eventId}`);
//         setEvent(eventResponse.data);
//       } catch (error) {
//         console.error("Error fetching user or event data:", error);
//       }
//     };

//     if (booking) {
//       fetchUserAndEvent();
//     }
//   }, [booking]);

//   const downloadInvoice = () => {
//     const input = document.getElementById('invoice');
//     html2canvas(input).then((canvas) => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF('p', 'mm', 'a4');
//       const imgProps = pdf.getImageProperties(imgData);
//       const pdfWidth = pdf.internal.pageSize.getWidth();
//       const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

//       pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
//       pdf.save('invoice.pdf');
//     });
//   };
//   const eventDate = new Date(booking.eventDate);
//   const formattedDate = eventDate.toISOString().split('T')[0];
//   if (!booking || !user || !event) {
//     return <div>Loading...</div>;
//   }

//   const subtotal = booking.amount; // assuming amount is in cents
//   const tax = 0.15 * subtotal;
//   const total = subtotal + tax;

//   return (
//     <div style={{ margin: '3%' }}>
//       <div style={{ display: 'flex', justifyContent: 'center',marginBottom: '20px' }}>
//         <button onClick={downloadInvoice} style={{ padding: '10px', borderRadius: '5px', cursor: 'pointer', background: '#007bff', color: '#fff', border: 'none', transition: 'background 0.3s ease' }}>
//           Download PDF
//         </button>
//       </div>
//       <div
//         id="invoice"
//         style={{
//           width: '210mm',
//           minHeight: 'auto',
//           margin: '0 auto',
//           border: '1px solid black',
//           padding: '5mm 20mm',
//           borderRadius: '6px',
//           boxSizing: 'border-box',
//         }}
//       >
//         <h2
//           style={{
//             fontFamily: 'Poppins',
//             fontSize: '24px',
//             textAlign: 'center',
//           }}
//         >
//            <img src={`${process.env.PUBLIC_URL}/assets/logo.png`} alt="logo"/>
//         </h2>
//         <h4
//           style={{
//             fontFamily: 'Poppins',
//             fontSize: '20px',
//             textAlign: 'center',
//             color: '#656a78',
//           }}
//         >
//           Invoice Receipt
//         </h4>

//         <div style={{ display: 'flex', marginTop: '40px', justifyContent: 'space-between' }}>
//           <div>
//             <h5 style={{ fontSize: '15px', fontFamily: 'Inter',fontWeight: '600' }}>
//               {user.firstname} {user.lastname}
//             </h5>
//             <p style={{ fontSize: '14px', fontFamily: 'Inter' }}>
//               <b>Mobile no. : </b>{user.phone}
//             </p>
//             <p style={{ fontSize: '14px', fontFamily: 'Inter' }}>
//               <b>Email : </b>{user.email}
//             </p>
//           </div>
//           <div>
//             <h5 style={{ fontSize: '14px', fontFamily: 'Inter' }}>
//               ID: {booking.paymentId}
//             </h5>
//             <p style={{ fontSize: '14px', fontFamily: 'Inter' }}>
//               <b>Invoice Date : </b>{new Date(booking.createdAt).toLocaleDateString()}
//             </p>
//             <p style={{ fontSize: '14px', fontFamily: 'Inter' }}>
//               <b>Status : </b> {booking.status}
//             </p>
//           </div>
//         </div>
//         <hr />
//         <table style={{ width: '100%', marginTop: '4%' }}>
//           <thead>
//             <tr style={{ background: '#D9D9D9' }}>
//               <th style={{fontSize: '13px',  padding: '12px' }}>Transaction ID</th>
//               <th style={{ fontSize: '13px', padding: '12px' }}>Show Name</th>
//               <th style={{ fontSize: '13px',  padding: '12px' }}>Show Date</th>
//               <th style={{ fontSize: '13px',  padding: '12px' }}>Total Quantity</th>
//               <th style={{ fontSize: '13px',  padding: '12px' }}>Amount</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr style={{ borderBottom: '1px solid #D9D9D9' }}>
//               <td style={{ fontSize: '13px', padding: '12px' }}>{booking._id}</td>
//               <td style={{ fontSize: '13px', padding: '12px' }}>{booking.eventName}</td>
//               <td style={{ fontSize: '13px', padding: '12px' }}>{formattedDate}</td>
//               <td style={{ fontSize: '13px', padding: '12px' }}>{booking.ticketquantity}</td>
//               <td style={{ fontSize: '13px', padding: '12px' }}>{booking.currency} {booking.amount}</td>
//             </tr>
//           </tbody>
//         </table>

//         <div style={{ display: 'flex', justifyContent: 'space-around', paddingTop: '3%' }}>
//           <h6
//             style={{
//               fontFamily: 'Poppins',
//               fontSize: '18px',
//             }}
//           >
//           </h6>
//         </div>

//         <hr />

//         <p style={{ fontSize: '16px', fontFamily: 'Poppins', fontWeight: '600', textAlign: 'center' }}>
//           Thank you for your Booking
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Invoice;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const Invoice = () => {
  const { state } = useLocation();
  const { booking } = state || {};
  const [user, setUser] = useState(null);
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const fetchUserAndEvent = async () => {
      try {
        const userResponse = await axios.get(`https://backend.eurofestia.com/api/v1/users/user/${booking.customer}`);
        const userData = userResponse.data.user;
        setUser(userData);

        const eventResponse = await axios.get(`https://backend.eurofestia.com/api/v1/events/event/${booking.eventId}`);
        setEvent(eventResponse.data);
      } catch (error) {
        console.error("Error fetching user or event data:", error);
      }
    };

    if (booking) {
      fetchUserAndEvent();
    }
  }, [booking]);

  const downloadInvoice = () => {
    const input = document.getElementById('invoice');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('invoice.pdf');
    });
  };

  const eventDate = new Date(booking.eventDate);
  const formattedDate = eventDate.toISOString().split('T')[0];
  
  if (!booking || !user || !event) {
    return <div>Loading...</div>;
  }

  const subtotal = booking.amount;
  const tax = 0.15 * subtotal;
  const total = subtotal + tax;
  const filteredTicketDetails = booking.ticketDetails.filter(ticket => ticket.count > 0);


  return (
    <div style={{ margin: '3%' }}>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <button onClick={downloadInvoice} style={{ padding: '10px', borderRadius: '5px', cursor: 'pointer', background: '#007bff', color: '#fff', border: 'none', transition: 'background 0.3s ease' }}>
          Download PDF
        </button>
      </div>
      <div
        id="invoice"
        style={{
          width: '210mm',
          minHeight: 'auto',
          margin: '0 auto',
          border: '1px solid black',
          padding: '5mm 20mm',
          borderRadius: '6px',
          boxSizing: 'border-box',
        }}
      >
        <h2
          style={{
            fontFamily: 'Poppins',
            fontSize: '24px',
            textAlign: 'center',
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/logo.png`} alt="logo" />
        </h2>
        <h4
          style={{
            fontFamily: 'Poppins',
            fontSize: '20px',
            textAlign: 'center',
            color: '#656a78',
          }}
        >
          Invoice Receipt
        </h4>

        <div style={{ display: 'flex', marginTop: '40px', justifyContent: 'space-between' }}>
          <div>
            <h5 style={{ fontSize: '15px', fontFamily: 'Inter', fontWeight: '600' }}>
              {user.firstname} {user.lastname}
            </h5>
            <p style={{ fontSize: '14px', fontFamily: 'Inter' }}>
              <b>Mobile no. : </b>{user.phone}
            </p>
            <p style={{ fontSize: '14px', fontFamily: 'Inter' }}>
              <b>Email : </b>{user.email}
            </p>
          </div>
          <div>
            <h5 style={{ fontSize: '14px', fontFamily: 'Inter' }}>
              ID: {booking.paymentId}
            </h5>
            <p style={{ fontSize: '14px', fontFamily: 'Inter' }}>
              <b>Invoice Date : </b>{new Date(booking.createdAt).toLocaleDateString()}
            </p>
            <p style={{ fontSize: '14px', fontFamily: 'Inter' }}>
              <b>Status : </b> {booking.status}
            </p>
          </div>
        </div>
        <hr />
        <table style={{ width: '100%', marginTop: '4%' }}>
          <thead>
            <tr style={{ background: '#D9D9D9' }}>
              <th style={{ fontSize: '13px', padding: '12px' }}>Ticket Type</th>
              <th style={{ fontSize: '13px', padding: '12px' }}>Ticket Price</th>
              <th style={{ fontSize: '13px', padding: '12px' }}>Booking Fee</th>
              <th style={{ fontSize: '13px', padding: '12px' }}>Quantity</th>
              <th style={{ fontSize: '13px', padding: '12px' }}>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {filteredTicketDetails.map((ticket) => (
              <tr key={ticket._id} style={{ borderBottom: '1px solid #D9D9D9' }}>
                <td style={{ fontSize: '13px', padding: '12px' }}>{ticket.typeName}</td>
                <td style={{ fontSize: '13px', padding: '12px' }}>{booking.currency} {ticket.ticketPrice}</td>
                <td style={{ fontSize: '13px', padding: '12px' }}>{booking.currency} {ticket.bookingfeePrice}</td>
                <td style={{ fontSize: '13px', padding: '12px' }}>{ticket.count}</td>
                <td style={{ fontSize: '13px', padding: '12px' }}>{booking.currency} {ticket.totalAmount}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <table style={{ width: '100%', marginTop: '4%' }}>
          <thead>
            <tr style={{ background: '#D9D9D9' }}>
              <th style={{ fontSize: '13px', padding: '12px' }}>Transaction ID</th>
              <th style={{ fontSize: '13px', padding: '12px' }}>Show Name</th>
              <th style={{ fontSize: '13px', padding: '12px' }}>Show Date</th>
              <th style={{ fontSize: '13px', padding: '12px' }}>Total Quantity</th>
              <th style={{ fontSize: '13px', padding: '12px' }}>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ borderBottom: '1px solid #D9D9D9' }}>
              <td style={{ fontSize: '13px', padding: '12px' }}>{booking._id}</td>
              <td style={{ fontSize: '13px', padding: '12px' }}>{booking.eventName}</td>
              <td style={{ fontSize: '13px', padding: '12px' }}>{formattedDate}</td>
              <td style={{ fontSize: '13px', padding: '12px' }}>{booking.ticketquantity}</td>
              <td style={{ fontSize: '13px', padding: '12px' }}>{booking.currency} {booking.amount}</td>
            </tr>
          </tbody>
        </table>

        

        

        <div style={{ display: 'flex', justifyContent: 'space-around', paddingTop: '3%' }}>
          <h6
            style={{
              fontFamily: 'Poppins',
              fontSize: '18px',
            }}
          >
          </h6>
        </div>


        <p style={{ fontSize: '16px', fontFamily: 'Poppins', fontWeight: '600', textAlign: 'center' }}>
          Thank you for your Booking
        </p>
      </div>
    </div>
  );
};

export default Invoice;
