// // import React from "react";
// // import Navbar from "./Navbar";
// // import { Link } from "react-router-dom";

// // const PaymentForm = () => {
// //   return (
// //     <>
// //       <Navbar />

// //       <div className="container-fluid signin">
// //         <div className="container">
// //           <div className="signin-form row">
// //             <div className="col-lg-7 mx-auto confirm">
// //               <h3>Card Details </h3>
// //               <div className="book-payment pt-3 text-center">
// //                 <h4>Payment Using :</h4>
// //                 <div className="payment-imgs">
// //                   <img src="assets/master-card.png" alt="" />
// //                   <img src="assets/visa.png" alt="" />
// //                   <img src="assets/paypal.png" alt="" />
// //                   <img src="assets/maestro.png" alt="" />
// //                 </div>
// //               </div>
// //               <form>
// //                 <div className="row pay-row">
// //                   <div className="col-lg-12 sign-item pay-item">
// //                     <label htmlFor="name">Name On Card</label>
// //                     <input type="text" placeholder="Card Holder name" />
// //                   </div>
// //                   <div className="col-lg-12 sign-item pay-item">
// //                     <label htmlFor="name">Card Number</label>
// //                     <input type="text" placeholder="1234 1234 1234 1234" />
// //                   </div>
// //                   <div className="col-lg-6 sign-item pay-item">
// //                     <label htmlFor="name">Expiration Date</label>
// //                     <input type="text" placeholder="mm/yy" />
// //                   </div>
// //                   <div className="col-lg-6 sign-item pay-item">
// //                     <label htmlFor="name">CVV</label>
// //                     <input type="password" placeholder="000" />
// //                   </div>
// //                 </div>
// //               </form>
// //               <div className="book-item pt-3">
// //                 <div>
// //                   <h2>Subtotal : </h2>
// //                   <h2>Extra Charges : </h2>
// //                   <h2>Total(Tax incl.) : </h2>
// //                 </div>
// //                 <div>
// //                   <h2>$ 2400</h2>
// //                   <h2>$ 530</h2>
// //                   <h2>$ 2930</h2>
// //                 </div>
// //               </div>
// //               <div className="confirm-button">
// //                 <button>
// //                   <Link to="/" className="text-decoration-none text-white">
// //                     Continue to Pay
// //                   </Link>
// //                 </button>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </>
// //   );
// // };

// // export default PaymentForm;

// import React, { useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import Navbar from "./Navbar";
// import Footer from "./Footer";

// const PaymentForm = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { totalPrice, totalTickets } = location.state || { totalPrice: 40, totalTickets: 0 };

//   const [formData, setFormData] = useState({
//     cardNumber: "",
//     cardholderName: "",
//     cardSecurityCode: "",
//     expiryMonth: "",
//     expiryYear: "",
//     // referenceId: "",
//     currency: "",
//   });
//   const [loading, setLoading] = useState(false);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     const paymentData = {
//       paymentType: "DEPOSIT",
//       card: {
//         cardNumber: formData.cardNumber,
//         cardholderName: formData.cardholderName,
//         cardSecurityCode: formData.cardSecurityCode,
//         expiryMonth: formData.expiryMonth,
//         expiryYear: formData.expiryYear,
//       },
//       customer: {
//         referenceId: localStorage.getItem('userid'),
//       },
//       amount: totalPrice,
//       currency: formData.currency,
//     };

//     try {
//       const response = await axios.post("https://backend.eurofestia.com/api/v1/payments/createpayment", paymentData);
//       console.log("Payment successful:", response.data);
//       setLoading(false);
//       // Redirect or show success message
//       // history.push("/success");
//       navigate('/confirm');
//     } catch (error) {
//       console.error("Error processing payment:", error);
//       setLoading(false);
//       // Handle error
//     }
//   };

//   return (
//     <>
//       <Navbar />

//       <div className="container-fluid signin">
//         <div className="container">
//           <div className="signin-form row">
//             <div className="col-lg-7 mx-auto confirm">
//               <h3>Card Details </h3>
//               <div className="book-payment pt-3 text-center">
//                 <h4>Payment Using :</h4>
//                 <div className="payment-imgs">
//                   <img src="assets/master-card.png" alt="MasterCard" />
//                   <img src="assets/visa.png" alt="Visa" />
//                   <img src="assets/paypal.png" alt="PayPal" />
//                   <img src="assets/maestro.png" alt="Maestro" />
//                 </div>
//               </div>
//               <form onSubmit={handleSubmit}>
//                 <div className="row pay-row">
//                   <div className="col-lg-12 sign-item pay-item">
//                     <label htmlFor="cardholderName">Name On Card</label>
//                     <input
//                       type="text"
//                       name="cardholderName"
//                       placeholder="Card Holder name"
//                       value={formData.cardholderName}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   <div className="col-lg-12 sign-item pay-item">
//                     <label htmlFor="cardNumber">Card Number</label>
//                     <input
//                       type="text"
//                       name="cardNumber"
//                       placeholder="1234 1234 1234 1234"
//                       value={formData.cardNumber}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   <div className="col-lg-6 sign-item pay-item">
//                     <label htmlFor="expiryMonth">Expiration Month</label>
//                     <input
//                       type="text"
//                       name="expiryMonth"
//                       placeholder="MM"
//                       value={formData.expiryMonth}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   <div className="col-lg-6 sign-item pay-item">
//                     <label htmlFor="expiryYear">Expiration Year</label>
//                     <input
//                       type="text"
//                       name="expiryYear"
//                       placeholder="YYYY"
//                       value={formData.expiryYear}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   <div className="col-lg-6 sign-item pay-item">
//                     <label htmlFor="cardSecurityCode">CVV</label>
//                     <input
//                       type="password"
//                       name="cardSecurityCode"
//                       placeholder="000"
//                       value={formData.cardSecurityCode}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   {/* <div className="col-lg-6 sign-item pay-item">
//                     <label htmlFor="referenceId">Customer Reference ID</label>
//                     <input
//                       type="text"
//                       name="referenceId"
//                       placeholder="userid"
//                       value={localStorage.getItem('userid')}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div> */}
//                   <div className="col-lg-12 sign-item pay-item">
//                     <label htmlFor="currency">Currency</label>
//                     <input
//                       type="text"
//                       name="currency"
//                       placeholder="Currency"
//                       value={formData.currency}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="book-item pt-3">
//                   <div>
//                     <h2>Total Price : </h2>
//                     <h2>Total Tickets : </h2>
//                   </div>
//                   <div>
//                     <h2>{totalPrice.toFixed(2)}</h2>
//                     <h2>{totalTickets}</h2>
//                   </div>
//                 </div>
//                 <div className="confirm-button">
//                   <button type="submit" disabled={loading} className="text-decoration-none text-white">
//                     {loading ? "Processing..." : "Continue to Pay"}
//                   </button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default PaymentForm;








// import React, { useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import Navbar from "./Navbar";
// import Footer from "./Footer";

// const PaymentForm = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { totalPrice, totalTickets,priceCurrency,eventName,eventDate } = location.state || { totalPrice: 0, totalTickets: 0 };

//   const [formData, setFormData] = useState({
//     cardNumber: "",
//     cardholderName: "",
//     cardSecurityCode: "",
//     expiryMonth: "",
//     expiryYear: "",
//     referenceId: "",
//     currency: "",
//   });
//   const [loading, setLoading] = useState(false);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     const paymentData = {
//       paymentType: "DEPOSIT",
//       card: {
//         cardNumber: formData.cardNumber,
//         cardholderName: formData.cardholderName,
//         cardSecurityCode: formData.cardSecurityCode,
//         expiryMonth: formData.expiryMonth,
//         expiryYear: formData.expiryYear,
//       },
//       customer: {
//         referenceId: localStorage.getItem('userid'),
//       },
//       amount: totalPrice,
//       // currency: formData.currency,
//       currency:priceCurrency,
      
//     };

//     try {
//       const response = await axios.post("https://backend.eurofestia.com/api/v1/payments/createpayment", paymentData);
//       console.log("Payment successful:", response.data);
//       setLoading(false);
//       // Redirect or show success message
//       navigate("/confirm",{state:response.data.paymentId});
//     } catch (error) {
//       console.error("Error processing payment:", error);
//       setLoading(false);
//       // Handle error
//     }
//   };
// console.log(totalPrice)
// // console.log(currency)
//   return (
//     <>
//       <Navbar />

//       <div className="container-fluid signin">
//         <div className="container">
//           <div className="signin-form row">
//             <div className="col-lg-7 mx-auto confirm">
//               <h3>Card Details </h3>
//               <div className="book-payment pt-3 text-center">
//                 <h4>Payment Using :</h4>
//                 <div className="payment-imgs">
//                   <img src="assets/master-card.png" alt="MasterCard" />
//                   <img src="assets/visa.png" alt="Visa" />
//                   <img src="assets/paypal.png" alt="PayPal" />
//                   <img src="assets/maestro.png" alt="Maestro" />
//                 </div>
//               </div>
//               <form onSubmit={handleSubmit}>
//                 <div className="row pay-row">
//                   <div className="col-lg-12 sign-item pay-item">
//                     <label htmlFor="cardholderName">Name On Card</label>
//                     <input
//                       type="text"
//                       name="cardholderName"
//                       placeholder="Card Holder name"
//                       value={formData.cardholderName}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   <div className="col-lg-12 sign-item pay-item">
//                     <label htmlFor="cardNumber">Card Number</label>
//                     <input
//                       type="text"
//                       name="cardNumber"
//                       placeholder="1234 1234 1234 1234"
//                       value={formData.cardNumber}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   <div className="col-lg-6 sign-item pay-item">
//                     <label htmlFor="expiryMonth">Expiration Month</label>
//                     <input
//                       type="text"
//                       name="expiryMonth"
//                       placeholder="MM"
//                       value={formData.expiryMonth}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   <div className="col-lg-6 sign-item pay-item">
//                     <label htmlFor="expiryYear">Expiration Year</label>
//                     <input
//                       type="text"
//                       name="expiryYear"
//                       placeholder="YYYY"
//                       value={formData.expiryYear}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   <div className="col-lg-6 sign-item pay-item">
//                     <label htmlFor="cardSecurityCode">CVV</label>
//                     <input
//                       type="password"
//                       name="cardSecurityCode"
//                       placeholder="000"
//                       value={formData.cardSecurityCode}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   {/* <div className="col-lg-6 sign-item pay-item">
//                     <label htmlFor="referenceId">Customer Reference ID</label>
//                     <input
//                       type="text"
//                       name="referenceId"
//                       placeholder="Reference ID"
//                       value={localStorage.getItem('userid')}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div> */}
//                   <div className="col-lg-12 sign-item pay-item">
//                     <label htmlFor="currency">Currency</label>
//                     <input
//                       type="text"
//                       name="currency"
//                       placeholder="Currency"
//                       // value={formData.currency}
//                       value={priceCurrency}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="book-item pt-3">
//                   <div>
//                     <h2>Total Price : </h2>
//                     <h2>Total Tickets : </h2>
//                   </div>
//                   <div>
//                     <h2>{totalPrice.toFixed(2)}</h2>
//                     <h2>{totalTickets}</h2>
//                   </div>
//                 </div>
//                 <div className="confirm-button">
//                   <button type="submit" disabled={loading} className="text-decoration-none text-white">
//                     {loading ? "Processing..." : "Continue to Pay"}
//                   </button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default PaymentForm;


// import React, { useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import Navbar from "./Navbar";
// import Footer from "./Footer";

// const PaymentForm = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { totalPrice, totalTickets, priceCurrency, eventName, eventDate,eventId,tickets,count } = location.state || { totalPrice: 0, totalTickets: 0 };
//   console.log(eventId,tickets,count)

//   const [formData, setFormData] = useState({
//     cardNumber: "",
//     cardholderName: "",
//     cardSecurityCode: "",
//     expiryMonth: "",
//     expiryYear: "",
//     referenceId: "",
//     currency: "",
//   });
//   const [loading, setLoading] = useState(false);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     const paymentData = {
//       paymentType: "DEPOSIT",
//       card: {
//         cardNumber: formData.cardNumber,
//         cardholderName: formData.cardholderName,
//         cardSecurityCode: formData.cardSecurityCode,
//         expiryMonth: formData.expiryMonth,
//         expiryYear: formData.expiryYear,
//       },
//       customer: {
//         referenceId: localStorage.getItem('userid'),
//       },
//       amount: totalPrice,
//       currency: priceCurrency,
//       eventName: eventName,
//       eventDate: eventDate,
//       eventId:eventId,
//       totalTickets: totalTickets
//     };

//     console.log('Sending payment data:', paymentData);

//     try {
//       const response = await axios.post("https://backend.eurofestia.com/api/v1/payments/createpayment", paymentData);
//       console.log("Payment successful:", response.data);

//       // Save booking information after successful payment
//       const bookingData = {
//         paymentId: response.data.paymentId,
//         eventId:eventId,
//         eventName: eventName,
//         eventDate: eventDate,
//         customer: localStorage.getItem('userid'),
//         currency: priceCurrency,
//         amount: totalPrice,
//         ticketquantity: totalTickets,
//         status: "Paid", // Assuming the status is 'Paid' after successful payment
//         paymentMethod: "Card" // Assuming the payment method is card
//       };
     

//       // await axios.post("https://backend.eurofestia.com/api/v1/payments/createbooking", bookingData);

//       setLoading(false);
//       // Redirect to confirmation page or show success message
//       navigate("/confirm", { state: response.data.paymentId });
//     } catch (error) {
//       console.error("Error processing payment:", error);
//       setLoading(false);
//       // Handle error
//     }
//   };

//   return (
//     <>
//       <Navbar />

//       <div className="container-fluid signin">
//         <div className="container">
//           <div className="signin-form row">
//             <div className="col-lg-7 mx-auto confirm">
//               <h3>Card Details </h3>
//               <div className="book-payment pt-3 text-center">
//                 <h4>Payment Using :</h4>
//                 <div className="payment-imgs">
//                 <img src={`${process.env.PUBLIC_URL}/assets/master-card.png`} alt="" />
//                   <img src={`${process.env.PUBLIC_URL}/assets/visa.png`} alt="" />
//                   <img src={`${process.env.PUBLIC_URL}/assets/paypal.png`} alt="" />
//                   <img src={`${process.env.PUBLIC_URL}/assets/maestro.png`} alt="" />
//                 </div>
//               </div>
//               <form onSubmit={handleSubmit}>
//                 <div className="row pay-row">
//                   <div className="col-lg-12 sign-item pay-item">
//                     <label htmlFor="cardholderName">Name On Card</label>
//                     <input
//                       type="text"
//                       name="cardholderName"
//                       placeholder="Card Holder name"
//                       value={formData.cardholderName}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   <div className="col-lg-12 sign-item pay-item">
//                     <label htmlFor="cardNumber">Card Number</label>
//                     <input
//                       type="text"
//                       name="cardNumber"
//                       placeholder="1234 1234 1234 1234"
//                       value={formData.cardNumber}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   <div className="col-lg-6 sign-item pay-item">
//                     <label htmlFor="expiryMonth">Expiration Month</label>
//                     <input
//                       type="text"
//                       name="expiryMonth"
//                       placeholder="MM"
//                       value={formData.expiryMonth}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   <div className="col-lg-6 sign-item pay-item">
//                     <label htmlFor="expiryYear">Expiration Year</label>
//                     <input
//                       type="text"
//                       name="expiryYear"
//                       placeholder="YYYY"
//                       value={formData.expiryYear}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   <div className="col-lg-6 sign-item pay-item">
//                     <label htmlFor="cardSecurityCode">CVV</label>
//                     <input
//                       type="password"
//                       name="cardSecurityCode"
//                       placeholder="000"
//                       value={formData.cardSecurityCode}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   <div className="col-lg-6 sign-item pay-item">
//                     <label htmlFor="currency">Currency</label>
//                     <input
//                       type="text"
//                       name="currency"
//                       placeholder="Currency"
//                       value={priceCurrency}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="book-item pt-3">
//                   <div>
//                     <h2>Total Price : </h2>
//                     <h2>Total Tickets : </h2>
//                   </div>
//                   <div>
//                     <h2>{totalPrice.toFixed(2)}</h2>
//                     <h2>{totalTickets}</h2>
//                   </div>
//                 </div>
//                 <div className="confirm-button">
//                   <button type="submit" disabled={loading} className="text-decoration-none text-white">
//                     {loading ? "Processing..." : "Continue to Pay"}
//                   </button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default PaymentForm;


import React, { useState ,useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';


const userid = localStorage.getItem('userid');


const PaymentForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { totalPrice, totalTickets, priceCurrency, eventName, eventDate, eventId, tickets, count } = location.state || { totalPrice: 0, totalTickets: 0 };
  console.log(eventId, tickets, count);
  // if (!userid) {
  //   iziToast.error({
  //     title: 'Error',
  //     message: 'Please Login as user to continue',
  //     position: 'topCenter'
  //   });
  //     navigate('/signin');
  //     const redirectTo = localStorage.getItem('redirectAfterLogin') || '/';
  //         localStorage.removeItem('redirectAfterLogin');
  //         navigate(redirectTo);

  //   }

  const [formData, setFormData] = useState({
    cardNumber: "",
    cardholderName: "",
    cardSecurityCode: "",
    expiryMonth: "",
    expiryYear: "",
    referenceId: "",
    currency: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(""); // New state for error message
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);
 



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(""); // Reset error message
    
  
    const paymentData = {
      paymentType: "DEPOSIT",
      card: {
        cardNumber: formData.cardNumber,
        cardholderName: formData.cardholderName,
        cardSecurityCode: formData.cardSecurityCode,
        expiryMonth: formData.expiryMonth,
        expiryYear: formData.expiryYear,
      },
      customer: {
        referenceId: userid,
      },
      amount: totalPrice,
      currency: priceCurrency,
      eventName: eventName,
      eventDate: eventDate,
      eventId: eventId,
      totalTickets: totalTickets,
      ticketDetails: tickets.map((ticket, index) => ({
        typeName: ticket.typeName,
        ticketPrice: ticket.ticketPrice,
        bookingfeePrice: ticket.bookingfeePrice,
        count: count[index],
        totalAmount: (ticket.ticketPrice + ticket.bookingfeePrice) * count[index]
      }))
    };
  
    console.log('Sending payment data:', paymentData);
  
    try {
      const response = await axios.post("https://backend.eurofestia.com/api/v1/payments/createpayment", paymentData);
            // const response = await axios.post("http://localhost:4000/api/v1/payments/createpayment", paymentData);

      console.log("Payment successful:", response.data);

      // Save booking information after successful payment
      const ticketDetails = tickets.map((ticket, index) => ({
        typeName: ticket.typeName,
        ticketPrice: ticket.ticketPrice,
        bookingfeePrice: ticket.bookingfeePrice,
        count: count[index],
        totalAmount: (ticket.ticketPrice + ticket.bookingfeePrice) * count[index]
      }));

      const bookingData = {
        paymentId: response.data.paymentId,
        eventId: eventId,
        eventName: eventName,
        eventDate: eventDate,
        // customer: localStorage.getItem('userid'),
        customer: userid,

        currency: priceCurrency,
        amount: totalPrice,
        ticketquantity: totalTickets,
        status: "Paid", // Assuming the status is 'Paid' after successful payment
        paymentMethod: "Card", // Assuming the payment method is card
        ticketDetails: ticketDetails // Include detailed ticket information
      };

      // await axios.post("https://backend.eurofestia.com/api/v1/bookings/createbooking", bookingData);
      setLoading(false);
      // Redirect to confirmation page or show success message
      navigate("/confirm", { state: response.data.paymentId });
    } catch (error) {
      console.error("Error processing payment:", error);
      setError("Payment failed. Please try again."); // Set error message
      iziToast.error({
        title: 'Error',
        message: 'Payment failed. Please try again.',
        position: 'topCenter'
      });
      setLoading(false);
      // Handle error
    }
  };


  return (
    <>
      <Navbar />

      <div className="container-fluid signin">
        <div className="container">
          <div className="signin-form row">
            <div className="col-lg-7 mx-auto confirm">
              <h3>Card Details </h3>
              <div className="book-payment pt-3 text-center">
                <h4>Payment Using :</h4>
                <div className="payment-imgs">
                  <img src={`${process.env.PUBLIC_URL}/assets/master-card.png`} alt="" />
                  <img src={`${process.env.PUBLIC_URL}/assets/visa.png`} alt="" />
                  <img src={`${process.env.PUBLIC_URL}/assets/paypal.png`} alt="" />
                  <img src={`${process.env.PUBLIC_URL}/assets/maestro.png`} alt="" />
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row pay-row">
                  <div className="col-lg-12 sign-item pay-item">
                    <label htmlFor="cardholderName">Name On Card</label>
                    <input
                      type="text"
                      name="cardholderName"
                      placeholder="Card Holder name"
                      value={formData.cardholderName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-lg-12 sign-item pay-item">
                    <label htmlFor="cardNumber">Card Number</label>
                    <input
                      type="text"
                      name="cardNumber"
                      placeholder="1234 1234 1234 1234"
                      value={formData.cardNumber}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-lg-6 sign-item pay-item">
                    <label htmlFor="expiryMonth">Expiration Month</label>
                    <input
                      type="text"
                      name="expiryMonth"
                      placeholder="MM"
                      value={formData.expiryMonth}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-lg-6 sign-item pay-item">
                    <label htmlFor="expiryYear">Expiration Year</label>
                    <input
                      type="text"
                      name="expiryYear"
                      placeholder="YYYY"
                      value={formData.expiryYear}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-lg-6 sign-item pay-item">
                    <label htmlFor="cardSecurityCode">CVV</label>
                    <input
                      type="password"
                      name="cardSecurityCode"
                      placeholder="000"
                      value={formData.cardSecurityCode}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-lg-6 sign-item pay-item">
                    <label htmlFor="currency">Currency</label>
                    <input
                      type="text"
                      name="currency"
                      placeholder="Currency"
                      value={priceCurrency}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="book-item pt-3">
                  <div>
                    <h2>Total Price : </h2>
                    <h2>Total Tickets : </h2>
                  </div>
                  <div>
                    <h2>{totalPrice.toFixed(2)}</h2>
                    <h2>{totalTickets}</h2>
                  </div>
                </div>
                <div className="confirm-button">
                  <button type="submit" disabled={loading} className="text-decoration-none text-white">
                    {loading ? "Processing..." : "Continue to Pay"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PaymentForm;
