import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import iziToast from "izitoast";

const Profile = () => {
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    country: "",
    postalcode: "",
  });

  const navigate = useNavigate();

  const userId = localStorage.getItem("userid");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://backend.eurofestia.com/api/v1/users/user/${userId}`);
        setUserData(response.data.user);
      } catch (error) {
        console.error("Error fetching user details", error);
      }
    };

    fetchUserData();
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     await axios.put(`https://backend.eurofestia.com/api/v1/users/user/${userId}`, userData);
  //     navigate(`/profile`);
  //   } catch (error) {
  //     console.error("Error updating user details", error);
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://backend.eurofestia.com/api/v1/users/user/${userId}`, userData);
      iziToast.success({
        title: 'Success',
        message: 'Profile updated successfully',
        position: "topCenter"
      });
      navigate(`/profile/${'userid'}`);
    } catch (error) {
      iziToast.error({
        title: 'Error',
        message: 'Error updating profile',
      });
      console.error("Error updating user details", error);
    }
  };

  return (
    <>
      <Navbar login={true} />
      <div className="container-fluid signin">
        <div className="container">
          <div className="signin-form">
            <div className="sign-heading px-3">
              <h1>Edit Profile</h1>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="sign-item">
                    <label htmlFor="firstname">First Name</label>
                    <input
                      type="text"
                      name="firstname"
                      placeholder="First Name"
                      value={userData.firstname}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sign-item">
                    <label htmlFor="lastname">Last Name</label>
                    <input
                      type="text"
                      name="lastname"
                      placeholder="Last Name"
                      value={userData.lastname}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sign-item">
                    <label htmlFor="phone">Phone no.</label>
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone number"
                      value={userData.phone}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sign-item">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={userData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sign-item">
                    <label htmlFor="address">Address</label>
                    <input
                      type="text"
                      name="address"
                      placeholder="Address"
                      value={userData.address}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sign-item">
                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      name="city"
                      placeholder="City"
                      value={userData.city}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sign-item">
                    <label htmlFor="country">Country</label>
                    <input
                      type="text"
                      name="country"
                      placeholder="Country"
                      value={userData.country}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sign-item">
                    <label htmlFor="postalcode">Postal Code</label>
                    <input
                      type="text"
                      name="postalcode"
                      placeholder="Postal Code"
                      value={userData.postalcode}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="signup-button pt-5 px-4">
                  <button type="submit">Update</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
