// import React, { useState, useEffect, useCallback } from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import axios from "axios";
// import europeCountryCodes from "../europeCountriesCode";
// import { fetchUpcomingEvents } from "../ticketService";

// const Events = () => {
//   const [showFilter, setShowFilter] = useState(false);
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [calendarVisible, setCalendarVisible] = useState(false);
//   const [selectingStartDate, setSelectingStartDate] = useState(true);
//   const [country, setCountry] = useState("");
//   const [city, setCity] = useState("");
//   const [events, setEvents] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [selectedGenre, setSelectedGenre] = useState("");
//   const [keyword, setKeyword] = useState("");


//   const genres = ["Pop", "Rock", "Jazz", "Classical", "Hip-Hop", "Country", "BALLADS/ROMANTIC","BLUES","CHILDREN'S MUSIC","Dance","Electronic","Folk","Rap","Holiday","Latin","Medieval/Renaissance","Metal", "New Age", "Other","Pop","R&B","Reggae","Religious","World"];

//   const toggleCalendar = () => {
//     setCalendarVisible(!calendarVisible);
//   };

//   const toggleFilter = () => {
//     setShowFilter(!showFilter);
//   };

//   const handleDateChange = (date) => {
//     if (selectingStartDate) {
//       setStartDate(date);
//       setEndDate(null); // Clear end date when selecting a new start date
//       setSelectingStartDate(false);
//     } else {
//       if (date >= startDate) {
//         setEndDate(date);
//       } else {
//         setStartDate(date);
//         setEndDate(null); // Clear end date if new start date is selected
//       }
//       setCalendarVisible(false);
//       setSelectingStartDate(true);
//     }
//   };

//   const handleSearchChange = (event) => {
//     const { name, value } = event.target;
//     if (name === "country") {
//       setCountry(value);
//     } else if (name === "city") {
//       setCity(value);
//     } else if (name === "genre") {
//       setSelectedGenre(value);
//     } else if (name === "keyword") {
//       setKeyword(value);
//     }
//   };
//   // const saveEvents = async (events) => {
//   //   try {
//   //     await axios.post('https://backend.eurofestia.com/api/v1/events/saveEvents', { events });
//   //     console.log('Events saved to the database');
//   //   } catch (error) {
//   //     console.error('Error saving events:', error);
//   //   }
//   // };

//   // const fetchEvents = useCallback(async (page = 0, initialFetch = false) => {
//   //   const countryCode = country ? `&countryCode=${europeCountryCodes[country]}` : "";
//   //   const cityQuery = city ? `&city=${city}` : "";
//   //   const genreQuery = selectedGenre ? `&classificationName=${selectedGenre}` : "";
//   //   const keywordQuery = keyword ? `&keyword=${keyword}` : "";
//   //   let dateRangeQuery = "";
//   // if (startDate && endDate) {
//   //   const startDateISO = startDate.toISOString();
//   //   const endDateISO = endDate.toISOString();
//   //   dateRangeQuery = `&startDateTime=${startDateISO}&endDateTime=${endDateISO}`;
//   // }
//   // // const europeanCountryCodes = Object.values(europeCountryCodes).join(',');


//   //   try {
//   //     const response = await axios.get(
//   //       `https://app.ticketmaster.com/discovery/v2/events.json?apikey=VgRLAbfHW62DJTQwk8TTumOFGOWVLRsw&locale=*&page=${page}${cityQuery}${countryCode}${genreQuery}${keywordQuery}${dateRangeQuery}${countryCode}`
//   //     );
//   //     console.log("API Response:", response.data); // Debug: Log API response

//   //     const fetchedEvents = response.data._embedded?.events || [];
//   //     if (initialFetch) {
//   //       setEvents(fetchedEvents);
//   //     } else {
//   //       setEvents((prevEvents) => [...prevEvents, ...fetchedEvents]);
//   //     }
//   //   } catch (error) {
//   //     console.error("Error fetching events:", error);
//   //   }
//   // }, [country, city, selectedGenre,keyword,startDate, endDate]);
//   const saveEvents = async (events) => {
//     try {
//       await axios.post('https://backend.eurofestia.com/api/v1/events/saveEvents', { events });
//       console.log('Events saved to the database');
//     } catch (error) {
//       console.error('Error saving events:', error);
//     }
//   };
  
//   const fetchEvents = useCallback(async (page = 0, initialFetch = false) => {
//     const countryCode = country ? `&countryCode=${europeCountryCodes[country]}` : "";
//     const cityQuery = city ? `&city=${city}` : "";
//     const genreQuery = selectedGenre ? `&classificationName=${selectedGenre}` : "";
//     const keywordQuery = keyword ? `&keyword=${keyword}` : "";
//     let dateRangeQuery = "";
//     if (startDate && endDate) {
//       const startDateISO = startDate.toISOString();
//       const endDateISO = endDate.toISOString();
//       dateRangeQuery = `&startDateTime=${startDateISO}&endDateTime=${endDateISO}`;
//     }
  
//     try {
//       const response = await axios.get(
//         `https://app.ticketmaster.com/discovery/v2/events.json?apikey=VgRLAbfHW62DJTQwk8TTumOFGOWVLRsw&locale=*&page=${page}${cityQuery}${countryCode}${genreQuery}${keywordQuery}${dateRangeQuery}`
//       );
//       console.log("API Response:", response.data);
  
//       const fetchedEvents = response.data._embedded?.events || [];
//       if (initialFetch) {
//         setEvents(fetchedEvents);
//       } else {
//         setEvents((prevEvents) => [...prevEvents, ...fetchedEvents]);
//       }
      
  
//       // Validate and map event data
//       const eventsToSave = fetchedEvents.map(event => ({
//         // eventId: event.id,
//         // name: event.name,
//         // date: event.dates.start.localDate,
//         // city: event._embedded.venues[0].city.name,
//         // country: event._embedded.venues[0].country.name,
//         // venue: event._embedded.venues[0].name,
//         // classification: selectedGenre,
//         // imageUrl: event.images[0].url,
//         // attraction:event._embedded.attractions[0].name,
//         // currency:event.priceRanges[0].currency,
//         // minprice:event.priceRanges[0].min,
//         // maxprice:event.priceRanges[0].max,
//         eventId: event.id,
//         name: event.name,
//         date: event.dates?.start?.localDate ?? 'Unknown Date',
//         city: event._embedded?.venues?.[0]?.city?.name ?? 'Unknown City',
//         country: event._embedded?.venues?.[0]?.country?.name ?? 'Unknown Country',
//         venue: event._embedded?.venues?.[0]?.name ?? 'Unknown Venue',
//         classification: selectedGenre ?? 'Unknown Genre',
//         imageUrl: event.images?.[0]?.url ?? 'No Image',
//         attraction: event._embedded?.attractions?.[0]?.name ?? 'Unknown Attraction',
//         // attractions: event._embedded?.attractions?.map(attraction => ({
//         //   name: attraction.name ?? 'Unknown Attraction',
//         //   imageUrl: attraction.images?.[0]?.url ?? 'No Image',
//         // })) ?? [],
//         currency: event.priceRanges?.[0]?.currency ?? 'Unknown Currency',
//         minprice: event.priceRanges?.[0]?.min ?? "0",
//         maxprice: event.priceRanges?.[0]?.max ?? "0",

        
//       }));
  
//       // Check if eventsToSave is a valid array and has elements
//       if (Array.isArray(eventsToSave) && eventsToSave.length > 0) {
//         saveEvents(eventsToSave);
//       } else {
//         console.error('No valid events to save');
//       }
  
//     } catch (error) {
//       console.error("Error fetching events:", error);
//     }
//   }, [country, city, selectedGenre, keyword, startDate, endDate]);
  

//   // useEffect(() => {
//   //   fetchEvents(0, true); // Initial fetch of events on component mount
//   // }, [fetchEvents]);
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const upcomingEvents = await fetchUpcomingEvents();
//         setEvents(upcomingEvents);
//       } catch (error) {
//         console.error("Error fetching events:", error);
//       }
//     };

//     fetchData();
//   }, []); // Empty dependency array to run only once on component mount

//   const handleFilterSubmit = () => {
//     setEvents([]); // Reset events when filters change
//     setCurrentPage(0); // Reset page number when filters change
//     fetchEvents(0, true);
//   };

//   const handleLoadMore = () => {
//     const nextPage = currentPage + 1;
//     setCurrentPage(nextPage);
//     fetchEvents(nextPage);
//   };
//   const clearSelection = () => {
//     setStartDate(null);
//     setEndDate(null);
//     setSelectingStartDate(true);
//   };


//   return (
//     <>
//       <Navbar />

//       <div className="container-fluid events">
//         <div className="row">
//           { <div className="col-lg-5 event-i">
//             {/* <img src="assets/taylor.png" alt="" /> */}
//           </div> }
          
//           <div className="col-lg-8 event-right mx-auto">
//             <h1>Explore The Events</h1>
//             <h1>Of</h1>
//             <h1>Europe</h1>
            

//             <div className="d-flex justify-content-center">
//               <Link to="/details">
//                 {/* <button>Book Now</button> */}
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div>


//       <div className="container-fluid event-main">
//         <div className="container event-search">
//           <div className="input-group event-group">
//             <div className="input-group-prepend event-append">
//               <span className="input-group-text event-text" id="basic-addon2">
//                 <i className="bi bi-search"></i>
//               </span>
//             </div>
//             <input
//               type="text"
//               className="form-control"
//               placeholder="Search"
//               aria-label="search"
//               aria-describedby="basic-addon2"
//               name="keyword"
//               value={keyword}
//               onChange={handleSearchChange}
//             />
//             <div className="input-group-append event-append">
//               <span className="input-group-text event-text" id="basic-addon2">
//                 <i className="bi bi-send"></i>
//               </span>
//             </div>
//           </div>
//           <div className="filter-icons">
//             <i className="bi bi-calendar4-week" onClick={toggleCalendar}></i>
//             <i className="bi bi-funnel mx-5" onClick={toggleFilter}></i>
//           </div>
//           {/* {calendarVisible && (
//             <div className="calendar">
//               <DatePicker
//                 selected={selectingStartDate ? startDate : endDate}
//                 onChange={handleDateChange}
//                 startDate={startDate}
//                 endDate={endDate}
//                 selectsStart={selectingStartDate}
//                 selectsEnd={!selectingStartDate}
//                 inline
//               />
//             </div>
//           )}
//         </div> */}
//                {calendarVisible && (
//             <div className="calendar">
//               <div className="calendar-labels">
//                 <label>
//                   <span>Start Date : </span>{" "}
//                   {startDate ? startDate.toLocaleDateString() : "Not Selected"}
//                 </label>
//                 <label>
//                   <span>End Date : </span>{" "}
//                   {endDate ? endDate.toLocaleDateString() : "Not Selected"}
//                 </label>
//               </div>
//               <DatePicker
//                 selected={selectingStartDate ? startDate : endDate}
//                 onChange={handleDateChange}
//                 startDate={startDate}
//                 endDate={endDate}
//                 selectsStart={selectingStartDate}
//                 selectsEnd={!selectingStartDate}
//                 inline
//                 className="calendar-relative"
//               />
//             <div className="calendar-column">
//             <div className="calendar-button">
//                 <button onClick={clearSelection}>Clear</button>
//                 <button onClick={handleFilterSubmit}>Filter by Date</button>
//               </div>
//             </div>
//             </div>
//           )}
//         </div>

        

//         {showFilter && (
//           <div className="container d-flex justify-content-end">
//             <div className="col-lg-8 filters">
//               <h1>Filters</h1>
//               <div className="row">
//                 <div className="col-lg-3 filter-column p-3">
//                 {/* <label >Country</label> */}
//                   <input
//                     type="text"
//                     name="country"
//                     className="form-control"
//                     placeholder="Country"
//                     value={country}
//                     onChange={handleSearchChange}
//                   />
//                 </div>
//                 <div className="col-lg-3 filter-column p-3">
//                   <input
//                     type="text"
//                     name="city"
//                     className="form-control"
//                     placeholder="City"
//                     value={city}
//                     onChange={handleSearchChange}
//                   />
//                 </div>
//                 <div className="col-lg-3 p-3">
//                   <select name="genre" value={selectedGenre} onChange={handleSearchChange}>
//                     <option value="">Select Genre</option>
//                     {genres.map((genre) => (
//                       <option key={genre} value={genre}>{genre}</option>
//                     ))}
//                   </select>
//                 </div>
//                 <div className="col-lg-3 filter-column p-3">
//                   <button onClick={handleFilterSubmit}>Apply Filters</button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         )}

//         <div className="container event-month">
//           <i >
//             {" "}
//             <span className="px-5"> </span>
//           </i>
//           {/* <i className="bi bi-chevron-right"></i> */}
//         </div>

//         <div className="container">
//           {Array.isArray(events) && events.map((event) => (
//             console.log(event.id),
//             <div className="row event-card" key={event.id}>
//               <div className="col-lg-1 content1">
//                 <h2>
//                   {new Date(event.dates.start.localDate).toLocaleString('en-US', { weekday: 'short' })} <br />
//                   {new Date(event.dates.start.localDate).getDate()}
//                 </h2>
//               </div>
//               <div className="col-lg-6 content2 pe-2">
//                 <h4>{new Date(event.dates.start.localDate).toLocaleDateString()}</h4>
//                 <h1>{event.name}</h1>
//                 <h3>{event._embedded.venues[0].name}</h3>
//                 <h3>{event._embedded.venues[0].city.name}, {event._embedded.venues[0].country.name}</h3>
//                 {/* <p>{event.info}</p> */}
//               </div>
//               <div className="col-lg-3 content3">
//                 <img src={event.images[0].url} alt={event.name} />
//               </div>
              
//               <div className="col-lg-2 content4">
//                 <Link to={`/details/${event.id}`} className="text-decoration-none text-white">
//                   <h5>
//                     Details <i className="bi bi-chevron-right"></i>
//                   </h5>
//                 </Link>
//               </div>
//             </div>
//           ))}
//           {/* <div className="container-fluid events">
//         {events.map((event) => (
//           <div className="row" key={event.id}>
//             <div className="col-lg-5 event-i">
//               <img src={event.image} alt={event.name} />
//             </div>
//             <div className="col-lg-7 event-right">
//               <h1>{event.name}</h1>
//               <h2>{event.date}</h2>
//               <h2>{event.location}</h2>
//               <div>
//                 <Link to="/details">
//                   <button>Book Now</button>
//                 </Link>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div> */}

//           <div className="event-button">
//             <button onClick={handleLoadMore}>Load More</button>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Events;




import React, { useState, useEffect, useCallback } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

import Footer from "./Footer";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import europeCountryCodes from "../europeCountriesCode";
// import { fetchUpcomingEvents } from "../ticketService";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';


const Events = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [selectingStartDate, setSelectingStartDate] = useState(true);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedGenre, setSelectedGenre] = useState("");
  const [keyword, setKeyword] = useState("");


  const genres = ["Pop", "Rock", "Jazz", "Classical", "Hip-Hop", "Country", "BALLADS/ROMANTIC","BLUES","CHILDREN'S MUSIC","Dance","Electronic","Folk","Rap","Holiday","Latin","Medieval/Renaissance","Metal", "New Age", "Other","Pop","R&B","Reggae","Religious","World"];

  const toggleCalendar = () => {
    setCalendarVisible(!calendarVisible);
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleDateChange = (date) => {
    if (selectingStartDate) {
      setStartDate(date);
      setEndDate(null); // Clear end date when selecting a new start date
      setSelectingStartDate(false);
    } else {
      if (date >= startDate) {
        setEndDate(date);
      } else {
        setStartDate(date);
        setEndDate(null); // Clear end date if new start date is selected
      }
      setCalendarVisible(false);
      setSelectingStartDate(true);
    }
  };

  const handleSearchChange = (event) => {
    const { name, value } = event.target;
    if (name === "country") {
      setCountry(value);
    } else if (name === "city") {
      setCity(value);
    } else if (name === "genre") {
      setSelectedGenre(value);
    } else if (name === "keyword") {
      setKeyword(value);
    }
  };
  // const saveEvents = async (events) => {
  //   try {
  //     await axios.post('https://backend.eurofestia.com/api/v1/events/saveEvents', { events });
  //     console.log('Events saved to the database');
  //   } catch (error) {
  //     console.error('Error saving events:', error);
  //   }
  // };

  // const fetchEvents = useCallback(async (page = 0, initialFetch = false) => {
  //   const countryCode = country ? `&countryCode=${europeCountryCodes[country]}` : "";
  //   const cityQuery = city ? `&city=${city}` : "";
  //   const genreQuery = selectedGenre ? `&classificationName=${selectedGenre}` : "";
  //   const keywordQuery = keyword ? `&keyword=${keyword}` : "";
  //   let dateRangeQuery = "";
  // if (startDate && endDate) {
  //   const startDateISO = startDate.toISOString();
  //   const endDateISO = endDate.toISOString();
  //   dateRangeQuery = `&startDateTime=${startDateISO}&endDateTime=${endDateISO}`;
  // }
  // // const europeanCountryCodes = Object.values(europeCountryCodes).join(',');


  //   try {
  //     const response = await axios.get(
  //       `https://app.ticketmaster.com/discovery/v2/events.json?apikey=VgRLAbfHW62DJTQwk8TTumOFGOWVLRsw&locale=*&page=${page}${cityQuery}${countryCode}${genreQuery}${keywordQuery}${dateRangeQuery}${countryCode}`
  //     );
  //     console.log("API Response:", response.data); // Debug: Log API response

  //     const fetchedEvents = response.data._embedded?.events || [];
  //     if (initialFetch) {
  //       setEvents(fetchedEvents);
  //     } else {
  //       setEvents((prevEvents) => [...prevEvents, ...fetchedEvents]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching events:", error);
  //   }
  // }, [country, city, selectedGenre,keyword,startDate, endDate]);
  const saveEvents = async (events) => {
    try {
      await axios.post('https://backend.eurofestia.com/api/v1/events/saveEvents', { events });
      console.log('Events saved to the database');
    } catch (error) {
      console.error('Error saving events:', error);
    }
  };
  
  const fetchEvents = useCallback(async (page = 0, initialFetch = false) => {
    const countryCode = country ? `&countryCode=${europeCountryCodes[country]}` : "";
    const cityQuery = city ? `&city=${city}` : "";
    const genreQuery = selectedGenre ? `&classificationName=${selectedGenre}` : "";
    const keywordQuery = keyword ? `&keyword=${keyword}` : "";
    let dateRangeQuery = "";
  
    if (startDate && endDate) {
      // const startDateISO = new Date(startDate).toISOString().slice(0, -5) + 'Z' : '';
      // const endDateISO = new Date(endDate).toISOString().slice(0, -5) + 'Z' : '';
      const startDateISO = startDate ? startDate.toISOString().slice(0, -5) + 'Z' : '';
    const endDateISO = endDate ? endDate.toISOString().slice(0, -5) + 'Z' : '';
      dateRangeQuery = `&startDateTime=${startDateISO}&endDateTime=${endDateISO}`;
    }
  
    const url = `https://app.ticketmaster.com/discovery/v2/events.json?apikey=VgRLAbfHW62DJTQwk8TTumOFGOWVLRsw&locale=*&page=${page}${cityQuery}${countryCode}${genreQuery}${keywordQuery}${dateRangeQuery}`;
  
    console.log("API URL:", url); // Debug: Log API request URL
  
    try {
      const response = await axios.get(url);
      console.log("API Response:", response.data);
  
      const fetchedEvents = response.data._embedded?.events || [];
      if (initialFetch) {
        setEvents(fetchedEvents);
      } else {
        setEvents((prevEvents) => [...prevEvents, ...fetchedEvents]);
      }
  
      const eventsToSave = fetchedEvents.map(event => ({
        eventId: event.id,
        name: event.name,
        date: event.dates?.start?.localDate ?? 'Unknown Date',
        time:event.dates?.start?.localTime ?? 'Unknown Time',
        city: event._embedded?.venues?.[0]?.city?.name ?? 'Unknown City',
        country: event._embedded?.venues?.[0]?.country?.name ?? 'Unknown Country',
        venue: event._embedded?.venues?.[0]?.name ?? 'Unknown Venue',
        classification: selectedGenre ?? 'Unknown Genre',
        imageUrl: event.images?.[0]?.url ?? 'No Image',
        attraction: event._embedded?.attractions?.[0]?.name ?? 'Unknown Attraction',
        currency: event.priceRanges?.[0]?.currency ?? 'Unknown Currency',
        minprice: event.priceRanges?.[0]?.min ?? "0",
        maxprice: event.priceRanges?.[0]?.max ?? "0",
      }));
  
      if (Array.isArray(eventsToSave) && eventsToSave.length > 0) {
        saveEvents(eventsToSave);
        iziToast.success({
          title: 'Success',
          message: `${eventsToSave.length} events saved to the database`
        });
      } else {
        iziToast.warning({
          title: 'No Events',
          message: 'No valid events to save'
        });
        console.error('No valid events to save');
      }
  
    } catch (error) {
      iziToast.error({
        title: 'Error',
        message: 'Error fetching events'
      });
      console.error("Error fetching events:", error);
    }
  }, [country, city, selectedGenre, keyword, startDate, endDate]);
  
  

  // useEffect(() => {
  //   fetchEvents(0, true); // Initial fetch of events on component mount
  // }, [fetchEvents]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const upcomingEvents = await fetchUpcomingEvents();
        // setEvents(upcomingEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run only once on component mount

  const handleFilterSubmit = () => {
    console.log("Start Date:", startDate);
  console.log("End Date:", endDate);
    setEvents([]); // Reset events when filters change
    setCurrentPage(0); // Reset page number when filters change
    fetchEvents(0, true);
  };

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    fetchEvents(nextPage);
  };
  const clearSelection = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectingStartDate(true);
  };


  return (
    <>
      {/* <Navbar />
       */}
      <Sidebar />
      <div className="home">


      <div className="container-fluid events">
        <div className="row">
          <div className="col-lg-8 event-right mx-auto">
            <h1>Save Europe's Musical Events </h1>
            <h1></h1>
            <h1></h1>
            

          </div>
        </div>
      </div>


      <div className="container-fluid event-main">
        <div className="container event-search">
          <h3>Select the Date range ,Loction and Genre of events to Store <i class="bi bi-arrow-right-short"></i></h3>
          {/* <div className="input-group event-group">
            <div className="input-group-prepend event-append">
              <span className="input-group-text event-text" id="basic-addon2">
                <i className="bi bi-search"></i>
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              aria-label="search"
              aria-describedby="basic-addon2"
              name="keyword"
              value={keyword}
              onChange={handleSearchChange}
            />
            <div className="input-group-append event-append">
              <span className="input-group-text event-text" id="basic-addon2">
                <i className="bi bi-send"></i>
              </span>
            </div>
          </div> */}
          <div className="filter-icons">
            <i className="bi bi-calendar4-week" onClick={toggleCalendar}></i>
            <i className="bi bi-funnel mx-5" onClick={toggleFilter}></i>
          </div>
          {/* {calendarVisible && (
            <div className="calendar">
              <DatePicker
                selected={selectingStartDate ? startDate : endDate}
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsStart={selectingStartDate}
                selectsEnd={!selectingStartDate}
                inline
              />
            </div>
          )}
        </div> */}
               {calendarVisible && (
            <div className="calendar">
              <div className="calendar-labels">
                <label>
                  <span>Start Date : </span>{" "}
                  {startDate ? startDate.toLocaleDateString() : "Not Selected"}
                </label>
                <label>
                  <span>End Date : </span>{" "}
                  {endDate ? endDate.toLocaleDateString() : "Not Selected"}
                </label>
              </div>
              <DatePicker
                selected={selectingStartDate ? startDate : endDate}
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsStart={selectingStartDate}
                selectsEnd={!selectingStartDate}
                inline
                className="calendar-relative"
              />
            <div className="calendar-column">
            <div className="calendar-button">
                <button onClick={clearSelection}>Clear</button>
                <button onClick={handleFilterSubmit}>Filter by Date</button>
              </div>
            </div>
            </div>
          )}
        </div>

        

        {showFilter && (
          <div className="container d-flex justify-content-end">
            <div className="col-lg-8 filters">
              <h1>Filters</h1>
              <div className="row">
                <div className="col-lg-3 filter-column p-3">
                {/* <label >Country</label> */}
                  <input
                    type="text"
                    name="country"
                    className="form-control"
                    placeholder="Country"
                    value={country}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="col-lg-3 filter-column p-3">
                  <input
                    type="text"
                    name="city"
                    className="form-control"
                    placeholder="City"
                    value={city}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="col-lg-3 p-3">
                  <select name="genre" value={selectedGenre} onChange={handleSearchChange}>
                    <option value="">Select Genre</option>
                    {genres.map((genre) => (
                      <option key={genre} value={genre}>{genre}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-3 filter-column p-3">
                  <button onClick={handleFilterSubmit}>Apply Filters</button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="container event-month">
          <i >
            {" "}
            <span className="px-5"> </span>
          </i>
          {/* <i className="bi bi-chevron-right"></i> */}
        </div>

        <div className="container">
          {Array.isArray(events) && events.map((event) => (
            console.log(event.id),
            <div className="row event-card" key={event.id}>
              <div className="col-lg-1 content1">
                <h2>
                  {new Date(event.dates.start.localDate).toLocaleString('en-US', { weekday: 'short' })} <br />
                  {new Date(event.dates.start.localDate).getDate()}
                </h2>
              </div>
              <div className="col-lg-6 content2 pe-2">
                <h4>{new Date(event.dates.start.localDate).toLocaleDateString()}</h4>
                <h1>{event.name}</h1>
                <h3>{event._embedded.venues[0].name}</h3>
                <h3>{event._embedded.venues[0].city.name}, {event._embedded.venues[0].country.name}</h3>
                {/* <p>{event.info}</p> */}
              </div>
              <div className="col-lg-3 content3">
                <img src={event.images[0].url} alt={event.name} />
              </div>
              
              <div className="col-lg-2 content4">
                <Link to={`/details/${event.id}`} className="text-decoration-none text-white">
                  <h5>
                    Details <i className="bi bi-chevron-right"></i>
                  </h5>
                </Link>
              </div>
            </div>
          ))}
          {/* <div className="container-fluid events">
        {events.map((event) => (
          <div className="row" key={event.id}>
            <div className="col-lg-5 event-i">
              <img src={event.image} alt={event.name} />
            </div>
            <div className="col-lg-7 event-right">
              <h1>{event.name}</h1>
              <h2>{event.date}</h2>
              <h2>{event.location}</h2>
              <div>
                <Link to="/details">
                  <button>Book Now</button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div> */}
        <div className="event-box">
          <div className="event-button">
            <button onClick={handleLoadMore}>Load More</button>
          </div>
          </div>
        </div>
      </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Events;

