import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import axios from "axios";

const TicketDetails = () => {
  const { eventId } = useParams();
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });

    const fetchTickets = async () => {
      try {
        const response = await axios.get(`https://backend.eurofestia.com/api/v1/tickets/ticket/${eventId}`);
        // const response = await axios.get(`http://localhost:4000/api/v1/tickets/ticket/${eventId}`);

        setTickets(response.data.tickets);
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    };

    fetchTickets();
  }, [eventId]);

  return (
    <>
      <Sidebar />
      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1> Ticket Details</h1>
          </div>
          
      <div className="container-fluid contact-content">
        <div className="container">
          <div className="booking-heading">
            {/* <h1>Ticket Details</h1> */}
          </div>
          <div className="row book-row d-flex justify-content-between">
            <div className="col-lg-6">
              <div className="book-left">
                {tickets.map((ticket) => (
                  <div key={ticket.eventId}>
                    <h1>{ticket.typeName} - {ticket.type}</h1>
                    <div>
                      <li>
                        <h4>Details :{ticket.comment}</h4>
                      </li>
                      <li>
                        <span>Price: </span> {ticket.priceCurrency} {ticket.ticketPrice}
                      </li>
                      <li>
                        <span>Booking Fee: </span> {/*ticket.bookingfeeCurrency*/} {ticket.priceCurrency} {ticket.bookingfeePrice}
                      </li>
                      <li>
                        {/* <span>Comment: </span>   */}
                      </li>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default TicketDetails;
