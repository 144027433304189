// // // import React, { useState,useContext } from "react";
// // // import Navbar from "./Navbar";
// // // import { Link ,useNavigate} from "react-router-dom";
// // // import iziToast from "izitoast";
// // // // import AuthContext from '../AuthContex';
// // // import { useAuth } from "../AuthContex";

// // // const Signup = () => {
// // //   // const { apipath } = useContext(AuthContext);
// // //   const {apipath}=useAuth();

// // //   const [showPassword, setShowPassword] = useState(false);
// // //   const navigate = useNavigate();
// // //   const [formData, setFormData] = useState({
// // //     firstname:"",
// // //     lastname:"",
// // //     phone:"",
// // //     email:"",
// // //     address:"",
// // //     city:"",
// // //     country:"",
// // //     postalcode:"",
// // //     password:"",
// // //     confirmpassword:"",

// // //   });

// // //   const handleSubmit = async (event) => {
// // //     event.preventDefault();

// // //     try {
// // //       const response = await fetch(
// // //         `https://backend.eurofestia.com/api/v1/users/register`,
// // //         {
// // //           method: "POST",
// // //           headers: {
// // //             "Content-Type": "application/json",
// // //           },
// // //           body: JSON.stringify(formData)
// // //         }
// // //       );

// // //       // if (!response.ok) {
// // //       //   // Handle HTTP errors
// // //       //   const errorResult = await response.json();
// // //       //   if (response.status === 400 && errorResult.error && errorResult.error.message.includes("User already exists")) {
// // //       //     iziToast.error({
// // //       //       message: "User already exists. Please use a different email.",
// // //       //       position: "topCenter"
// // //       //     });
// // //       //   } else {
// // //       //     iziToast.error({
// // //       //       message: `Registration failed: ${errorResult.error.message}` || "Unknown error",
// // //       //       position: "topCenter"
// // //       //     });
// // //       //   }
// // //       //   return;
// // //       // }
// // //       const result = await response.json();
// // //       console.log(response);

// // //       if (result.success ) {
// // //         iziToast.success({
// // //           message: "Registration successful",
// // //           position: "topCenter"
// // //         });
// // //         console.log("Registration successful");
// // //         navigate("/signin");
// // //       }
// // //       else {
// // //         if (result.error && result.error.message.includes("User already exists")) {
// // //           iziToast.error({
// // //             message: "User already exists. Please use a different email.",
// // //             position: "topCenter"
// // //           });
// // //         }
// // //         else {
// // //           // console.log(result.error.message)
// // //           iziToast.error({
// // //             message: `Registration failed:  ${result.error.message}`,
// // //             position: "topCenter"
// // //           });
// // //         }
// // //       }
// // //     }
        
    
// // //       catch (error) {
// // //       iziToast.error({
// // //         message: "Error during registration Please use a different email.",
// // //         position: "topCenter"
// // //       });
// // //       console.error("Error during registration:", error.message);
// // //     }
// // //   };
// // //   const handleChange = (event) => {
// // //     const { name, value, type, checked } = event.target;
// // //     setFormData((prevData) => ({
// // //       ...prevData,
// // //       [name]: type === "checkbox" ? checked : value,
// // //     }));
// // //   };



// // //   const togglePassword = () => {
// // //     setShowPassword(!showPassword);
// // //   };

// // //   return (
// // //     <>
// // //       <Navbar />

// // //       <div className="container-fluid signin">
// // //         <div className="container">
// // //           <div className="signin-form">
// // //             <div className="sign-heading px-3">
// // //               <h1>Register</h1>
// // //               <p>
// // //                 Welcome! Please enter your information to create your account.
// // //               </p>
// // //             </div>
// // //             <form onSubmit={handleSubmit}>
// // //               <div className="row">
// // //                 <div className="col-lg-6">
// // //                   <div className="sign-item">
// // //                     <label htmlFor="firstname">First Name</label>
// // //                     <input type="text"
// // //                             name="firstname"
// // //                             placeholder="Enter Your first name" 
// // //                             value={formData.firstname} 
// // //                             onChange={handleChange} 
// // //                             required
// // //                       />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-6">
// // //                   <div className="sign-item">
// // //                     <label htmlFor="lastname">Last Name</label>
// // //                     <input type="text"
// // //                             name="lastname"
// // //                             placeholder="Enter Your last name" 
// // //                             value={formData.lastname} 
// // //                             onChange={handleChange} 
// // //                             required
// // //                           />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-6">
// // //                   <div className="sign-item">
// // //                     <label htmlFor="phone">Phone no.</label>
// // //                     <input type="text" 
// // //                             placeholder="Enter Your Phone number"
// // //                             name="phone"
// // //                             // placeholder="Enter Your first name" 
// // //                             value={formData.phone} 
// // //                             onChange={handleChange} 
// // //                             required />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-6">
// // //                   <div className="sign-item">
// // //                     <label htmlFor="email">Email</label>
// // //                     <input type="text" 
// // //                             placeholder="Enter Your email"
// // //                             name="email"
// // //                             // placeholder="Enter Your first name" 
// // //                             value={formData.email} 
// // //                             onChange={handleChange} 
// // //                             required />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-6">
// // //                   <div className="sign-item">
// // //                     <label htmlFor="address">Address</label>
// // //                     <input type="text" 
// // //                             placeholder="Enter Your address"
// // //                             name="address"
// // //                             // placeholder="Enter Your first name" 
// // //                             value={formData.address} 
// // //                             onChange={handleChange} 
// // //                             required />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-6">
// // //                   <div className="sign-item">
// // //                     <label htmlFor="city">City</label>
// // //                     <input type="text" 
// // //                             placeholder="Enter Your City" 
// // //                             name="city"
// // //                             // placeholder="Enter Your first name" 
// // //                             value={formData.city} 
// // //                             onChange={handleChange} 
// // //                             required/>
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-6">
// // //                   <div className="sign-item">
// // //                     <label htmlFor="country">Country</label>
// // //                     <input type="text" 
// // //                             placeholder="Enter Your Country"
// // //                             name="country"
// // //                             // placeholder="Enter Your first name" 
// // //                             value={formData.country} 
// // //                             onChange={handleChange} 
// // //                             required />
// // //                   </div>
// // //                 </div>

// // //                 <div className="col-lg-6">
// // //                   <div className="sign-item">
// // //                     <label htmlFor="postalcode">Postal Code</label>
// // //                     <input type="text" 
// // //                             placeholder="Enter Your postal code"
// // //                             name="postalcode"
// // //                             // placeholder="Enter Your first name" 
// // //                             value={formData.postalcode} 
// // //                             onChange={handleChange} 
// // //                             required />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-6">
// // //                   <div className="sign-item">
// // //                     <label htmlFor="password">Password</label>
// // //                     <div class="input-group sign-group">
// // //                       <input
// // //                         type={showPassword ? "text" : "password"}
// // //                         name="password"
// // //                         class="form-control"
// // //                         placeholder="Enter Password"
// // //                         aria-label="password"
// // //                         aria-describedby="basic-addon2"
// // //                         // class="form-control"
// // //                           value={formData.password}
// // //                           onChange={handleChange}
// // //                           required
// // //                       />
// // //                       <div class="input-group-append sign-append">
// // //                         <span
// // //                           class="input-group-text sign-text"
// // //                           id="basic-addon2"
// // //                           onClick={togglePassword}
// // //                         >
// // //                           <i className="bi bi-eye"></i>
// // //                         </span>
// // //                       </div>
// // //                     </div>
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-6">
// // //                   <div className="sign-item">
// // //                     <label htmlFor="confirmpassword">Confirm Password</label>
// // //                     <div class="input-group sign-group">
// // //                       <input
// // //                         type={showPassword ? "text" : "password"}
// // //                         name="confirmpassword"
// // //                         class="form-control"
// // //                         placeholder="Confirm Password"
// // //                         aria-label="password"
// // //                         aria-describedby="basic-addon2"
// // //                         value={formData.confirmpassword}
// // //                           onChange={handleChange}
// // //                           required
// // //                       />
// // //                       <div class="input-group-append sign-append">
// // //                         <span
// // //                           class="input-group-text sign-text"
// // //                           id="basic-addon2"
// // //                           onClick={togglePassword}
// // //                         >
// // //                           <i className="bi bi-eye"></i>
// // //                         </span>
// // //                       </div>
// // //                     </div>
// // //                   </div>
// // //                 </div>

// // //                 <div className="signup-button pt-5 d-flex justify-content-center">
// // //                   {/* <Link to="/signin"> */}
// // //                     <button>Continue</button>
// // //                   {/* </Link> */}
// // //                 </div>

// // //                 <h6>
// // //                   Already Have an Account ?{" "}
// // //                   <Link to="/signin" className="text-decoration-none">
// // //                     <span>Login Here</span>
// // //                   </Link>
// // //                 </h6>
// // //               </div>
// // //             </form>
// // //           </div>
// // //         </div>
// // //       </div>
// // //     </>
// // //   );
// // // };

// // // export default Signup;




import React, { useState ,useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import iziToast from "izitoast";
import { useAuth } from "../AuthContex";
import Navbar from "./Navbar";

const Signup = () => {
  const { apipath } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    country: "",
    postalcode: "",
    password: "",
    confirmpassword: "",
    otp: "",
  });
  const [isOtpSent, setIsOtpSent] = useState(false);
  const countries = ['Albania','Andorra','Austria','Belarus','Bosnia and Herzegovina','Bulgaria','Croatia','Czech Republic (Czechia)','Denmark',
    'Estonia','Finland','France','Germany','Greece','Holy See','Hungary','Iceland','Ireland','Italy','Latvia','Liechtenstein','Lithuania',
    'Luxembourg','Malta','Moldova','Monaco','Montenegro','Netherlands','North Macedonia','Norway','Poland','Portugal','Romania','Russia',
    '	San Marino','Serbia','Slovakia','Slovenia','Spain','Sweden','Switzerland','Ukraine','United Kingdom'
    // Add more countries as needed
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.password !== formData.confirmpassword) {
      iziToast.error({
        message: "Passwords do not match",
        position: "topCenter",
      });
      return;
    }

    if (!isOtpSent) {
      // Register user and send OTP
      try {
        const response = await fetch(
          `https://backend.eurofestia.com/api/v1/users/register`,
          // 'http://localhost:4000/api/v1/users/register',
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        const result = await response.json();

        if (result.success) {
          iziToast.success({
            message: "Registration successful. Please check your email for the OTP.",
            position: "topCenter",
          });
          setIsOtpSent(true);
          localStorage.setItem("signupEmail", formData.email);
          localStorage.setItem("otpSent", "true");
        } else {
          if (result.error && response.status === 400) {
            iziToast.error({
              message: "User already exists. Please use a different email.",
              position: "topCenter",
            });
          } else {
            iziToast.error({
              message: result.error ? result.error.message : "Unknown error",
              position: "topCenter",
            });
          }
        }
      } catch (error) {
        iziToast.error({
          message: "Error during registration. Please try again.",
          position: "topCenter",
        });
        console.error("Error during registration:", error.message);
      }
    } else {
      // Verify OTP
      try {
        const response = await fetch(
          `https://backend.eurofestia.com/api/v1/users/verify-otp`,
          // 'http://localhost:4000/api/v1/users/verify-otp',
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: formData.email, otp: formData.otp }),
          }
        );

        const result = await response.json();

        if (result.success) {
          iziToast.success({
            message: "OTP verified successfully. Your account is now verified.",
            position: "topCenter",
          });
          localStorage.removeItem("signupEmail");
          localStorage.removeItem("otpSent");
          navigate("/signin");
        } else {
          iziToast.error({
            message: result.error ? result.error.message : "Invalid OTP",
            position: "topCenter",
          });
        }
      } catch (error) {
        iziToast.error({
          message: "Error during OTP verification. Please try again.",
          position: "topCenter",
        });
        console.error("Error during OTP verification:", error.message);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid signin">
        <div className="container">
          <div className="signin-form">
            <div className="sign-heading px-3">
              <h1>Register</h1>
              <p>
                Welcome! Please enter your information to create your account.
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                {!isOtpSent ? (
                  <>
                    <div className="col-lg-6">
                      <div className="sign-item">
                        <label htmlFor="firstname">First Name</label>
                        <input
                          type="text"
                          name="firstname"
                          placeholder="Enter Your first name"
                          value={formData.firstname}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="sign-item">
                        <label htmlFor="lastname">Last Name</label>
                        <input
                          type="text"
                          name="lastname"
                          placeholder="Enter Your last name"
                          value={formData.lastname}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="sign-item">
                        <label htmlFor="phone">Phone no.</label>
                        <input
                          type="text"
                          placeholder="Enter Your Phone number"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="sign-item">
                        <label htmlFor="email">Email</label>
                        <input
                          type="text"
                          placeholder="Enter Your email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="sign-item">
                        <label htmlFor="address">Address</label>
                        <input
                          type="text"
                          placeholder="Enter Your address"
                          name="address"
                          value={formData.address}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="sign-item">
                        <label htmlFor="city">City</label>
                        <input
                          type="text"
                          placeholder="Enter Your City"
                          name="city"
                          value={formData.city}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-6">
                      <div className="sign-item">
                        <label htmlFor="country">Country</label>
                        <input
                          type="text"
                          placeholder="Enter Your Country"
                          name="country"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div> */}
                    <div className="col-lg-6">
                      <div className="sign-item">
                        <label htmlFor="country">Country</label>
                        <select
                          name="country"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select Your Country</option>
                          {countries.map((country, index) => (
                            <option key={index} value={country}>
                              {country}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="sign-item">
                        <label htmlFor="postalcode">Postal Code</label>
                        <input
                          type="text"
                          placeholder="Enter Your postal code"
                          name="postalcode"
                          value={formData.postalcode}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="sign-item">
                        <label htmlFor="password">Password</label>
                        <div className="input-group sign-group">
                          <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            className="form-control"
                            placeholder="Enter Password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                          />
                          <div className="input-group-append sign-append">
                            <span
                              className="input-group-text sign-text"
                              onClick={togglePassword}
                            >
                              <i className="bi bi-eye"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="sign-item">
                        <label htmlFor="confirmpassword">Confirm Password</label>
                        <div className="input-group sign-group">
                          <input
                            type={showPassword ? "text" : "password"}
                            name="confirmpassword"
                            className="form-control"
                            placeholder="Confirm Password"
                            value={formData.confirmpassword}
                            onChange={handleChange}
                            required
                          />
                          <div className="input-group-append sign-append">
                            <span
                              className="input-group-text sign-text"
                              onClick={togglePassword}
                            >
                              <i className="bi bi-eye"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-lg-6">
                    <div className="sign-item">
                      <label htmlFor="otp">Enter OTP</label>
                      <input
                        type="text"
                        name="otp"
                        placeholder="Enter the OTP sent to your email"
                        value={formData.otp}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="signup-button pt-5 d-flex justify-content-center">
                <button>{isOtpSent ? "Verify OTP" : "Continue"}</button>
              </div>
              <h6>
                Already Have an Account?{" "}
                <Link to="/signin" className="text-decoration-none">
                  <span>Login Here</span>
                </Link>
              </h6>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;


// seprate verfify page



// import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import iziToast from "izitoast";
// import { useAuth } from "../AuthContex";
// import Navbar from "./Navbar";

// const Signup = () => {
//   const { apipath } = useAuth();
//   const [showPassword, setShowPassword] = useState(false);
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     firstname: "",
//     lastname: "",
//     phone: "",
//     email: "",
//     address: "",
//     city: "",
//     country: "",
//     postalcode: "",
//     password: "",
//     confirmpassword: "",
//   });

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (formData.password !== formData.confirmpassword) {
//       iziToast.error({
//         message: "Passwords do not match",
//         position: "topCenter",
//       });
//       return;
//     }

//     // Register user and send OTP
//     try {
//       const response = await fetch(
//         'https://backend.eurofestia.com/api/v1/users/register',
//         // "http://localhost:4000/api/v1/users/register",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(formData),
//         }
//       );

//       const result = await response.json();

//       if (result.success) {
//         iziToast.success({
//           message: "Registration successful. Please check your email for the OTP.",
//           position: "topCenter",
//         });
//         localStorage.setItem("signupEmail", formData.email);
//         navigate("/signin");
//       } else {
//         if (result.error && response.status === 400) {
//           iziToast.error({
//             message: "User already exists. Please use a different email.",
//             position: "topCenter",
//           });
//         } else {
//           iziToast.error({
//             message: result.error ? result.error.message : "Unknown error",
//             position: "topCenter",
//           });
//         }
//       }
//     } catch (error) {
//       iziToast.error({
//         message: "Error during registration. Please try again.",
//         position: "topCenter",
//       });
//       console.error("Error during registration:", error.message);
//     }
//   };

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const togglePassword = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     <>
//       <Navbar />
//       <div className="container-fluid signin">
//         <div className="container">
//           <div className="signin-form">
//             <div className="sign-heading px-3">
//               <h1>Register</h1>
//               <p>
//                 Welcome! Please enter your information to create your account.
//               </p>
//             </div>
//             <form onSubmit={handleSubmit}>
//               <div className="row">
//                 <div className="col-lg-6">
//                   <div className="sign-item">
//                     <label htmlFor="firstname">First Name</label>
//                     <input
//                       type="text"
//                       name="firstname"
//                       placeholder="Enter Your first name"
//                       value={formData.firstname}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-6">
//                   <div className="sign-item">
//                     <label htmlFor="lastname">Last Name</label>
//                     <input
//                       type="text"
//                       name="lastname"
//                       placeholder="Enter Your last name"
//                       value={formData.lastname}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-6">
//                   <div className="sign-item">
//                     <label htmlFor="phone">Phone no.</label>
//                     <input
//                       type="text"
//                       placeholder="Enter Your Phone number"
//                       name="phone"
//                       value={formData.phone}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-6">
//                   <div className="sign-item">
//                     <label htmlFor="email">Email</label>
//                     <input
//                       type="text"
//                       placeholder="Enter Your email"
//                       name="email"
//                       value={formData.email}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-6">
//                   <div className="sign-item">
//                     <label htmlFor="address">Address</label>
//                     <input
//                       type="text"
//                       placeholder="Enter Your address"
//                       name="address"
//                       value={formData.address}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-6">
//                   <div className="sign-item">
//                     <label htmlFor="city">City</label>
//                     <input
//                       type="text"
//                       placeholder="Enter Your City"
//                       name="city"
//                       value={formData.city}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-6">
//                   <div className="sign-item">
//                     <label htmlFor="country">Country</label>
//                     <input
//                       type="text"
//                       placeholder="Enter Your Country"
//                       name="country"
//                       value={formData.country}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-6">
//                   <div className="sign-item">
//                     <label htmlFor="postalcode">Postal Code</label>
//                     <input
//                       type="text"
//                       placeholder="Enter Your postal code"
//                       name="postalcode"
//                       value={formData.postalcode}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-6">
//                   <div className="sign-item">
//                     <label htmlFor="password">Password</label>
//                     <div className="input-group sign-group">
//                       <input
//                         type={showPassword ? "text" : "password"}
//                         name="password"
//                         className="form-control"
//                         placeholder="Enter Password"
//                         value={formData.password}
//                         onChange={handleChange}
//                         required
//                       />
//                       <div className="input-group-append sign-append">
//                         <span
//                           className="input-group-text sign-text"
//                           onClick={togglePassword}
//                         >
//                           <i className="bi bi-eye"></i>
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-6">
//                   <div className="sign-item">
//                     <label htmlFor="confirmpassword">Confirm Password</label>
//                     <div className="input-group sign-group">
//                       <input
//                         type={showPassword ? "text" : "password"}
//                         name="confirmpassword"
//                         className="form-control"
//                         placeholder="Confirm Password"
//                         value={formData.confirmpassword}
//                         onChange={handleChange}
//                         required
//                       />
//                       <div className="input-group-append sign-append">
//                         <span
//                           className="input-group-text sign-text"
//                           onClick={togglePassword}
//                         >
//                           <i className="bi bi-eye"></i>
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="signup-button pt-5 d-flex justify-content-center">
//                 <button>Continue</button>
//               </div>
//               <h6>
//                 Already Have an Account?{" "}
//                 <Link to="/signin" className="text-decoration-none">
//                   <span>Login Here</span>
//                 </Link>
//               </h6>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Signup;
